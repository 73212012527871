// @flow

import { useTranslation } from 'react-i18next'

export type OptionsType = {
  description: string,
  label: ?string,
}

const conditionalFormulaRangeDescription = (params) => {
  const {
    description,
    label,
    skip_reasons,
    translation,
    unAnsweredDescription,
  } = params

  const skippedLabel = translation('skipped')
  const unansweredLabel = translation('unanswered')

  if (label === skippedLabel) {
    return skip_reasons[description]
  }

  if (label === unansweredLabel) {
    return unAnsweredDescription
  }

  return description
}

function useConditionalFormulaRangeDescription(options: OptionsType) {
  const { description, label } = options || {}

  const { t: translation } = useTranslation()

  const skipReasonSuffix = translation(
    'to_get_a_result_for_this_health_card_you_can_update_your_answers_anytime',
  )
  const unAnsweredDescription = `${translation(
    'the_health_assessment_for_this_section_is_not_complete',
  )} ${skipReasonSuffix}`

  const skip_reasons = {
    irrelevant: `${translation(
      'you_chose_to_skip_this_question_set_because_you_preferred_not_to_answer',
    )} ${skipReasonSuffix}`,
    later: `${translation(
      'you_chose_to_skip_and_do_this_question_set_at_a_later_time',
    )} ${skipReasonSuffix}`,
    with_clinician: `${translation(
      'you_chose_to_skip_and_do_this_question_set_with_your_clinician_this_request_has_been_shared_with_them',
    )} ${skipReasonSuffix}`,
    with_support: `${translation(
      'you_chose_to_skip_and_do_this_question_set_with_a_support_person',
    )} ${skipReasonSuffix}`,
  }

  return {
    callbacks: {
      conditionalFormulaRangeDescription: () =>
        conditionalFormulaRangeDescription({
          description,
          label,
          skip_reasons,
          translation,
          unAnsweredDescription,
        }),
    },
  }
}

export default useConditionalFormulaRangeDescription
