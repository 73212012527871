// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  createFragmentContainer,
  graphql,
  useRelayEnvironment,
} from 'react-relay'

import { commit } from 'mutations/UserNotificationsMarkAsRead'
import { Box, Button, Container, FlexContainer } from 'care-ui'
import TextNew from 'care-ui/molecules/TextNew/TextNew'

import type { UserNotifications_user } from './__generated__/UserNotifications_user.graphql'

type PropsType = { user: UserNotifications_user }

const UserNotificationsComponent = (props: PropsType) => {
  const {
    user: { id, user_notifications },
  } = props

  const environment = useRelayEnvironment()

  // Translations
  const { t: translation } = useTranslation()

  if (!user_notifications || user_notifications.length < 1) {
    return null
  }

  const markNotificationsRead = () => {
    commit({
      environment,
      variables: {
        input: {
          userId: id,
        },
      },
    })
  }

  return (
    <Box
      backgroundColor="rgb(58, 70, 86)"
      paddingX="sm"
      dataTestId="userNotificationBanner"
    >
      <FlexContainer alignItems="center" direction="row">
        <Container>
          <FlexContainer
            justifyContent="space-between"
            alignItems="center"
            wrap="nowrap"
          >
            <div>
              {user_notifications.map((notification) => {
                return (
                  <TextNew color="default" key={notification.id}>
                    {notification.text}
                  </TextNew>
                )
              })}
            </div>

            <Button
              variant="secondary"
              dataTestId="dismissButton"
              onClick={markNotificationsRead}
            >
              {translation('dismiss')}
            </Button>
          </FlexContainer>
        </Container>
      </FlexContainer>
    </Box>
  )
}

export const UserNotifications = createFragmentContainer(
  UserNotificationsComponent,
  {
    user: graphql`
      fragment UserNotifications_user on User {
        id
        user_notifications(read: false) {
          id
          user_id
          text
          read
        }
      }
    `,
  },
)
