// @flow

import React from 'react'
import { useFela } from 'react-fela'
import { useTranslation } from 'react-i18next'
import { useLazyLoadQuery, usePaginationFragment } from 'react-relay'
import { uniqueId } from 'lodash'

import LoadMore from 'react-ui/components/LoadMore/LoadMore'
import { nodesOf } from 'shared/utils/paginationUtils'
import { Text } from 'care-ui'

import { AssessmentRequestsComponentLoader } from '../AssessmentRequestsComponent'
import { useAssessmentRequesteeContext } from '../useAssessmentRequesteeContext'

import { mainContentStyle, wrapperStyle } from './AssessmentRequestsList.style'
import {
  assessmentRequestsListFragment,
  assessmentRequestsListPaginatedQuery,
  individualAssessmentRequestsListFragment,
} from './query/AssessmentRequestsList'

type PropsType = {
  per?: number,
}

const DEFAULT_COUNT = 2

const AssessmentRequestsList = (props: PropsType) => {
  const { per = DEFAULT_COUNT } = props

  const { css } = useFela()
  const { userId, isIndividual } = useAssessmentRequesteeContext()

  const { node } = useLazyLoadQuery(
    assessmentRequestsListPaginatedQuery,
    { id: userId, count: per, isIndividual, isClincian: !isIndividual },
    { fetchPolicy: 'network-only' },
  )

  const fragment = isIndividual
    ? individualAssessmentRequestsListFragment
    : assessmentRequestsListFragment

  const { data, loadNext, hasNext } = usePaginationFragment(fragment, node)

  const source = isIndividual
    ? data?.current_role?.assessment_requested
    : data?.assessment_requested

  const assessment_requests = nodesOf(source)

  const { t: translation } = useTranslation()

  return (
    <>
      <div className={css(wrapperStyle)}>
        <div className={css(mainContentStyle)}>
          {assessment_requests.length ? (
            assessment_requests.map((assessment_request) => (
              <AssessmentRequestsComponentLoader
                assessment_request={assessment_request}
                key={uniqueId('AssessmentRequests')}
              />
            ))
          ) : (
            <Text size="lg" color="title" center>
              {translation('no_assessment_request')}
            </Text>
          )}
        </div>
      </div>

      {hasNext && <LoadMore handleClick={() => loadNext(per)} />}
    </>
  )
}

export default AssessmentRequestsList
