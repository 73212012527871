// @flow

import React, { useState } from 'react'
import { useFela } from 'react-fela'
import { useTranslation } from 'react-i18next'
import { createFragmentContainer, graphql } from 'react-relay'
import { uniqueId } from 'lodash/fp'

import { AccordionItem } from 'react-ui/components/Accordion'
import { shortDateFormat } from 'platform_web/services/dateTime'

import { StratifiedScoringResultAnswersLoader } from './StratifiedScoringResultAnswers'

import type { StratifiedScoringResultHistory_stratified_scoring_result } from './__generated__/StratifiedScoringResultHistory_stratified_scoring_result.graphql'

type PropsType = {
  stratified_scoring_result: ?StratifiedScoringResultHistory_stratified_scoring_result,
}

const accordionItemStyle = (theme, isOpen) => ({
  AccordionItem: {
    marginTop: '1.6rem',
    marginBottom: '0',
    backgroundColor: 'white',
    width: 'fit-content',
    [theme.breakpoints.queries.lg]: {
      width: '100%',
    },
  },
  header: {
    backgroundColor: theme.palette.dividerColor,
    flexDirection: 'row-reverse',
    paddingLeft: '0.4rem',
    borderRadius: isOpen ? '4px 4px 0 0' : '4px',
  },
  headerContainer: {
    backgroundColor: 'white',
  },
  heading: {
    fontSize: '1rem',
  },
  icon: {
    marginLeft: '0.3rem',
    margin: '0.4rem 0.9rem 0 0.3rem',
    width: '0.5rem',
    height: '0.5rem',
  },
  children: {
    backgroundColor: theme.palette.dividerColor,
    paddingTop: '0',
    paddingBottom: '1rem',
    paddingLeft: '0.7rem',
    paddingRight: '0.7rem',
    borderRadius: '0 0 4px 4px',
  },
})

const headerStyle = () => ({
  display: 'flex',
  padding: '0 7rem 0 2rem',
  position: 'relative',
  // top: '-0.4rem',
  '& div': {
    ':first-child': {
      width: '34.5rem',
    },
    ':nth-child(2)': {
      width: '31.5rem',
    },
  },
})

const wrapperStyle = () => ({
  overflowX: 'auto',
})

const requestHeadingStyle = () => ({
  display: 'flex',
  justifyContent: 'space-between',
})

const groupHealthDomains = (answerSets) => {
  const healthDomains = []

  answerSets.forEach((answerSet) => {
    const { health_domain } = answerSet
    const { label } = health_domain || {}

    const healthDomainIndex = healthDomains.findIndex((e) => e.label === label)

    if (healthDomainIndex > -1) {
      healthDomains[healthDomainIndex].answerSets.push(answerSet)
    } else {
      healthDomains.push({
        label,
        answerSets: [answerSet],
      })
    }
  })

  return healthDomains
}

export const StratifiedScoringResultHistory = ({
  stratified_scoring_result,
}: PropsType) => {
  // Localization
  const { t: translation } = useTranslation()
  const { answer_sets, status, completed_at } = stratified_scoring_result || {}
  const [isOpen, setOpen] = useState(true)
  const { css } = useFela()

  const onToggleOpen = () => {
    setOpen(!isOpen)
  }

  const renderAnsweredQuestionnaires = () => {
    if (!answer_sets?.length) return null

    const healthDomains = groupHealthDomains(answer_sets)

    return (
      <>
        {healthDomains &&
          healthDomains.map(({ label, answerSets }) => (
            <StratifiedScoringResultAnswersLoader
              label={label}
              answers={answerSets[0].answers}
              key={uniqueId(label)}
              rating={answerSets[0].rating}
            >
              {answerSets.length > 1 &&
                answerSets.map((answerSet) => {
                  const { question_set } = answerSet
                  return (
                    <StratifiedScoringResultAnswersLoader
                      label={question_set.label}
                      answers={answerSet.answers}
                      key={uniqueId(question_set.label)}
                      rating={null}
                      isChild
                    />
                  )
                })}
            </StratifiedScoringResultAnswersLoader>
          ))}
      </>
    )
  }

  const requestHeading = (
    <div className={css(requestHeadingStyle)}>
      <div>{`${translation('cluster')}: ${status}`}</div>
      <div>
        {completed_at &&
          `${translation('completed_on')} ${shortDateFormat(completed_at)}`}
      </div>
    </div>
  )

  return (
    <div className={css(wrapperStyle)}>
      <AccordionItem
        withArrow
        extend={({ theme }) => accordionItemStyle(theme, isOpen)}
        isOpen={isOpen}
        onToggleOpen={onToggleOpen}
        heading={requestHeading}
      >
        <div className={css(headerStyle)} role="rowgroup">
          <div>{translation('question_set')}</div>
          <div>{translation('status')}</div>
          <div>{translation('rating')}</div>
        </div>
        {renderAnsweredQuestionnaires()}
      </AccordionItem>
    </div>
  )
}

export const StratifiedScoringResultHistoryLoader = createFragmentContainer(
  StratifiedScoringResultHistory,
  {
    stratified_scoring_result: graphql`
      fragment StratifiedScoringResultHistory_stratified_scoring_result on StratifiedScoringResult {
        status
        completed_at
        answer_sets {
          id
          rating
          health_domain {
            label
          }
          question_set {
            id
            label
            name
          }
          answers {
            ...StratifiedScoringResultAnswers_answers
          }
        }
      }
    `,
  },
)
