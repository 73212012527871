// @flow

import React, { useState } from 'react'
import { useFela } from 'react-fela'
import { useTranslation } from 'react-i18next'
import { useFragment, useRelayEnvironment } from 'react-relay'
import { uniqueId } from 'lodash/fp'

import { Container } from 'react-ui/components/Grid'
import PopOver, {
  PopOverDoneFooter,
  PopOverSearchContents,
} from 'react-ui/components/PopOver'
import { commit as commitRemoveSupportAssignment } from 'mutations/SupportPersonAssignment/Remove'
import { Button } from 'care-ui'

import { assignmentFragment } from '../../queries/SupportingSupporteeRemoveModal'
import SupportingSupporteeCheckBox from '../SupportingSupporteeCheckBox'

import {
  containerStyle,
  listContainerStyle,
} from './SupportingSupporteeRemoveModal.style'

import type { SupportingSupporteeRemoveModal_individual_assignments$key } from '../../queries/__generated__/SupportingSupporteeRemoveModal_individual_assignments.graphql'

type PropsType = {
  individualAssignments: SupportingSupporteeRemoveModal_individual_assignments$key,
}

const SupportingSupporteeRemoveModal = (props: PropsType) => {
  const { individualAssignments: fragRef } = props

  const [selectedAssignments, setSelectedAssignments] = useState([])
  const individualAssignments = useFragment(assignmentFragment, fragRef)
  const { css } = useFela()
  const environment = useRelayEnvironment()

  const { t: translation } = useTranslation()

  const renderTrigger = ({ onToggleOpen }) => (
    <Button onClick={onToggleOpen} variant="text">
      {translation('remove_yourself_as_support_person')}
    </Button>
  )

  const onSubmit = (onToggleOpen) => {
    if (selectedAssignments.length > 0) {
      selectedAssignments.forEach((id) => {
        commitRemoveSupportAssignment({
          environment,
          variables: {
            input: {
              id,
            },
          },
        })
      })
    }
    onToggleOpen()
  }

  return (
    <PopOver
      attachment="middle center"
      targetModifier="visible"
      target={document.body}
      extend={containerStyle}
      overlay
      renderTrigger={renderTrigger}
      renderFooter={({ onToggleOpen }) => (
        <PopOverDoneFooter
          onToggleOpen={() => onSubmit(onToggleOpen)}
          label={
            selectedAssignments.length === 0
              ? translation('close')
              : translation('remove')
          }
        />
      )}
    >
      <Container className={css(listContainerStyle)}>
        <PopOverSearchContents
          data={individualAssignments}
          searchFields={[{ path: 'individual.legal_name' }]}
        >
          {({ searchResult }) => (
            <>
              {searchResult.map(({ id, individual }) => (
                <SupportingSupporteeCheckBox
                  key={uniqueId('supporting-supportee-checkbox-')}
                  assignmentId={id}
                  label={individual?.legal_name}
                  selectedAssignments={selectedAssignments}
                  setSelectedAssignments={setSelectedAssignments}
                />
              ))}
            </>
          )}
        </PopOverSearchContents>
      </Container>
    </PopOver>
  )
}

export default SupportingSupporteeRemoveModal
