// @flow

import type { TFunction } from 'react-i18next'

const AboriginalAndTorresStraitParagraphs = (translation: TFunction) => [
  {
    id: '1',
    text: translation(
      'innowell_acknowledges_the_traditional_custodians_of_the_land_throughout_australia_and_recognises_their_continuing_connection_to_land_water_and_community_we_pay_our_respects_to_all_aboriginal_and_torres_strait_islander_peoples_their_culture_diversity_history_and_knowledge_we_pay_our_respects_to_the_elders_past_present_and_emerging',
    ),
  },
]

export default AboriginalAndTorresStraitParagraphs
