// @flow

import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLazyLoadQuery } from 'react-relay'
import { useRouter } from 'found'

import { HealthPreferences } from 'platform_web/features/HealthCard'
import { Button, Container, Grid, GridItem, Section } from 'care-ui'
import ChevronLeft from 'care-ui/atoms/icons/chevron-left.svg'

import { containerStyle } from './DashboardPreferences.style'
import { query } from './query/DashboardPreferences'

const DashboardPreferences = () => {
  const {
    viewer: { currentUser },
  } = useLazyLoadQuery(query)
  const { router } = useRouter()

  const [isIndividual] = useSelector(({ currentUser: { roleType } }) => [
    roleType === 'INDIVIDUAL',
  ])

  const { homepage_path } = currentUser

  useEffect(() => {
    if (!isIndividual) {
      router.replace(homepage_path)
    }
  }, [isIndividual])

  // Localization
  const { t: translation } = useTranslation()

  return (
    <Section>
      <Container extend={containerStyle}>
        <Grid>
          <GridItem>
            <Button
              to="dashboard"
              variant="text"
              ariaLabel={translation('back_to_dashboard')}
              dataTestId="back-to-dashboard"
              iconLeft={ChevronLeft}
            >
              {translation('back_to_dashboard')}
            </Button>
          </GridItem>

          <GridItem>
            <HealthPreferences
              user_trackables={currentUser.extensiveUserTrackables}
            />
          </GridItem>
        </Grid>
      </Container>
    </Section>
  )
}

export default DashboardPreferences
