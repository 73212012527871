// @flow

import React, { Suspense, useState } from 'react'
import { useTranslation } from 'react-i18next'

import SelectedChips from 'platform_web/components/SelectedChips'
import useMultiRoleAssignment from 'platform_web/pages/Staff/hooks/useMultiRoleAssignment'
import type { UseMultiSelectReturnTypes } from 'platform_web/pages/Staff/hooks/useMultiSelect'
import { type QueryVariablesType } from 'platform_web/pages/Staff/Individuals/hooks/useIndividualTable'
import { Box, Button, FlexContainer, Modal, SearchBar } from 'care-ui'

import AssignCliniciansList from './AssignableCliniciansList'

type AssignCliniciansModalPropsType = {
  closeModal: () => void,
  isOpen: boolean,
  multiSelectPayload: UseMultiSelectReturnTypes,
  mutationQuery: any,
  queryVariables: QueryVariablesType,
}

const AssignCliniciansModal = (props: AssignCliniciansModalPropsType) => {
  const {
    closeModal,
    mutationQuery,
    isOpen,
    multiSelectPayload,
    queryVariables,
  } = props

  // Search
  const [searchString, setSearchString] = useState()

  const useMultiRoleAssignmentPayload = useMultiRoleAssignment({
    closeModal,
    multiSelectPayload,
    mutationQuery,
    queryVariables,
  })

  const { assignRoles, toggleAssignedRoleIds } = useMultiRoleAssignmentPayload

  // Selected Clinicians Chips
  const [selectedClinicians, setSelectedClinicians] = useState([])

  const toggleSelectedClinicians = (selectedClinician) => {
    const clinician = selectedClinicians.find(
      (c) => c.id === selectedClinician.id,
    )

    const updatedSelectedClinicians = clinician
      ? selectedClinicians.filter((item) => item.id !== selectedClinician.id)
      : [...selectedClinicians, selectedClinician]

    toggleAssignedRoleIds(selectedClinician.id)
    setSelectedClinicians(updatedSelectedClinicians)
  }

  const { t: translation } = useTranslation()

  return (
    <Modal
      width="500px"
      isOpen={isOpen}
      closeModal={closeModal}
      heading={`${translation('assign')} ${translation('clinician_other')}`}
      dataTestId="AssignCliniciansModal"
      footer={
        <Button
          variant="primary"
          onClick={() => assignRoles()}
          dataTestId="assignClinicians"
          disabled={!selectedClinicians.length}
        >
          {translation('assign')}
        </Button>
      }
    >
      <FlexContainer direction="column" gap="xxxs" wrap="nowrap">
        <Box height={selectedClinicians.length ? '10vh' : '5vh'}>
          <SearchBar
            fullWidth
            clearSearch={() => setSearchString('')}
            inputAttributes={{
              onChange: (e) => setSearchString(e.target.value),
              value: searchString,
            }}
          />
          <SelectedChips
            selectedItems={selectedClinicians}
            toggleSelectedItems={toggleSelectedClinicians}
          />
        </Box>

        <Box height="50vh" marginY="xs">
          <Suspense fallback={<center>{translation('loading')}</center>}>
            <AssignCliniciansList
              useMultiRoleAssignmentPayload={useMultiRoleAssignmentPayload}
              multiSelectPayload={multiSelectPayload}
              queryVariables={queryVariables}
              searchString={searchString}
              toggleSelectedClinicians={toggleSelectedClinicians}
            />
          </Suspense>
        </Box>
      </FlexContainer>
    </Modal>
  )
}

export default AssignCliniciansModal
