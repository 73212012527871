// @flow

import React, { type Node, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { flatten, size } from 'lodash'

import { useScoreCardChart } from 'react-ui/contexts/ScoreCardChartContext/ScoreCardChartContext'
import { FlexContainer, Select } from 'care-ui'

export type OptionalFilterTypes = {
  dataSets: Array<*>,
}

export type SelectOptionsPropTypes = OptionalFilterTypes & {
  children: Node,
  dataSets: Array<*>,
  disabled?: boolean,
}

const SelectOptions = ({
  dataSets,
  children,
  disabled,
}: SelectOptionsPropTypes) => {
  // Localization
  const { t: translation } = useTranslation()

  const RoleToLabel = {
    CLINICIAN: translation('clinician'),
    SUPPORT_PERSON: translation('support_person'),
  }

  const [selectedCliIndex, setSelectedCliIndex] = useState(0)
  const [selectedSPIndex, setSelectedSpIndex] = useState(0)

  const { setCliIndex, setSpIndex } = useScoreCardChart()

  useEffect(() => {
    setCliIndex(selectedCliIndex)
    setSpIndex(selectedSPIndex)
  }, [selectedCliIndex, selectedSPIndex])

  const indexHandler = (family, target) => {
    const newValue = parseInt(target, 10) ? target : 0
    return family === RoleToLabel.CLINICIAN.toUpperCase()
      ? setSelectedCliIndex(newValue)
      : setSelectedSpIndex(newValue)
  }

  return (
    <React.Fragment>
      {size(dataSets) > 0 && !disabled && (
        <FlexContainer direction="row" gap="sm" justifyContent="end">
          {dataSets.map(({ family, dataSet }, familyIndex) => {
            const itemKey = `index-${familyIndex}`
            const tempList = flatten(
              dataSet.map(({ answered_by }, i) => ({
                key: `${i}`,
                value: answered_by.user.name,
                itemIndex: i,
              })),
            )
            const isValidList = size(tempList) > 0
            const name = `${family} ${translation('select_options')}`

            return (
              isValidList && (
                <FlexContainer
                  key={itemKey}
                  wrap="nowrap"
                  gap="xxxs"
                  justifyContent="end"
                  alignItems="center"
                >
                  <label htmlFor={name}>{RoleToLabel[family]}:</label>

                  <Select
                    size="md"
                    id={name}
                    onChange={(e) =>
                      indexHandler(family, e.currentTarget.value)
                    }
                    name={name}
                    options={tempList}
                    labelKey="value"
                    value={
                      family === RoleToLabel.CLINICIAN.toUpperCase()
                        ? selectedCliIndex
                        : selectedSPIndex
                    }
                    valueKey="key"
                  />
                </FlexContainer>
              )
            )
          })}
        </FlexContainer>
      )}
      {children}
    </React.Fragment>
  )
}

export default SelectOptions
