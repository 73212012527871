// @flow

import React, { Fragment } from 'react'
import type { TFunction } from 'react-i18next'

const GeneralQuestions = (translation: TFunction) => [
  {
    key: 'howSupportOngoingCare',
    question: (
      <Fragment>
        {' '}
        {translation('how_does_innowell_support_ongoing_care')}{' '}
      </Fragment>
    ),
    answer: (
      <Fragment>
        {translation(
          'innowell_is_an_interactive_clinical_tool_that_brings_individuals_their_health_data_and_health_professionals_together_in_one_place',
        )}
        <br />
        <br />
        {translation(
          'innowell_works_with_a_health_service_using_an_initial_health_assessment_which_populates_an_individuals_dashboard_this_provides_clinicians_with_up_to_date_client_results_and_health_information_which_forms_the_basis_of_treatment_information_is_updated_regularly_to_inform_ongoing_care_track_progress_engage_with_care_options_and_assure_treatment_is_helping_the_person_reach_their_wellness_goals',
        )}
      </Fragment>
    ),
  },
  {
    key: 'minAge',
    question: (
      <Fragment>
        {translation('is_there_a_minimum_age_for_using_innowell')}
      </Fragment>
    ),
    answer: (
      <Fragment>
        {translation(
          'innowell_can_be_used_by_anyone_anywhere_clients_are_connected_to_innowell_through_a_health_provider_and_can_be_any_age_they_can_join_by_themselves_or_with_a_parent_guardian_or_carer_clients_may_also_invite_a_support_person',
        )}
      </Fragment>
    ),
  },
  {
    key: 'research',
    question: (
      <Fragment>
        {translation('why_is_innowell_interested_in_research')}
      </Fragment>
    ),
    answer: (
      <Fragment>
        {translation(
          'innowell_is_partnered_with_the_brain_and_mind_centre_of_the_university_of_sydney_the_brain_and_mind_centre_combines_research_and_practice_to_inform_better_health_services_and_person_centred_care_for_everyday_people',
        )}
        <br />
        <br />
        {translation(
          'by_using_data_for_research_innowell_is_constantly_investing_and_learning_about_mental_health_in_australia',
        )}
        <br />
        {translation(
          'if_you_have_provided_permission_your_de_identified_information_is_shared_with_the_brain_and_mind_centre',
        )}
      </Fragment>
    ),
  },
]

export default GeneralQuestions
