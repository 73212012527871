// @flow

import React from 'react'
import { useFela } from 'react-fela'
import { useTranslation } from 'react-i18next'

import { Container } from 'react-ui/components/Grid'
import Section from 'react-ui/components/Section'
import { Heading } from 'care-ui'

import Sticky from '../Sticky'

import type { StickyQuestionnaireHeaderLoader_userQuestionnairesQuestionSet } from './__generated__/StickyQuestionnaireHeaderLoader_userQuestionnairesQuestionSet.graphql'

type PropsType = {
  userQuestionnairesQuestionSet: StickyQuestionnaireHeaderLoader_userQuestionnairesQuestionSet,
}

const stickyHeaderContainer = ({ theme }) => ({
  paddingLeft: '10px',
  [theme.breakpoints.queries.md]: {
    paddingLeft: '42px',
  },
  paddingBottom: '20px',
})

const stickyHeader = () => ({
  fontSize: '20px',
  fontWeight: 'bold',
  paddingTop: '20px',
})

const label = () => ({
  textTransform: 'capitalize',
  display: 'inline-block',
})

const description = () => ({
  marginTop: '20px',
})

const stickySection = ({ theme }) => ({
  Section: {
    width: '100vw',
    borderBottomColor: theme.palette.dividerColor,
    borderBottomStyle: 'solid',
    borderBottomWidth: '1px',
  },
})

const StickyQuestionnaireHeader = (props: PropsType) => {
  const {
    userQuestionnairesQuestionSet: { progress, question_set },
  } = props

  const { t: translation } = useTranslation()
  const { css } = useFela()

  const stickyComponent = () => (
    <Section color="white" noPadding extend={stickySection}>
      <Container>
        <div className={css(stickyHeaderContainer)}>
          <div className={css(stickyHeader)}>
            <Heading level={1}>
              <span className={css(label)}>{question_set.label}</span>
              {` (${progress.step} ${translation('out_of')} ${progress.total})`}
            </Heading>
          </div>
        </div>
      </Container>
    </Section>
  )

  return (
    <Section color="white" noPadding>
      <Container>
        <div className={css(stickyHeaderContainer)}>
          <Sticky top={63} topOffset={30} stickyComponent={stickyComponent()}>
            <div className={css(stickyHeader)}>
              <Heading level={1}>
                <span className={css(label)}>{question_set.label}</span>
                {` (${progress.step} ${translation('out_of')} ${
                  progress.total
                })`}
              </Heading>
            </div>
          </Sticky>
          {question_set.description && (
            <div className={css(description)}>{question_set.description}</div>
          )}
        </div>
      </Container>
    </Section>
  )
}

export default StickyQuestionnaireHeader
