// @flow

import React, { type Node } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Flag,
  FlagAboriginal,
  FlagAustralia,
  FlagPride,
  FlagTorresStrait,
  FlagTrans,
} from 'react-ui/components/Flag'
import { Heading, Text } from 'care-ui'

import {
  ParagraphsAboriginalAndTorresStrait,
  ParagraphsAcknowledgement,
  ParagraphsLGBTQI,
} from './Paragraphs'

type SectionPropsType = {
  flags?: $ReadOnlyArray<{ id: string, image: Node }>,
  heading: string,
  paragraphs: $ReadOnlyArray<{ id: string, text: string }>,
}

const Section = ({ heading, paragraphs, flags = [] }: SectionPropsType) => (
  <>
    <Heading level={2} margin="xs">
      {heading}
    </Heading>
    <Text size="lg">
      {paragraphs.map(({ id, text }) => (
        <p key={id}>{text}</p>
      ))}
    </Text>
    <div>
      {flags.map(({ id, image }) => (
        <Flag key={id} as={image} />
      ))}
    </div>
  </>
)

const AcknowledgementsPage = () => {
  const { t: translation } = useTranslation()
  return (
    <div style={{ marginBottom: '5rem' }}>
      <Heading level={1} margin="sm" style={{ wordBreak: 'break-all' }}>
        {translation('acknowledgements')}
      </Heading>

      <Section
        heading={translation('project_synergy')}
        paragraphs={ParagraphsAcknowledgement(translation)}
      />

      <Section
        heading={translation('aboriginal_and_torres_strait_islanders')}
        flags={[
          { id: 'AustraliaFlag', image: FlagAustralia },
          { id: 'FlagAboriginal', image: FlagAboriginal },
          { id: 'FlagTorresStrait', image: FlagTorresStrait },
        ]}
        paragraphs={ParagraphsAboriginalAndTorresStrait(translation)}
      />

      <Section
        heading={translation('the_lgbtqi_community')}
        flags={[
          { id: 'FlagPride', image: FlagPride },
          { id: 'FlagTrans', image: FlagTrans },
        ]}
        paragraphs={ParagraphsLGBTQI(translation)}
      />
    </div>
  )
}

export default AcknowledgementsPage
