// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import { useRelayEnvironment } from 'react-relay'

import { IndividualCliniciansListLoader } from 'components/IndividualCliniciansList'
import ClinicianNoteActivity from 'react-ui/components/ClinicianNote'
import { ActionsDropDown, DropDownItem } from 'react-ui/components/DropDown'
import EmrDisplay from 'react-ui/components/IndividualUserAdditionalInformationCard/EditEmrModal'
import { commit as commitDischarge } from 'mutations/Individual/Discharge'
import {
  AssessmentRequesteeProvider,
  IndividualTrackablesList,
} from 'platform_web/features/AssessmentRequests'
import { useQuestionnaireSession } from 'platform_web/features/Questionnaire'
import { useCurrentUserRoles } from 'platform_web/hooks/useCurrentUserRoles'
import { usePolicies } from 'platform_web/hooks/usePolicies'

import { type IndividualsTable_individual } from '../query/__generated__/IndividualsTable_individual.graphql'
import type {
  ClinicianIndividualAssignmentStatusEnum,
  IndividualsTable_user,
} from '../query/__generated__/IndividualsTable_user.graphql'

const shouldDisplayRequestAssessmentAction = (
  hasLeadClinicianRole,
  hide_all_unassigned_individuals_for_clinician,
  assignmentStatus,
) => {
  if (!window.GLOBALS.features?.REQUEST_ASSESSMENTS) return false

  if (hasLeadClinicianRole) return true

  if (assignmentStatus === 'Explicit') return true

  return !hide_all_unassigned_individuals_for_clinician
}
type PropsType = {
  assignmentStatus: ClinicianIndividualAssignmentStatusEnum,
  individual: IndividualsTable_individual,
  user: IndividualsTable_user,
}
type MutateType = (any) => void
type InputType = { id?: string, roleId?: string }

const IndividualsTableScoresActions = ({
  assignmentStatus,
  individual,
  user,
  user: {
    staff_individuals_page_actions: { scores },
  },
}: PropsType) => {
  const policies = usePolicies()
  const environment = useRelayEnvironment()

  const { role_status, tenant } = individual

  // TODO: Cleanup this logic
  const {
    tenant: currentUserTenant,
    hasLeadClinicianRole,
  } = useCurrentUserRoles()

  const { hide_all_unassigned_individuals_for_clinician } = currentUserTenant
  const REQUEST_ASSESSMENTS = shouldDisplayRequestAssessmentAction(
    hasLeadClinicianRole,
    hide_all_unassigned_individuals_for_clinician,
    assignmentStatus,
  )
  //
  const summaryQuestionnaire = tenant?.summary_questionnaire || {}
  const giveInput =
    summaryQuestionnaire.answerable &&
    summaryQuestionnaire.id &&
    scores.includes('give_input')
  const assigned = individual.duty_of_care_clinicians
    .map((clinician) => clinician.user.id)
    .includes(user.id)
  const validStatuses = ['Explicit', 'Implicit'].includes(assignmentStatus)
  const addNote =
    assigned &&
    policies.CAN_USE_CLINICIAN_NOTE &&
    policies.CAN_VIEW_CLINICIAN_LINKS &&
    validStatuses &&
    scores.includes('add_note')
  const discharge = scores.includes('discharge')
  const medicalDirector = scores.includes('medical_director')
  const assignClinicians = scores.includes('assign_clinicians')

  const createClickHandler = (mutate: MutateType, input: InputType) => () => {
    mutate({
      environment,
      variables: { input },
    })
  }

  const { start } = useQuestionnaireSession({
    answereeUserId: individual.user.id,
    answererRoleType: 'CLINICIAN',
    questionnaireId: summaryQuestionnaire.id,
  })

  // Localization
  const { t: translation } = useTranslation()

  return (
    <ActionsDropDown simple>
      {addNote && <ClinicianNoteActivity staff user={individual.user} />}
      {giveInput && (
        <DropDownItem label={translation('give_input')} onClick={start} />
      )}
      {medicalDirector && (
        <EmrDisplay staff actions individual={individual} emrEditable />
      )}
      {assignClinicians && (
        <IndividualCliniciansListLoader individual={individual} />
      )}
      {role_status === 'Active' && discharge && (
        <DropDownItem
          label={translation('discharge')}
          onClick={createClickHandler(commitDischarge, {
            roleId: individual.id,
          })}
        />
      )}
      {REQUEST_ASSESSMENTS && (
        <AssessmentRequesteeProvider
          individualUserId={individual.user.id}
          individualId={individual.id}
        >
          <IndividualTrackablesList isMenuItem />
        </AssessmentRequesteeProvider>
      )}
    </ActionsDropDown>
  )
}

export default IndividualsTableScoresActions
