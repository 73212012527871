// @flow

import React, { useEffect, useState } from 'react'
import { useFela } from 'react-fela'
import { useTranslation } from 'react-i18next'
import { useFragment } from 'react-relay'
import { useRouter } from 'found'
import { get, uniqueId } from 'lodash/fp'

import { Button, Grid, GridItem, Heading, Text } from 'care-ui'

import HealthCard from '../HealthCard'

import { containerStyle, subtitleStyle } from './HealthPreferences.style'
import { healthCardListFragment } from './query/HealthPreferences'
import useSaveUserTrackable from './useSaveUserTrackable'

import type { HealthPreferences_user_trackables$key } from './query/__generated__/HealthPreferences_user_trackables.graphql'

type PropsType = {
  +user_trackables: HealthPreferences_user_trackables$key,
}

const HealthPreferences = (props: PropsType) => {
  const { user_trackables: fragmentRef } = props
  const { nodes: user_trackables } = useFragment(
    healthCardListFragment,
    fragmentRef,
  )

  const [inPlan, setInPlan] = useState([])

  const { css } = useFela()
  const { router } = useRouter()

  useEffect(() => {
    const newInPlan = (user_trackables || []).reduce((arr, user_trackable) => {
      if (user_trackable && user_trackable.in_plan) {
        arr.push(user_trackable.trackable.id)
      }
      return arr
    }, [])

    setInPlan(newInPlan)
  }, [user_trackables])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [window])

  const action = (trackableId: string) => {
    if (inPlan?.includes(trackableId)) {
      return setInPlan((prev) => prev?.filter((id) => id !== trackableId))
    } else if (inPlan && inPlan.length < 3) {
      return setInPlan((prev) => prev?.concat(trackableId))
    }

    return undefined
  }

  const { commit } = useSaveUserTrackable()
  const handleTrackablePreferencesSave = () => {
    commit({
      variables: {
        input: {
          trackableIds: inPlan,
        },
      },
      onCompleted: () => {
        router.push({ name: 'dashboard' })
      },
    })
  }

  const healthCards = user_trackables?.map((user_trackable) => {
    const trackableId = get('trackable.id')(user_trackable)
    const selected = inPlan?.includes(trackableId)

    return (
      user_trackable && (
        <GridItem
          key={uniqueId('health-preference-')}
          xs={12}
          sm={6}
          md={4}
          lg={3}
          xl={2}
          center
        >
          <HealthCard
            user_trackable={user_trackable}
            selectable
            action={action}
            selected={selected}
          />
        </GridItem>
      )
    )
  })

  const { t: translation } = useTranslation()

  return (
    <Grid extend={containerStyle}>
      <GridItem>
        <Heading level={1}>{translation('select_health_domains')}</Heading>
      </GridItem>

      <GridItem>
        <Text className={css(subtitleStyle)}>
          {translation(
            'select_up_to_3_domains_to_focus_on_to_improve_your_overall_wellbeing',
          )}
        </Text>
      </GridItem>

      <GridItem>
        <Grid>{healthCards}</Grid>
      </GridItem>

      <GridItem center>
        <Button
          variant="primary"
          onClick={handleTrackablePreferencesSave}
          ariaLabel={translation('save')}
          dataTestId="save"
        >
          {translation('save')}
        </Button>
      </GridItem>
    </Grid>
  )
}

export default HealthPreferences
