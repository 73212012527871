// @flow

import React from 'react'
import { connect } from 'react-fela'
import { useTranslation } from 'react-i18next'

import { withoutFelaProps } from 'shared/services/fela'
import logo from 'shared/ui/Styles/images/innowell_icon.png'
import { buildLocalizationKey } from 'platform_web/utility/buildLocalizationKey'
import { Button, Text } from 'care-ui'

import type { FelaPropsType } from 'react-ui/typing'

type RoleType = {
  +id: string,
  +logoUrl?: string,
  +tenantGroupId: string,
  +tenantOrGroupName: string,
  +title: string,
}

type PropsType = FelaPropsType & {
  as?: string,
  availableRoles: $ReadOnlyArray<RoleType>,
  logoUrl?: string,
  noLink?: boolean,
  tenantName: string,
}

const styleRules = ({ theme }) => ({
  TenantCard: {
    alignItems: 'flex-start',
    className: 'TenantCard',
    minWidth: '250px',
    display: 'flex',
    flexFlow: 'row nowrap',
    fontSize: '18px',
    justifyContent: 'stretch',
    lineHeight: theme.spacing(1),
  },

  contentRegion: {
    boxSizing: 'border-box',
    className: 'RoleCard__contentRegion',
    display: 'flex',
    flex: '0 1 100%',
    '-ms-flex': '0 1 auto',
    flexFlow: 'column wrap',
    paddingLeft: theme.Grid.gutter,
    paddingRight: theme.Grid.gutter,
  },

  logo: {
    backgroundColor: 'white',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    borderRadius: '5px',
    border: `1px solid ${theme.care.palette.border.subtle}`,
    boxSizing: 'border-box',
    className: 'TenantCard__logo',
    display: 'block',
    flex: `0 0 ${theme.spacing(2)}`,
    height: theme.spacing(2),
    width: theme.spacing(2),
  },

  tenantName: {
    display: 'block',
  },
  roles: {
    className: 'TenantCard__roles',
  },
  roleAction: {
    className: 'TenantCard__roleAction',
    display: 'block',
    color: 'white',
  },
})

function renderRoleLink(role, noLink, translation) {
  if (noLink) {
    return (
      <Text key={role.id} size="lg">
        {translation(buildLocalizationKey(role.title))}
      </Text>
    )
  }
  return (
    <Button
      key={role.id}
      to={{ name: 'changeRole', params: { roleId: role.id } }}
    >
      {translation(buildLocalizationKey(role.title))}
    </Button>
  )
}

const TenantWithRolesCard = ({
  as: Component = 'div',
  logoUrl = logo,
  styles,
  tenantName,
  availableRoles,
  noLink,
  ...props
}: PropsType) => {
  // Localization
  const { t: translation } = useTranslation()

  return (
    <Component {...withoutFelaProps(props)} className={styles.TenantCard}>
      <div
        aria-label="logo"
        className={styles.logo}
        role="img"
        style={{ backgroundImage: `url("${logoUrl}")` }}
      />

      <div className={styles.contentRegion}>
        <Text size="lg" bold className={styles.tenantName}>
          {tenantName || '-'}
        </Text>
        <div className={styles.roles}>
          {(availableRoles || []).map((role) =>
            renderRoleLink(role, noLink, translation),
          )}
        </div>
      </div>
    </Component>
  )
}

export default connect(styleRules)(TenantWithRolesCard)
