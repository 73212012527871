// @flow

import type { TFunction } from 'react-i18next'

const AcknowledgementParagraphs = (translation: TFunction) => [
  {
    id: '1',
    text: translation(
      'project_synergy_2017_20_is_an_australian_government_department_of_health_funded_initiative_that_is_administered_by_innowell_pty_ltd_a_joint_venture_between_the_university_of_sydney_and_pwc_innowell_is_also_the_manufacturer_of_the_innowell_platform_including_its_database_and_is_considered_to_be_the_custodian_of_all_data_collected_within_that_database',
    ),
  },
  {
    id: '2',
    text: translation(
      'the_platform_is_a_customisable_digital_toolkit_to_assist_assessment_monitoring_and_management_of_mental_ill_health_and_maintenance_of_wellbeing_it_does_this_by_collecting_storing_analysing_and_reporting_personal_and_health_information_back_to_individuals_and_their_clinicians_to_promote_collaborative_care_partnerships',
    ),
  },
  {
    id: '3',
    text: translation(
      'the_clinical_content_is_determined_by_service_providers_the_platform_operates_through_existing_service_providers_to_promote_24_7_access_to_high_quality_and_cost_effective_mental_health_services_highly_protected_personally_controlled_data_capture_and_transfer_systems_are_utilised_to_move_the_centre_of_care_from_service_providers_to_the_person_and_family_seeking_care',
    ),
  },
  {
    id: '4',
    text: translation(
      'through_a_number_of_research_studies_the_platform_is_being_embedded_within_traditional_face_to_face_and_online_australian_mental_health_services_and_offered_to_consumers_presenting_to_those_services_as_part_of_standard_clinical_care_practice_it_allows_individuals_to_complete_online_clinical_self_assessments_to_understand_their_needs_explore_their_dashboard_of_results_including_current_symptoms_functioning_and_health_history_select_recommended_care_options_fact_sheets_apps_etools_and_other_resources_to_support_their_mental_health_and_wellbeing_track_their_progress_real_time_to_see_how_they_are_going_and_share_their_dashboard_and_plan_with_their_treating_clinicians_to_support_care',
    ),
  },
  {
    id: '5',
    text: translation(
      'innowell_is_committed_to_actively_supporting_aboriginal_and_torres_strait_islander_people_people_with_disability_and_the_lgbtiqa_plus_community_as_an_organisation_we_celebrate_diversity_regardless_of_age_gender_disability_lifestyle_race_ethnicity_aboriginal_or_torres_strait_islander_status_cultural_background_religion_or_other_beliefs_sexual_orientation_and_gender_identity_or_expression',
    ),
  },
]

export default AcknowledgementParagraphs
