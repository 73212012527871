// @flow

import React, { Fragment } from 'react'
import type { TFunction } from 'react-i18next'

import Link from 'react-ui/components/Link'

const InnowellPeopleQuestions = (translation: TFunction) => [
  {
    key: 'whoAreInnowellBoardMembers',
    question: (
      <Fragment> {translation('who_are_the_innowell_board_members')}</Fragment>
    ),
    answer: (
      <Fragment>
        {translation(
          'to_see_our_current_board_members_and_find_out_more_about_our_executive_team_please_visit',
        )}{' '}
        <Link to="https://www.innowell.org/about-us#leadership">
          https://www.innowell.org/about-us#leadership{' '}
        </Link>
      </Fragment>
    ),
  },
  {
    key: 'mediaInterviewRequest',
    question: (
      <Fragment>
        {' '}
        {translation('who_do_i_contact_about_a_media_interview_request')}
      </Fragment>
    ),
    answer: (
      <Fragment>
        {translation(
          'please_submit_your_interview_request_including_your_publication_and_deadline_to',
        )}{' '}
        <Link to="mailto: info@innowell.org">info@innowell.org </Link>
      </Fragment>
    ),
  },
]

export default InnowellPeopleQuestions
