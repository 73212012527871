// @flow

import React from 'react'
import { useFela } from 'react-fela'
import { useTranslation } from 'react-i18next'
import { useLazyLoadQuery } from 'react-relay'
import { uniqueId } from 'lodash/fp'

import InfoCircle from 'react-ui/assets/icons/information-circle.svg'
import { useAssessmentRequesteeContext } from 'platform_web/features/AssessmentRequests'
import { Grid, GridItem, Icon } from 'care-ui'

import AssessmentRequestsItem from '../AssessmentRequestsItem'

import {
  alertStyle,
  iconStyle,
  wrapperStyle,
} from './AssessmentRequestsLibrary.style'
import { query } from './query/AssessmentRequestsLibrary'

const AssessmentRequestsLibrary = () => {
  const {
    userId,
    assessmentRequesteeId,
    setPreSelected,
    individualId,
  } = useAssessmentRequesteeContext()
  const { css } = useFela()
  const { user } = useLazyLoadQuery(query, {
    id: userId,
    requesteeRoleId: individualId,
  })
  const { linked_individual } = user || {}
  const { requestableQuestionnaires } = linked_individual || {}

  const isIndividualRequestee = assessmentRequesteeId !== individualId

  const toggleRender = (questionnaireId) => {
    setPreSelected(questionnaireId)
  }

  const { t: translation } = useTranslation()

  const getTrackableCategory = (params) => {
    const { library_description, category } = params

    if (category === 'initial')
      return translation(
        'provide_a_comprehensive_overview_of_mental_health_across_multiple_domains_evaluating_symptoms_functioning_risk_indicators_and_support_systems',
      )
    if (category === 'summary')
      return translation(
        'a_quick_check_in_on_mental_health_incorporating_multiple_instruments_and_evaluating_general_health_history_and_wellbeing',
      )

    return library_description
  }

  return (
    <>
      {!isIndividualRequestee && (
        <p className={css(alertStyle)}>
          <span className={css(iconStyle)}>
            <Icon as={InfoCircle} scale={0.6} />
          </span>

          {translation(
            'assessments_that_dont_apply_to_the_patient_have_been_removed_from_the_list',
          )}
        </p>
      )}

      <Grid extend={wrapperStyle}>
        {requestableQuestionnaires.map(
          ({ label, id, library_description, category }) => {
            const trackableDescription = getTrackableCategory({
              library_description,
              category,
            })

            return (
              <GridItem xl={3} lg={4} md={6}>
                <AssessmentRequestsItem
                  key={uniqueId('assessment-requests-item-')}
                  label={label}
                  toggleList={toggleRender}
                  questionnaireId={id}
                  description={trackableDescription}
                />
              </GridItem>
            )
          },
        )}
      </Grid>
    </>
  )
}

export default AssessmentRequestsLibrary
