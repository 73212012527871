// @flow

import React from 'react'
import { useFela } from 'react-fela'
import { useTranslation } from 'react-i18next'
import { type FormikActions, type FormikProps, withFormik } from 'formik'

import { Field, Input } from 'react-ui/components/Form'
import { type EmrUpdateInputObject } from 'mutations/__generated__/EmrUpdateMutation.graphql'
import { commit as commitEMRUpdate } from 'mutations/EmrUpdate'
import { Button } from 'care-ui'

type PropsType = FormikProps & {
  afterSubmit: () => {},
  currentId: ?string,
  emrName: string,
  id: string,
  relay: { environment: {} },
  values: EmrUpdateInputObject,
}

const fieldStyle = (theme) => ({
  textAlign: 'left',
  display: 'inline-block',
  maxWidth: '477px',
  marginRight: theme.Grid.gutter,
})

function EditEmrFormBase({
  emrName,
  handleBlur,
  handleChange,
  handleSubmit,
  isSubmitting,
  values,
}: PropsType) {
  const { theme } = useFela()
  const { t: translation } = useTranslation()
  return (
    <form onSubmit={handleSubmit} aria-label="EmrForm">
      <Field
        extend={{ Field: fieldStyle(theme) }}
        id="emr_user_id"
        label={`${emrName}`}
        name="emr_user_id"
        input={
          <Input
            block
            onChange={handleChange}
            onBlur={handleBlur}
            name="emr_user_id"
            value={values.emr_user_id}
          />
        }
      />
      <center>
        <Button type="submit" disabled={isSubmitting} variant="primary">
          {translation('update')}
        </Button>
      </center>
    </form>
  )
}

export const formikMethods = {
  mapPropsToValues: ({ currentId }: PropsType) => ({
    emr_user_id: currentId || '',
  }),
  handleSubmit: (
    { emr_user_id }: { emr_user_id: ?string },
    {
      props: {
        id,
        afterSubmit,
        relay: { environment },
      },
      setSubmitting,
    }: FormikActions & { props: PropsType },
  ) => {
    commitEMRUpdate({
      environment,
      onCompleted: afterSubmit,
      onError() {
        setSubmitting()
      },
      variables: {
        input: {
          id,
          individual_detail: {
            emr_user_id,
          },
        },
      },
    })
  },
}

export default withFormik(formikMethods)(EditEmrFormBase)
