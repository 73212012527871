// @flow

import React from 'react'
import { connect } from 'react-fela'
import { useTranslation } from 'react-i18next'
import compose from 'lodash/fp/compose'

import FitbitLogo from 'react-ui/assets/images/FitbitLogo.svg'
import { Container } from 'react-ui/components/Grid'
import Heading from 'react-ui/components/Heading'
import Link from 'react-ui/components/Link'
import {
  trackFitbitConnected,
  trackFitbitNotInterested,
} from 'services/analytics/events'
import {
  privacyPolicyUrl,
  termsAndConditionsUrl,
} from 'services/commonExternalUrls'
import { Button } from 'care-ui'

import { styleRules } from './FitbitConnectActivity.style'

import type { FelaPropsType } from 'react-ui/typing'

type PropTypes = FelaPropsType & {
  authLink: string,
  closeModal?: boolean,
}

const FitbitConnectActivity = (props: PropTypes) => {
  const { authLink, closeModal, rules, styles } = props

  const { t: translation } = useTranslation()

  return (
    <Container extend={rules.container}>
      <FitbitLogo className={styles.logo} />
      <Heading level={2} extend={() => ({ textAlign: 'center' })}>
        {translation('benefits_of_connecting_fitbit_to_the_innowell_platform')}
      </Heading>

      <ul className={styles.list}>
        <li>{translation('track_your_activity_and_sleep_progress')}</li>

        <li>
          {translation(
            'view_your_fitbit_data_in_the_context_of_your_health_and_wellbeing',
          )}
        </li>

        <li>{translation('get_realtime_updates_via_your_dashboard')}</li>
      </ul>

      <span className={styles.note}>
        {translation(
          'you_can_always_disconnect_reconnect_at_any_time_here_is_more_information_on_innowells',
        )}{' '}
        &nbsp;
        <Link to={privacyPolicyUrl()}>{translation('privacy_policy')}</Link>
        &nbsp;{translation('and')}&nbsp;
        <Link to={termsAndConditionsUrl()}>
          {translation('terms_and_conditions')}
        </Link>
        .
      </span>

      <div className={styles.buttonGroup}>
        <Button
          dataTestId="fitbit-no"
          variant="secondary"
          onClick={compose(closeModal, trackFitbitNotInterested)}
        >
          {translation('no_not_interested')}
        </Button>

        <Button
          dataTestId="fitbit-yes"
          variant="primary"
          onClick={trackFitbitConnected}
          href={authLink}
          target="_blank"
        >
          {translation('yes_i_want_to_connect')}
        </Button>
      </div>
    </Container>
  )
}

export default connect(styleRules)(FitbitConnectActivity)
