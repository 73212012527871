// @flow

import React from 'react'
import { useFela } from 'react-fela'
import { useTranslation } from 'react-i18next'
import { useFragment, useLazyLoadQuery, useRelayEnvironment } from 'react-relay'

import Card from 'react-ui/components/Card'
import ToggleButton from 'react-ui/components/Form/ToggleButton'
import Heading from 'react-ui/components/Heading'
import Link from 'react-ui/components/Link'
import { commit as commitNotificationSettings } from 'mutations/IndividualDetail/NotificationPreferenceUpdateInput'
import { privacyPolicyUrl } from 'services/commonExternalUrls'
import { SuspenseLoader } from 'care-ui'

import { fragment, query } from '../../queries/UserNotificationsPage'

import { cardStyle, toggleStyle } from './UserNotificationsPage.style'

const UserNotificationsPage = () => {
  const {
    viewer: { currentUser },
  } = useLazyLoadQuery(query)
  const user = useFragment(fragment, currentUser)
  const { css } = useFela()
  const environment = useRelayEnvironment()

  const setPreferences = () => {
    commitNotificationSettings({
      environment,
      variables: {
        input: {
          id: user.notification_preference.id,
          notification_preference: {
            sms: !user.notification_preference.sms,
          },
        },
      },
    })
  }

  const { t: translation } = useTranslation()

  return (
    <>
      <Heading level={3}>{translation('sms_notifications')}</Heading>

      <Card>
        <div className={css(cardStyle)}>
          <div className={css(toggleStyle)}>
            <ToggleButton
              id="notification_settings"
              onChange={setPreferences}
              checked={user.notification_preference.sms}
            />
          </div>
        </div>

        <p>
          {translation(
            'your_mobile_number_will_be_used_to_send_you_reminders_and_notifications_you_can_turn_sms_notifications_on_or_off_anytime',
          )}
        </p>

        <p>
          {translation(
            'innowell_will_never_share_your_mobile_number_if_you_want_more_information_on_your_data_please_see_our',
          )}

          <Link to={privacyPolicyUrl()}>{translation('privacy_policy')}</Link>
        </p>
      </Card>
    </>
  )
}

export const UserNotificationsPageLoader = () => {
  const { t: translation } = useTranslation()

  return (
    <SuspenseLoader message={translation('notifications_settings')}>
      <UserNotificationsPage />
    </SuspenseLoader>
  )
}
