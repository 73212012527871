// @flow

import React from 'react'
import { useFela } from 'react-fela'
import { useTranslation } from 'react-i18next'

import { buildLocalizationKey } from 'platform_web/utility/buildLocalizationKey'
import { Icon } from 'care-ui'
import StarFilled from 'care-ui/atoms/icons/star-filled.svg'
import StarOutline from 'care-ui/atoms/icons/star-outline.svg'

import {
  bodyStyle,
  containerStyle,
  ratingStyle,
  scoreContainerStyle,
  titleStyle,
} from './HealthCardBody.style'

type PropsType = {
  lastUpdate: string,
  onClick: () => void,
  rating: string,
  selectable?: boolean,
  selected?: boolean,
  title: string,
  trend: string,
}

const HealthCardBody = (props: PropsType) => {
  const {
    lastUpdate,
    onClick,
    rating,
    selectable,
    selected,
    title,
    trend,
  } = props

  const { css } = useFela()

  const icon = selected ? StarFilled : StarOutline

  // Localization
  const { t: translation } = useTranslation()

  const withTrend = (
    <div className={css(containerStyle)}>
      <div className={css(ratingStyle)}>{rating}</div>

      <div className={css(scoreContainerStyle)}>
        <div>{trend && translation(buildLocalizationKey(trend))}</div>

        <div>{lastUpdate}</div>
      </div>
    </div>
  )

  const withoutTrend = (
    <div className={css(scoreContainerStyle)}>
      <div className={css(ratingStyle)}>{rating}</div>

      <Icon as={icon} />
    </div>
  )

  return (
    <div
      className={css(bodyStyle)}
      onClick={onClick}
      onKeyPress={onClick}
      role="button"
      tabIndex={0}
    >
      <div className={css(titleStyle)}>{title}</div>

      {selectable ? withoutTrend : withTrend}
    </div>
  )
}

export default HealthCardBody
