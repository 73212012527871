// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import Loadable from 'react-loadable'

import { LoadingSpinner } from 'care-ui'

const LoadingComponent = () => {
  const { t: translation } = useTranslation()

  return <LoadingSpinner message={translation('loading')} />
}

export const ConnectedOnboardingIndividualPage = Loadable({
  loader() {
    return import('./Individual').then((cjsModule) => cjsModule.default)
  },
  loading: LoadingComponent,
  render: React.createElement,
})

export const ConnectedSetPasswordPage = Loadable({
  loader() {
    return import('./SetPassword').then((cjsModule) => cjsModule.default)
  },
  loading: LoadingComponent,
  render: React.createElement,
})

export const ConnectedQuestionnaireIndividualPage = Loadable({
  loader() {
    return import('./IndividualQuestionnaire').then(
      (cjsModule) => cjsModule.default,
    )
  },
  loading: LoadingComponent,
  render: React.createElement,
})
