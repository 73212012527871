// @flow

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { concat, curry, pull } from 'lodash/fp'

import { FormError } from 'react-ui/components/Form'
import Spacer from 'react-ui/components/Spacer'
import createComponentId from 'shared/services/id'
import { CheckboxSet } from 'shared/ui/Forms/Fields'

import { type PropsType } from '.'

const selectOptions = (options) =>
  options.map(({ label, value }) => ({
    title: label,
    value,
  }))

const handleChange = curry(
  (props: PropsType, event: SyntheticInputEvent<*>) => {
    const { target } = event
    const { value, onTouched } = props
    onTouched()
    if (target.checked) {
      props.onChange(concat(target.value, value))
    } else {
      props.onChange(pull(target.value, value))
    }
  },
)

const defaultId = createComponentId(__filename)

const CheckboxesWidget = (props: PropsType) => {
  const { t: translation } = useTranslation()
  const [touched, setTouched] = useState(false)

  const {
    componentId = defaultId,
    disabled,
    options: { enumOptions },
    value,
    rawErrors,
  } = props

  const onTouched = () => setTouched(true)

  return (
    <div className={componentId}>
      {translation('select_all_that_apply')}:
      <CheckboxSet
        disabled={disabled}
        name={Math.random().toString()}
        onChange={handleChange({ ...props, onTouched })}
        options={selectOptions(enumOptions)}
        uiStyle="primary"
        selectedValues={value}
      />
      <Spacer units={1} />
      {touched && rawErrors && <FormError>{rawErrors}</FormError>}
    </div>
  )
}

export default CheckboxesWidget
