// @flow

import React, { type Node } from 'react'
import { connect } from 'react-fela'
import { useTranslation } from 'react-i18next'

import DropDown, { DropDownTrigger } from 'react-ui/components/DropDown'

import type { FelaRulesType } from 'react-ui/typing'

type PropsType = {
  children: Node,
  componentId?: string,
  label?: string,
  rules: FelaRulesType,
  simple: boolean,
}

const styleRules = () => {
  const popOverContainerStyle = {
    width: 'max-content',
    height: 'auto',
    paddingTop: '14px',
    paddingBottom: '14px',
  }
  return {
    popOverContainer: {
      ...popOverContainerStyle,

      ':before': {
        left: 'initial',
        right: '20px',
      },
    },
    simplePopOverContainer: {
      ...popOverContainerStyle,
      maxWidth: 'max-content',
      padding: '0.16rem 0',
      top: '3px',
      right: '10px',
      borderRadius: '5px 0 5px 5px',

      ':before': {
        display: 'none',
      },
    },
  }
}

const tetherProps = {
  attachment: 'top right',
  targetAttachment: 'bottom right',
  offset: '0 40px',
  constraints: [
    {
      to: 'window',
      attachment: 'together',
    },
  ],
}

const ActionsDropDown = (props: PropsType) => {
  const {
    componentId = 'ActionsDropDown',
    label,
    rules,
    children,
    simple = false,
  } = props
  const { t: translation } = useTranslation()

  const defaultLabel = translation('actions')

  const popOverContainerStyle = simple
    ? rules.simplePopOverContainer()
    : rules.popOverContainer()
  const simpleTetherOffset = simple ? { offset: '40px 50px' } : undefined
  const extentTether = simple ? { tether: { zIndex: 0 } } : {}

  return (
    <DropDown
      componentId={`DropDown-${componentId}`}
      {...tetherProps}
      {...simpleTetherOffset}
      renderTrigger={({ onToggleOpen }) => (
        <DropDownTrigger
          label={label || defaultLabel}
          onToggleOpen={onToggleOpen}
          simple={simple}
        />
      )}
      extend={() => ({ container: popOverContainerStyle, ...extentTether })}
    >
      {children}
    </DropDown>
  )
}

export default connect(styleRules)(ActionsDropDown)
