// @flow

import React, { type Node, useState } from 'react'
import { connect } from 'react-fela'
import { useTranslation } from 'react-i18next'
import onClickOutside from 'react-onclickoutside'
import { flowRight, getOr } from 'lodash/fp'

import Link from 'react-ui/components/Link'
import ParticipantGroup from 'react-ui/components/ParticipantGroup'
import { QuestionnaireRefreshLoader } from 'platform_web/components/QuestionnaireRefresh'
import { personDetailsFromUser } from 'platform_web/components/UserResource/services'
import { usePolicies } from 'platform_web/hooks/usePolicies'

import TrackableResultCardButtons from './TrackableResultCardButtons'
import TrackableResultCardMenu from './TrackableResultCardMenu'

import type { FelaPropsType } from 'react-ui/typing'

const styleRules = ({ theme }) => ({
  TrackableResultCardBottom: {
    fontSize: '0.8em',
    cursor: 'pointer',
  },

  TrackableResultCardBottomMenuItem: {
    display: 'flex',
    padding: '0',
    fontSize: '16px',
    alignItems: 'center',
    height: '100%',
    color: theme.palette.component.primary.text,
    '&:hover': {
      color: theme.palette.component.primary.text,
    },
  },
})

type TrackableResultCardBottomType = FelaPropsType & {
  content: Node,
  participants: any,
  questionnaire: any,
  requireSupport: boolean,
  userId: string,
  viewCardDetails: Node,
}

const TrackableResultCardBottom = ({
  styles,
  rules,
  questionnaire,
  userId,
  participants,
  requireSupport,
  viewCardDetails,
}: TrackableResultCardBottomType) => {
  const [isOpen, setIsOpen] = useState(false)

  const onToggle = (val) => setIsOpen(val)

  const policies = usePolicies()

  // Localization
  const { t: translation } = useTranslation()

  const answerQuestions = policies.CAN_CREATE_USER_QUESTIONNAIRE ? (
    <QuestionnaireRefreshLoader
      questionnaire={questionnaire}
      cta={
        <div
          className={styles.TrackableResultCardBottomMenuItem}
          data-component-id="answer-questions"
        >
          {translation('retake_questionnaire')}
        </div>
      }
    />
  ) : null

  const viewAnswers = (
    <Link
      to={{
        name: 'user_questionnaire_details',
        params: {
          id: userId,
          questionnaire_id: getOr(null, 'id')(questionnaire),
        },
      }}
      extend={{ LinkClass: rules.TrackableResultCardBottomMenuItem }}
    >
      {translation('view_answers')}
    </Link>
  )

  const participantsList = participants
    ? participants.map((p) => personDetailsFromUser(p))
    : []

  const participantGroup = participantsList && (
    <ParticipantGroup
      participants={participantsList}
      withEmptySlot={requireSupport}
    />
  )
  const options = [viewCardDetails, answerQuestions, viewAnswers].filter(
    (value) => !!value,
  )

  return (
    <div className={styles.TrackableResultCardBottom}>
      <TrackableResultCardButtons
        content={participantGroup}
        isOpen={isOpen}
        toggleMenu={() => onToggle(!isOpen)}
      />
      <TrackableResultCardMenu options={options} isOpen={isOpen} />
    </div>
  )
}

const enhance = flowRight([onClickOutside, connect(styleRules)])(
  TrackableResultCardBottom,
)

export default enhance
