// @flow

import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { userLoggedOut as userLoggedOutAction } from 'redux/currentuser/actions'
import { useRouterReset } from 'services/routeConfig/Router'
import { signOutRequest } from 'shared/services/api/session'
import { LoadingSpinner } from 'care-ui'

function SigningOutPageDevise() {
  const resetRouter = useRouterReset()
  const dispatch = useDispatch()

  // Localization
  const { t: translation } = useTranslation()

  useEffect(() => {
    signOutRequest({
      onSuccess: () => {
        resetRouter()
        dispatch(userLoggedOutAction())
        window.location.replace('/users/sign_in')
      },
    })
  }, [])

  return <LoadingSpinner message={translation('signing_out')} />
}

export default function SigningOutPage() {
  const Component = SigningOutPageDevise
  return <Component />
}
