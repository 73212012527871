// @flow

import React from 'react'
import { useFela } from 'react-fela'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useFragment } from 'react-relay'
import { get, getOr } from 'lodash/fp'

import { generateInitials } from 'shared/services/user'
import { useQuestionnaireSession } from 'platform_web/features/Questionnaire'
import { Avatar } from 'care-ui'

import HealthCardMenu from '../HealthCardMenu'

import { containerStyle, ctaStyle, linkStyle } from './HealthCardFooter.style'
import { healthCardFragment } from './query/HealthCardFooter'

import type { HealthCardFooter_user_trackable$key } from './query/__generated__/HealthCardFooter_user_trackable.graphql'

type PropsType = {
  +user_trackable: HealthCardFooter_user_trackable$key,
}

const HealthCardFooter = (props: PropsType) => {
  const { user_trackable: fragmentRef } = props
  const user_trackable = useFragment(healthCardFragment, fragmentRef)
  const [isIndividual] = useSelector(({ currentUser: { roleType } }) => [
    roleType === 'INDIVIDUAL',
  ])
  const { css } = useFela()

  const participants = get('participants')(user_trackable)
  const updateRequired = get('refresh_required')(user_trackable) && isIndividual
  const requireSupport = get('requires_support')(user_trackable)
  const questionnaireId = getOr(
    null,
    'primary_user_questionnaire.questionnaire.id',
  )(user_trackable)

  const groupInitials = participants?.map(({ name }) => {
    const initials = generateInitials(name)
    return initials
  })

  const { start } = useQuestionnaireSession({
    answererRoleType: 'INDIVIDUAL',
    questionnaireId,
  })

  const { t: translation } = useTranslation()

  return (
    <div className={css(containerStyle)}>
      <Avatar avatarInitials={groupInitials} requireSupport={requireSupport} />

      <div className={css(ctaStyle)}>
        {updateRequired && (
          <div
            className={css(linkStyle)}
            role="button"
            tabIndex="0"
            onKeyPress={start}
            onClick={start}
          >
            {translation('update')}
          </div>
        )}

        <HealthCardMenu user_trackable={user_trackable} />
      </div>
    </div>
  )
}

export default HealthCardFooter
