// @flow

import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { FlexContainer } from 'care-ui'
import TextNew from 'care-ui/molecules/TextNew/TextNew'

const TinyLoader = () => {
  // Localization
  const { t: translation } = useTranslation()

  return (
    <FlexContainer justifyContent="center">
      <TextNew as="p" typography="bodyMd">
        {translation('loading')}
      </TextNew>
    </FlexContainer>
  )
}

export default TinyLoader
