// @flow

import React, { Suspense, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { capitalize } from 'lodash'

import SelectedChips from 'platform_web/components/SelectedChips'
import useMultiRoleAssignment from 'platform_web/pages/Staff/hooks/useMultiRoleAssignment'
import type { UseMultiSelectReturnTypes } from 'platform_web/pages/Staff/hooks/useMultiSelect'
import { type QueryVariablesType } from 'platform_web/pages/Staff/Individuals/hooks/useIndividualTable'
import {
  Box,
  Button,
  FlexContainer,
  FlexItem,
  IconNew,
  Modal,
  ScrollableBox,
  SearchBar,
  Text,
} from 'care-ui'
import { AssessmentIcon } from 'care-ui/atoms/icons-new'

import AssignableIndividualsList from './AssignableIndividualsList'

type AssignIndividualsModalPropsType = {
  closeModal: () => void,
  isOpen: boolean,
  multiSelectPayload: UseMultiSelectReturnTypes,
  mutationQuery: any,
  queryVariables: QueryVariablesType,
}

const MODAL_STEPS = {
  SELECT_INDIVIDUALS: 1,
  CONFIRMATION: 2,
  RESULT: 3,
}

const AssignIndividualsModal = (props: AssignIndividualsModalPropsType) => {
  const {
    closeModal,
    mutationQuery,
    isOpen,
    multiSelectPayload,
    queryVariables,
  } = props

  // Search
  const [searchString, setSearchString] = useState()

  const useMultiRoleAssignmentPayload = useMultiRoleAssignment({
    closeModal,
    multiSelectPayload,
    mutationQuery,
    queryVariables,
  })

  const [currentStep, setCurrentStep] = useState(MODAL_STEPS.SELECT_INDIVIDUALS)
  const {
    assignRoles,
    toggleAssignedRoleIds,
    failedList,
    isInFlight,
  } = useMultiRoleAssignmentPayload

  const [selectedIndividuals, setSelectedIndividuals] = useState([])

  const toggleSelectedIndividual = (selectedIndividual) => {
    const individual = selectedIndividuals.find(
      (i) => i.id === selectedIndividual.id,
    )

    const updatedSelectedIndividuals = individual
      ? selectedIndividuals.filter((item) => item.id !== selectedIndividual.id)
      : [...selectedIndividuals, selectedIndividual]

    toggleAssignedRoleIds(selectedIndividual.id)
    setSelectedIndividuals(updatedSelectedIndividuals)
  }

  const { t: translation } = useTranslation()

  const formattedIndividualText = translation('individual', {
    count: selectedIndividuals?.length,
  })

  const formattedClinicianText = translation('clinician', {
    count: failedList?.length,
  })

  const renderModalContent = () => {
    switch (currentStep) {
      case MODAL_STEPS.SELECT_INDIVIDUALS:
        return (
          <FlexContainer direction="column" gap="xxxs" wrap="nowrap">
            <Box height={selectedIndividuals.length ? '10vh' : '5vh'}>
              <SearchBar
                fullWidth
                clearSearch={() => setSearchString('')}
                inputAttributes={{
                  onChange: (e) => setSearchString(e.target.value),
                  value: searchString,
                }}
              />
              <SelectedChips
                selectedItems={selectedIndividuals}
                toggleSelectedItems={toggleSelectedIndividual}
              />
            </Box>

            <Box height="50vh" marginY="xs">
              <Suspense fallback={<center>{translation('loading')}</center>}>
                <AssignableIndividualsList
                  useMultiRoleAssignmentPayload={useMultiRoleAssignmentPayload}
                  multiSelectPayload={multiSelectPayload}
                  queryVariables={queryVariables}
                  searchString={searchString}
                  toggleSelectedIndividual={toggleSelectedIndividual}
                />
              </Suspense>
            </Box>
          </FlexContainer>
        )
      case MODAL_STEPS.CONFIRMATION:
        return (
          <>
            <FlexContainer>
              <Text dataTestId="ModalBodyMainText">
                {translation('you_are_about_to_assign')}&nbsp;
                <Text as="span" dataTestId="ModalBodyWarningText" bold>
                  {selectedIndividuals?.length} {formattedIndividualText}&nbsp;
                </Text>
                {translation('to_the_selected_clinicians')}
              </Text>
              <Box paddingX="sm">
                <Text dataTestId="ModalConfirmationText">
                  {translation('are_you_sure_you_want_to_continue')}
                </Text>
              </Box>
            </FlexContainer>
            <FlexContainer paddingX="sm" justifyContent="space-between">
              <Button
                dataTestId="CloseModalButton"
                onClick={closeModal}
                size="md"
                type="button"
                variant="text"
              >
                {translation('no')}
              </Button>
              <Button
                variant="primary"
                dataTestId="ConfirmAssignIndividuals"
                onClick={() => {
                  assignRoles(true)
                  setCurrentStep(MODAL_STEPS.RESULT)
                }}
              >
                {translation('yes_assign')} {formattedIndividualText}
              </Button>
            </FlexContainer>
          </>
        )
      case MODAL_STEPS.RESULT:
        return (
          !isInFlight && (
            <>
              <FlexContainer
                justifyContent="space-between"
                alignItems="center"
                wrap="nowrap"
                gap="xs"
              >
                <FlexItem flex={1}>
                  <IconNew as={AssessmentIcon} size="xl" color="success" />
                </FlexItem>
                <Text>
                  {capitalize(formattedIndividualText)}&nbsp;
                  {translation(
                    'assigned_please_allow_around_5_minutes_for_assignments_to_complete',
                  )}
                </Text>
              </FlexContainer>
              {failedList?.length > 0 && (
                <>
                  <Box
                    borderRadius="sm"
                    border="lighter"
                    paddingY="xxs"
                    marginY="xs"
                    backgroundColor="accentLighter"
                  >
                    <Text margin="xs">
                      {failedList?.length} {formattedClinicianText}&nbsp;
                      {translation('could_not_be_assigned')}{' '}
                      {formattedIndividualText}
                    </Text>
                  </Box>
                  <Text margin="xxxs">
                    {translation(
                      'following_invitations_may_have_failed_because_one_or_more_clinicians_may_not_yet_be_active',
                    )}
                  </Text>
                  <ScrollableBox maxHeight="225px" backgroundColor="subtle">
                    <Box paddingY="xxs" paddingX="xxs">
                      {failedList?.map((clinician) => (
                        <Text margin="xxs" key={clinician}>
                          {clinician}
                        </Text>
                      ))}
                    </Box>
                  </ScrollableBox>
                </>
              )}
            </>
          )
        )
      default:
        return null
    }
  }

  const renderFooterButtons = () => {
    if (currentStep === MODAL_STEPS.SELECT_INDIVIDUALS) {
      return (
        <Button
          variant="primary"
          onClick={() => setCurrentStep(MODAL_STEPS.CONFIRMATION)}
          dataTestId="assignIndividuals"
          disabled={!selectedIndividuals.length}
        >
          {translation('continue')}
        </Button>
      )
    }

    if (currentStep === MODAL_STEPS.RESULT) {
      return (
        <Button onClick={closeModal} size="md" type="button" variant="primary">
          {translation('done')}
        </Button>
      )
    }

    return null
  }

  return (
    <Modal
      width="500px"
      isOpen={isOpen}
      closeModal={closeModal}
      heading={
        currentStep === MODAL_STEPS.RESULT
          ? `${translation('individual_other')} ${translation('assigned')}`
          : `${translation('assign')} ${translation('individual_other')}`
      }
      dataTestId="AssignIndividualsModal"
      footer={renderFooterButtons()}
    >
      {renderModalContent()}
    </Modal>
  )
}

export default AssignIndividualsModal
