// @flow

import enTexts from './enTranslations.json'
import frTexts from './frTranslations.json'

// react-i18n requires the translations text to be under translation namespace
const resources = {
  en: { translation: enTexts },
  fr: { translation: frTexts },
}

export default resources
