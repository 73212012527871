// @flow

import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSetState } from 'react-use'

import { useCurrentUser } from 'react-ui/contexts/UserContext'

import { ESCALATIONS_TABLE_CONFIGURATIONS } from '../constants/EscalationsTableConstants'
import { COUNT } from '../constants/TableConstants'

export type UpdateQueryVariablesType = ({ [string]: string | boolean }) => void

export type QueryVariablesType = {
  [string]: string | boolean,
}

const getEscalationsTableType = (tenant, translation) => {
  const filters = [
    { label: translation('active'), value: 'open_notifications' },
  ]

  if (tenant) {
    const { stage_1_escalation_timeout, stage_2_escalation_timeout } = tenant
    if (stage_1_escalation_timeout && stage_2_escalation_timeout) {
      filters.push({
        label: translation('open_between_time1_hours_and_time2_hours', {
          time1: stage_1_escalation_timeout,
          time2: stage_2_escalation_timeout,
        }),
        value: 'open_stage1_notifications',
      })
    }
    const time = stage_2_escalation_timeout || stage_1_escalation_timeout
    if (time) {
      const stage = stage_2_escalation_timeout ? 'stage2' : 'stage1'
      filters.push({
        label: translation('open_more_than_time_hours', { time }),
        value: `open_${stage}_notifications`,
      })
    }
  }

  filters.push({
    label: translation('actioned'),
    value: 'resolved_notifications',
  })
  return filters
}

export type UseEscalationsTableReturnType = {
  count: number,
  escalationsTableType: Array<{ [string]: string }>,
  queryVariables: QueryVariablesType,
  setTableType: (tableType: string) => void,
  showResolvedDetails: boolean,
  tableType: string,
  updateQueryVariables: UpdateQueryVariablesType,
}

type UseEscalationsTableProps = {
  defaultTableType: string,
  tenant: any,
}

const useEscalationsTable = (
  props: UseEscalationsTableProps,
): UseEscalationsTableReturnType => {
  const { defaultTableType, tenant } = props
  const [tableType, setTableType] = useState(defaultTableType)

  const { current_role } = useCurrentUser()
  const assigned = current_role.role_type === 'CLINICIAN'

  const defaultQueryVariables = {
    ...ESCALATIONS_TABLE_CONFIGURATIONS[tableType],
    count: COUNT,
    assigned,
  }

  const showResolvedDetails = defaultQueryVariables.status?.[0] === 'resolved'

  // Logic to handle filter, sort, and search
  // Local state for Individual Table to handle filter, sort, and search query variables
  const [state, setState] = useSetState(defaultQueryVariables)

  const updateQueryVariables: UpdateQueryVariablesType = (
    updatedQueryVariables: QueryVariablesType,
  ) => {
    setState(updatedQueryVariables)
  }

  // Localization
  const { t: translation } = useTranslation()

  return {
    count: COUNT,
    tableType,
    setTableType,
    updateQueryVariables,
    queryVariables: state,
    showResolvedDetails,
    escalationsTableType: getEscalationsTableType(tenant, translation),
  }
}

export default useEscalationsTable
