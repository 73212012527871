// @flow

import React from 'react'
import { connect } from 'react-fela'
import { useTranslation } from 'react-i18next'

import InfoCircle from 'react-ui/assets/icons/information-circle.svg'
import Team from 'react-ui/assets/icons/team.svg'
import QuestionnaireInfoCard from 'react-ui/components/QuestionnaireInfoCard'

import { styleRules } from './UserQuestionnaireInfoWrapper.style'

import type { FelaStylesType } from 'react-ui/typing'

type PropsType = {
  firstContent?: Array<string>,
  firstTitle?: string,
  secondContent?: Array<string>,
  secondTitle?: string,
  styles: FelaStylesType,
}

const UserQuestionnaireInfoWrapper = (props: PropsType) => {
  const { firstContent, firstTitle, secondContent, secondTitle, styles } = props

  const { t: translation } = useTranslation()

  const benefits_of_innowell = [
    translation(
      'results_of_the_questionnaire_are_shared_with_your_clinician_to_help_support_your_mental_health_care',
    ),
    translation(
      'immediate_access_to_your_results_and_a_holistic_overview_of_your_health_and_wellbeing',
    ),
    translation(
      'each_health_area_has_associated_activities_called_care_options_that_provide_tools_for_improvement',
    ),
    translation(
      'complete_the_questionnaire_in_one_sitting_preferred_or_come_back_over_time_if_necessary',
    ),
  ]

  const important_notes = [
    translation('the_innowell_platform_is_not_a_diagnostic_tool'),
    translation('your_information_is_secure_and_strictly_confidential'),
  ]

  return (
    <div className={styles.displayInline}>
      <QuestionnaireInfoCard
        title={firstTitle || translation('benefits_of_innowell')}
        points={firstContent || benefits_of_innowell}
        avatar={Team}
      />

      <QuestionnaireInfoCard
        title={secondTitle || translation('important_notes')}
        points={secondContent || important_notes}
        avatar={InfoCircle}
      />
    </div>
  )
}

export default connect(styleRules)(UserQuestionnaireInfoWrapper)
