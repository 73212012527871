// @flow

import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { graphql } from 'react-relay'
import numeral from 'numeral'

import ReportCard from 'react-ui/components/ReportCard'
import { Table as SegmentationTable } from 'react-ui/components/SegmentationTable'

import { SatisfactionIndividualSignInChart } from '../charts'

import ReportsView from './ReportsView'
import ReportWithSettings from './ReportWithSettings'

type PropsType = {
  +dimensions: $ReadOnlyArray<{
    +key: string,
    +value: string,
  }>,
  print?: boolean,
  title: React.Node,
  +variables: Object,
}

const query = graphql`
  query SatisfactionAcceptabilityReportQuery(
    $from: DateTime!
    $to: DateTime!
    $tenants: [ID!]!
    $clinicians: [ID!]!
    $report_view: ReportView
  ) {
    viewer {
      reports {
        satisfaction_care_option_rating(
          from: $from
          to: $to
          tenants: $tenants
          clinicians: $clinicians
          report_view: $report_view
        ) {
          traces {
            name
            type
            x
            y
          }
        }
      }
    }
  }
`

const bodyStyle = () => ({
  body: {
    maxHeight: '430px',
  },
})

const SatisfactionAcceptabilityReport = ({
  dimensions,
  title,
  variables,
  print,
}: PropsType) => {
  // Localization
  const { t: translation } = useTranslation()

  return (
    <ReportsView print={print} title={title}>
      <ReportWithSettings query={query} variables={variables}>
        {(data) => {
          const {
            satisfaction_care_option_rating: rating,
          } = data.viewer.reports
          const individualsColumn = rating.traces.filter(
            (trace) => trace.name === 'Individuals',
          )
          return (
            <ReportCard
              title={translation('individual_care_option_satisfaction')}
            >
              <div className="SatisfactionCareOptionRatingChart">
                <SegmentationTable
                  extend={bodyStyle}
                  getColumnName={(_, index) => `col${index}`}
                  traces={individualsColumn}
                  defaultSort={{ key: 'col0', direction: 1 }}
                  columnConfig={{
                    label: {
                      format: ({ name }: { color: string, name: string }) => (
                        <span data-test-selector="resource label">{name}</span>
                      ),
                      width: '140px',
                    },
                    col0: {
                      format: (value) => numeral(value).format('0,0'),
                    },
                  }}
                />
              </div>
            </ReportCard>
          )
        }}
      </ReportWithSettings>

      <div className="SatisfactionIndividualSignInChart">
        <SatisfactionIndividualSignInChart
          dimensions={dimensions}
          variables={variables}
        />
      </div>
    </ReportsView>
  )
}

export default SatisfactionAcceptabilityReport
