// @flow

import React, { useState } from 'react'
import { useFela } from 'react-fela'
import { useTranslation } from 'react-i18next'

import { usePolicies } from 'platform_web/features/Policies'
import { SuspenseLoader, Tabs } from 'care-ui'

import { ActivityLogCardContainer } from './ActivityLog'
import {
  containerStyle,
  panelStyle,
  tabContainerStyle,
} from './ActivityPanel.style'
import { ClinicianNoteCardContainer, ClinicianNoteModal } from './ClinicianNote'

type PropsType = {
  assigned: ?boolean,
  userId: string,
}

const ActivityPanel = ({ userId, assigned }: PropsType) => {
  const { css } = useFela()
  const policies = usePolicies()

  const canAddNote =
    assigned &&
    policies.CAN_USE_CLINICIAN_NOTE &&
    policies.CAN_VIEW_CLINICIAN_LINKS

  const recentActivityContent = (
    <SuspenseLoader>
      <ActivityLogCardContainer userId={userId} />
    </SuspenseLoader>
  )

  const clinicianNoteContent = (
    <SuspenseLoader>
      <ClinicianNoteCardContainer userId={userId} />
    </SuspenseLoader>
  )

  const { t: translation } = useTranslation()

  const tabs = [
    {
      label: translation('recent_activity'),
      content: recentActivityContent,
      disabled: !canAddNote,
      dataTestId: 'recentActivityTab',
    },
    ...((canAddNote && [
      {
        label: translation('notes'),
        content: clinicianNoteContent,
        dataTestId: 'notesTab',
      },
    ]) ||
      []),
  ]

  const [activeTab, setActiveTab] = useState(0)

  const handleTabChange = (index) => {
    setActiveTab(index)
  }

  const activeContent = tabs[activeTab].content

  const isClinicianNoteLog = activeTab === 1

  return (
    <div className={css(panelStyle)}>
      <div className={css(tabContainerStyle)}>
        <Tabs tabs={tabs} defaultTab={0} onChange={handleTabChange} />
        {isClinicianNoteLog && <ClinicianNoteModal userId={userId} />}
      </div>
      <div className={css(containerStyle)}>{activeContent}</div>
    </div>
  )
}

export default ActivityPanel
