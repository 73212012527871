// @flow

import React from 'react'
import { useFela } from 'react-fela'
import { useTranslation } from 'react-i18next'

import Tooltip from 'react-ui/components/ToolTip'
import { buildLocalizationKey } from 'platform_web/utility/buildLocalizationKey'

type ClusterProbabilities = {
  +label: string,
  +probability: number,
}

type Props = {
  clusterProbabilities: $ReadOnlyArray<ClusterProbabilities>,
}

const textStyle = () => ({
  marginBottom: '1rem',
})

const listStyle = () => ({
  listStylePosition: 'inside',
  paddingLeft: '5px',
})

const listStyleItem = () => ({
  width: '70%',
  display: 'flex',
  justifyContent: 'space-between',
})

const StratifiedScoringTooltip = ({ clusterProbabilities }: Props) => {
  const { css } = useFela()

  const createStratifiedMarkdown = (translation) => {
    const clusterProbabilitiesList = clusterProbabilities?.map((cluster, i) => (
      <li key={cluster.label} className={css(listStyleItem)}>
        <span>{`${i + 1}. ${translation(
          buildLocalizationKey(cluster.label),
        )}`}</span>
        <span>{cluster.probability}%</span>
      </li>
    ))

    return (
      <div>
        <div className={css(textStyle)}>
          {translation(
            'stratified_scoring_provides_an_overall_summary_of_a_persons_clinical_needs_based_on_their_initial_answers_in_6_areas_depression_anxiety_psychosis_mania_functioning_and_suicidal_thoughts_and_behaviours_the_distinct_groups_have_been_derived_using_clustering_analysis_based_on_meaningful_differences_observed_between_the_groups_this_information_should_be_used_to_guide_personalised_decisions_about_the_level_of_care_a_person_requires',
          )}
        </div>
        <div>
          {translation('breakdown_of_this_individuals_cluster_probability')}
        </div>
        <ol className={css(listStyle)}>{clusterProbabilitiesList}</ol>
      </div>
    )
  }

  // Localization
  const { t: translation } = useTranslation()

  return (
    <Tooltip
      attachment="bottom right"
      targetAttachment="top left"
      width="500"
      offset="0px -90px"
      scale={0.5}
    >
      {createStratifiedMarkdown(translation)}
    </Tooltip>
  )
}

export default StratifiedScoringTooltip
