// @flow

import React, { useState } from 'react'
import { connect } from 'react-fela'
import { useTranslation } from 'react-i18next'
import truncate from 'lodash/fp/truncate'

import { Row } from 'react-ui/components/Grid'
import Modal from 'react-ui/components/Modal'
import Spacer from 'react-ui/components/Spacer'

import { type FelaPropsType } from 'react-ui/typing'

type PropsType = FelaPropsType & {
  characterLimit: number,
  heading?: string,
  subHeading?: string,
  text: string,
}

const styleRules = ({ theme }) => ({
  Modal: {
    maxWidth: '400px',
  },
  ReadMore: {
    width: '100%',
  },
  Button: {
    color: theme.palette.component.muted.base,
    textDecoration: 'underline',
    fontSize: theme.Small.fontSize,
    paddingLeft: '0px',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    border: 'none',
    ':hover': {
      color: theme.palette.component.muted.base,
    },
  },
  Row: {
    padding: '30px',
  },
})

const ReadMoreBase = (props: PropsType) => {
  const [isOpen, setIsOpen] = useState(false)
  const {
    characterLimit = 50,
    heading,
    subHeading,
    text = '',
    rules,
    styles,
  } = props

  const { t: translation } = useTranslation()

  const onToggleOpen = () => setIsOpen((prev) => !prev)

  const isCharacterLimitReached = text.length > characterLimit
  const extractedPhrase = truncate({
    length: characterLimit,
    separator: ' ',
  })(text)

  const readMoreModalButton = () => (
    <>
      <button
        data-testid="openReadMoreModalButton"
        onClick={onToggleOpen}
        type="button"
        className={styles.Button}
      >
        {translation('read_more')}
      </button>

      {isOpen && (
        <Modal
          extend={rules}
          isOpen={isOpen}
          onRequestClose={onToggleOpen}
          shrinkwrap
          showClose={false}
        >
          <Row extend={rules.Row}>
            {heading}
            <Spacer units={1.5} />
            {text}
          </Row>
        </Modal>
      )}
    </>
  )

  return (
    <div className={styles.ReadMore}>
      <div>
        {subHeading}
        <Spacer axis="horizontal" units={0.2} />
        {extractedPhrase}
      </div>
      {isCharacterLimitReached && readMoreModalButton()}
    </div>
  )
}

export default connect(styleRules)(ReadMoreBase)
