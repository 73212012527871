// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import { type FormikProps } from 'formik'

import { type SignInParamsType } from 'shared/services/api/session'
import Form from 'platform_web/components/Form/Form'
import FormField from 'platform_web/components/Form/FormField'
import { Button, FlexContainer } from 'care-ui'

import useSignInForm from '../hooks/useSignInForm'

import RegionSelector from './RegionSelector'

type SubmitHandlerType = (SignInParamsType, FormikProps) => void

type PropsType = {
  onSubmit: SubmitHandlerType,
}

const SignInForm = (props: PropsType) => {
  const { onSubmit } = props

  const { t: translation } = useTranslation()

  const { formFields, initialValues, validationSchema } = useSignInForm({
    translation,
  })

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ formik }) => {
        const {
          isSubmitting,
          values: { email },
        } = formik

        return (
          <FlexContainer gap="md" direction="column" alignItems="center">
            {formFields.map((formField, index) => {
              // Region Selector is not part of form elements
              const isRegionSelectorField = index === 2

              return (
                <>
                  {isRegionSelectorField && (
                    <RegionSelector email={email} translation={translation} />
                  )}
                  <FormField key={formField.id} formField={formField} />
                </>
              )
            })}

            <Button
              variant="primary"
              ariaLabel="sign-in"
              dataTestId="sign-in"
              disabled={isSubmitting}
              type="submit"
            >
              {translation('sign_in')}
            </Button>
          </FlexContainer>
        )
      }}
    </Form>
  )
}

export default SignInForm
