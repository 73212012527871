// @flow

import { useEffect, useState } from 'react'
import type { TFunction } from 'react-i18next'
import { useTranslation } from 'react-i18next'

export type OnboardingStepType = {
  active: boolean,
  completed: boolean,
  label: string,
  order: number,
}

export type OnboardingStepTypes = {
  [key: string]: OnboardingStepType,
}

const getIndividualOnboardingSteps = (
  translation: TFunction,
): OnboardingStepTypes => {
  return {
    PERSONAL_DETAILS: {
      order: 1,
      label: translation('tell_us_a_bit_about_yourself'),
      completed: false,
      active: false,
    },
    RESEARCH_AND_DATA_SHARING: {
      order: 2,
      label: translation('research_and_data_sharing'),
      completed: false,
      active: false,
    },
    INITIAL_QUESTIONNAIRE: {
      order: 3,
      label: translation('initial_questionnaire'),
      completed: false,
      active: false,
    },
    VIEW_RESULTS: {
      order: 4,
      label: translation('view_results'),
      completed: false,
      active: false,
    },
  }
}

export const individualOnboardingStepNumber = {
  PERSONAL_DETAILS: 1,
  RESEARCH_AND_DATA_SHARING: 2,
  INITIAL_QUESTIONNAIRE: 3,
  VIEW_RESULTS: 4,
}

const useOnboardingSteps = () => {
  // prettier-ignore
  const { t: translation } = useTranslation()

  const individualOnboardingSteps = getIndividualOnboardingSteps(translation)

  const [onboardingSteps, setOnboardingSteps] = useState(
    individualOnboardingSteps,
  )

  // prettier-ignore
  const [ currentStep, setCurrentStep ] = useState<number>(0)

  const {
    RESEARCH_AND_DATA_SHARING,
    PERSONAL_DETAILS,
    INITIAL_QUESTIONNAIRE,
    VIEW_RESULTS,
  } = individualOnboardingStepNumber

  useEffect(() => {
    setOnboardingSteps((prevState: OnboardingStepTypes) => {
      const updatedState = { ...prevState }

      if (currentStep >= PERSONAL_DETAILS) {
        if (updatedState.PERSONAL_DETAILS) {
          updatedState.PERSONAL_DETAILS.active = true
        }
      }
      if (currentStep >= RESEARCH_AND_DATA_SHARING) {
        if (updatedState.RESEARCH_AND_DATA_SHARING) {
          updatedState.RESEARCH_AND_DATA_SHARING.active = true
        }
        updatedState.PERSONAL_DETAILS.completed = true
      }
      if (currentStep >= INITIAL_QUESTIONNAIRE) {
        if (updatedState.RESEARCH_AND_DATA_SHARING) {
          updatedState.RESEARCH_AND_DATA_SHARING.completed = true
        }
        updatedState.INITIAL_QUESTIONNAIRE.active = true
      }
      if (currentStep >= VIEW_RESULTS) {
        updatedState.INITIAL_QUESTIONNAIRE.completed = true
        updatedState.VIEW_RESULTS.active = true
      }
      return updatedState
    })
  }, [currentStep])

  return { individualOnboardingSteps, onboardingSteps, setCurrentStep }
}

export default useOnboardingSteps
