// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  createFragmentContainer,
  graphql,
  useRelayEnvironment,
} from 'react-relay'
import { flowRight } from 'lodash/fp'

import { WithAuthWrapper } from 'components/hoc/WithAuthWrapper'
import { UserInviteFormLoader } from 'components/UserInviteForm/UserInviteForm'
import { Container } from 'react-ui/components/Grid'
import SimpleHeading from 'react-ui/components/Heading/SimpleHeading'
import Section from 'react-ui/components/Section'
import { commit as commitInvite } from 'mutations/Role/RoleIndividualInvite'

import type { IndividualsAdminInvitePage_viewer } from './__generated__/IndividualsAdminInvitePage_viewer.graphql'

type PropsType = {
  viewer: IndividualsAdminInvitePage_viewer,
}

const emptyInvitableUser = (tenant?: ?{ +id: string }) => ({
  inviteType: 'EMAIL',
  email: '',
  phone_number: '',
  roleType: 'INDIVIDUAL',
  tenantId: tenant?.id,
  clinicianAssignmentId: '',
  emrUserId: '',
})

const IndividualsAdminInvitePage = (props: PropsType) => {
  const {
    viewer: { currentUser },
  } = props
  const environment = useRelayEnvironment()

  // Localization
  const { t: translation } = useTranslation()

  if (!currentUser || !currentUser?.current_role.tenant) {
    return null
  }

  const {
    current_role: { tenant, tenants },
  } = currentUser

  // For flow's sake, inlining the args to commit
  const onFormSubmit = (values, resetForm) => {
    const { roleType } = values
    if (roleType === 'INDIVIDUAL') {
      commitInvite({
        environment,
        variables: {
          input: values,
        },
      })
    }
    resetForm()
  }

  return (
    <div>
      <Container>
        <SimpleHeading text={translation('invite_individual')} />
      </Container>
      <Section color="white">
        <Container>
          <UserInviteFormLoader
            id="individualInviteForm"
            invitableUser={emptyInvitableUser(tenant)}
            hideMobile={tenant?.hide_mobile_on_invite}
            tenants={tenants}
            onSubmit={onFormSubmit}
          />
        </Container>
      </Section>
    </div>
  )
}

const withAuth = (component) =>
  WithAuthWrapper(component, {
    requiredPolicies: ['CAN_LIST_TENANT_INDIVIDUALS'],
  })

export const IndividualsAdminInvitePageQuery = graphql`
  query IndividualsAdminInvitePage_Query {
    viewer {
      ...IndividualsAdminInvitePage_viewer
    }
  }
`

const withRelay = (component) =>
  createFragmentContainer(component, {
    viewer: graphql`
      fragment IndividualsAdminInvitePage_viewer on Viewer {
        currentUser {
          current_role {
            ... on RoleInterface {
              id
              tenant {
                hide_mobile_on_invite
                id
              }
              tenants {
                ...UserInviteForm_tenants
              }
            }
          }
        }
      }
    `,
  })

export const IndividualsAdminInvitePageLoader = flowRight([
  withRelay,
  withAuth,
])(IndividualsAdminInvitePage)
