// @flow

import React from 'react'
import { connect } from 'react-fela'
import type { TFunction } from 'react-i18next'
import { useTranslation } from 'react-i18next'
import useRouter from 'found/useRouter'

import TickCircle from 'react-ui/assets/icons/tick-circle.svg'
import Button from 'react-ui/components/Button/Button'
import Heading from 'react-ui/components/Heading/Heading'
import Icon from 'react-ui/components/Icon'
import Modal from 'react-ui/components/Modal'
import Spacer from 'react-ui/components/Spacer'
import { linkTo } from 'platform_web/services/defineLinkTo'

import { type UserQuestionnairesQuestionSetType } from './UserQuestionnairesQuestionSet'

import type { FelaPropsType } from 'react-ui/typing'

type PropsType = FelaPropsType & {
  homepage_path: string,
  invalidInteraction: boolean,
  +role: Object,
  +uqQuestionSet: UserQuestionnairesQuestionSetType,
}

const styleRules = () => ({
  Modal: {
    textAlign: 'center',
  },
})

type ModalPropsType = {
  homepage_path: string,
  invalidInteraction: boolean,
  role: Object,
  router: Object,
  translation: TFunction,
  uqQuestionSet: UserQuestionnairesQuestionSetType,
}

export const prepareModalData = ({
  uqQuestionSet,
  role,
  router,
  invalidInteraction,
  homepage_path,
  translation,
}: ModalPropsType) => {
  const {
    finished: uqqsFinished,
    user_questionnaire: { finished: uqFinished },
  } = uqQuestionSet

  if (uqFinished || invalidInteraction) {
    return {
      heading: translation('questionnaire_completed'),
      message: translation(
        'you_have_already_successfully_submitted_this_questionnaire',
      ),
      buttonLabel: translation('home'),
      buttonAction: () => router.push(`${homepage_path}`),
    }
  }
  if (uqqsFinished) {
    return {
      heading: translation('section_completed'),
      message: translation(
        'if_you_wish_to_edit_any_previous_answers_you_can_do_so_via_the_dashboard',
      ),
      buttonLabel: translation('next_section'),
      buttonAction: () =>
        linkTo({
          uqQuestionSet,
          role,
          router,
          roleType: role.role_type,
          specificQuestionnaireAssessmentTaken: false,
        }),
    }
  }
  return null
}

const FinishedAlertModal = ({
  uqQuestionSet,
  role,
  rules,
  invalidInteraction,
  homepage_path,
}: PropsType) => {
  const { router } = useRouter()
  // Localization
  const { t: translation } = useTranslation()
  const data = prepareModalData({
    uqQuestionSet,
    role,
    router,
    invalidInteraction,
    homepage_path,
    translation,
  })
  if (!data) return null
  const { heading, message, buttonLabel, buttonAction } = data

  return (
    <Modal
      isOpen
      showClose={false}
      showCross={false}
      shrinkwrap
      extend={{ Modal: rules.Modal }}
    >
      <Icon as={TickCircle} scale={4} />
      <Heading level={2}>{heading}</Heading>
      <p>{message}</p>
      <Spacer axis="vertical" units={1} />
      <Button shape="pill" onClick={buttonAction}>
        {buttonLabel}
      </Button>
    </Modal>
  )
}

export default connect(styleRules)(FinishedAlertModal)
