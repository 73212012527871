// @flow

import type { TFunction } from 'react-i18next'
import { useTranslation } from 'react-i18next'
import { formatDistance } from 'date-fns/fp'

import { createFlash } from 'platform_web/containers/Flash'

export type HandleEmailClickParams = {
  text: string,
  translation: TFunction,
}

const handleEmailClick = (params: HandleEmailClickParams): void => {
  const { text, translation } = params

  navigator.clipboard
    .writeText(text)
    .then(() =>
      createFlash({
        message: translation('copied_to_clipboard'),
        type: 'success',
        timeout: 1500,
      }),
    )
    .catch(() =>
      createFlash({
        message: translation('copy_failed'),
        type: 'error',
        timeout: 1500,
      }),
    )
}

export type GetLastSeenTextParams = {
  text?: string | null,
  translation: TFunction,
}

const getLastSeenText = (params: GetLastSeenTextParams) => {
  const { text, translation } = params

  if (!text) return 'N/A'
  return `${formatDistance(new Date())(new Date(text))} ${translation('ago')}`
}

const calculateAge = (birthDateString: string): number => {
  const birthDate = new Date(birthDateString)
  const today = new Date()

  let age = today.getFullYear() - birthDate.getFullYear()
  const monthDifference = today.getMonth() - birthDate.getMonth()

  if (
    monthDifference < 0 ||
    (monthDifference === 0 && today.getDate() < birthDate.getDate())
  ) {
    age -= 1
  }

  return age
}

export type AgeGenderDisplayParams = {
  dateText?: string | null,
  genderText: string,
}

const ageGenderDisplay = (params: AgeGenderDisplayParams): string => {
  const { dateText, genderText } = params

  const age = dateText ? calculateAge(dateText) : 'N/A'
  return `${age} / ${genderText}`
}

const usePersonalDetails = () => {
  const { t: translation } = useTranslation()

  return {
    callbacks: {
      ageGenderDisplay: (dateText?: string | null, genderText: string) =>
        ageGenderDisplay({ dateText, genderText }),
      calculateAge: (text: string) => calculateAge(text),
      getLastSeenText: (text?: string | null) =>
        getLastSeenText({ text, translation }),
      handleEmailClick: (text: string) =>
        handleEmailClick({ text, translation }),
    },
  }
}

export default usePersonalDetails
