// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import { useFragment, useLazyLoadQuery } from 'react-relay'

import { Container } from 'react-ui/components/Grid'
import Heading from 'react-ui/components/Heading'
import { ResponsiveLayout } from 'react-ui/components/Layout'
import Section from 'react-ui/components/Section'
import { useCurrentUser } from 'react-ui/contexts/UserContext'
import { SuspenseLoader } from 'care-ui'

import {
  query,
  supporteesFragment,
} from '../../queries/SupportingSupporteeSelectionPage'
import SupportingSupporteeInfoWrapper from '../SupportingSupporteeInfoWrapper'
import SupportingSupporteeQuestionnairesList from '../SupportingSupporteeQuestionnairesList'

function SupportingSupporteeSelectionPage() {
  const { id } = useCurrentUser()
  const { node } = useLazyLoadQuery(query, { id, count: 10, cursor: null })
  const { current_role, name } = node || {}
  const { supportees: supporteesFragmentRef, individual_assignments } =
    current_role || {}
  const supportees = useFragment(supporteesFragment, supporteesFragmentRef)

  const { t: translation } = useTranslation()

  const withSupportees = () => (
    <>
      <SupportingSupporteeQuestionnairesList
        user={node}
        supportees={supporteesFragmentRef}
      />
      <SupportingSupporteeInfoWrapper
        individual_assignments={individual_assignments}
      />
    </>
  )

  const withoutSupportees = (
    <Heading level={3}>{translation('you_are_not_supporting_anyone')}</Heading>
  )

  return (
    <ResponsiveLayout
      title={translation('welcome_name_and_thank_you_for_your_time_today', {
        name,
      })}
    >
      <Section color="white" center>
        <Container>
          {supportees.length <= 0 ? withoutSupportees : withSupportees()}
        </Container>
      </Section>
    </ResponsiveLayout>
  )
}

const SupportingSupporteeSelectionPageLoader = () => {
  const currentUser = useCurrentUser()

  if (currentUser.completed_onboarding) {
    return (
      <SuspenseLoader>
        <SupportingSupporteeSelectionPage />
      </SuspenseLoader>
    )
  }

  return null
}

export default SupportingSupporteeSelectionPageLoader
