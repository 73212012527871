// @flow

import * as React from 'react'
import { createComponent } from 'react-fela'
import { useTranslation } from 'react-i18next'
import { createFragmentContainer } from 'react-relay'
import { useRouter } from 'found'
import { compact, flowRight } from 'lodash/fp'

import { WithAuthWrapper } from 'components/hoc/WithAuthWrapper'
import type { UserResourceEvents } from 'mutations/__generated__/UserResourceChangeStateMutation.graphql'
import { commit as commitUserResourceChangeState } from 'mutations/UserResourceChangeState'
import { Button, Icon } from 'care-ui'
import Close from 'care-ui/atoms/icons/close.svg'

import { query } from '../queries/UserResourceStatusUpdate'

import type { UserResourceStatusUpdate_user_resource } from '../queries/__generated__/UserResourceStatusUpdate_user_resource.graphql'

type PropsType = {
  +relay: Object,
  +user_resource: UserResourceStatusUpdate_user_resource,
}

type isDisabledPropsType = {
  isDisabled?: boolean,
}

type EventButtonPropsType = isDisabledPropsType & {
  eventName: UserResourceEvents,
}

type ButtonComponentPropsType = isDisabledPropsType & {
  handleClick: () => void,
}

const submitEvent = (
  props: PropsType,
  event: UserResourceEvents,
  trackableId: string,
) => {
  const {
    user_resource,
    relay: { environment },
  } = props

  commitUserResourceChangeState({
    environment,
    variables: {
      input: {
        user_id: user_resource.user.id,
        resource_id: user_resource.resource.id,
        trackable_id: trackableId,
        event,
      },
    },
  })
}

const IndividualGetStarted = ({ handleClick }: ButtonComponentPropsType) => {
  const { t: translation } = useTranslation()
  return (
    <Button
      variant="primary"
      onClick={handleClick}
      ariaLabel="Get started"
      dataTestId="get-started"
    >
      {translation('get_started')}
    </Button>
  )
}

const IndividualInProgress = ({ handleClick }: ButtonComponentPropsType) => {
  const { t: translation } = useTranslation()
  return (
    <Button
      variant="primary"
      onClick={handleClick}
      ariaLabel="Cancel progress"
      dataTestId="cancel-progress"
    >
      <span>{translation('in_progress')}</span>
      <Icon as={Close} />
    </Button>
  )
}

const ClinicianStartSupporting = ({
  handleClick,
  isDisabled,
}: ButtonComponentPropsType) => {
  const { t: translation } = useTranslation()
  return (
    <Button
      variant="primary"
      onClick={handleClick}
      disabled={isDisabled}
      ariaLabel="Support"
      dataTestId="support"
    >
      {translation('support')}
    </Button>
  )
}
const ClinicianSupporting = ({ handleClick }: ButtonComponentPropsType) => {
  const { t: translation } = useTranslation()

  return (
    <Button
      variant="primary"
      onClick={handleClick}
      ariaLabel="Cancel support"
      dataTestId="cancel-support"
    >
      <span>{translation('supporting')}</span>
      <Icon as={Close} />
    </Button>
  )
}

const ButtonAndText = createComponent(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  [theme.breakpoints.queries.md]: {
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
}))

const ButtonDescription = createComponent(({ theme }) => ({
  display: 'flex',
  justifyItems: 'center',
  textAlign: 'left',
  paddingTop: '1rem',
  ...theme.care.typography.desktop.bodyMd,
  [theme.care.breakpoints.queries.md]: {
    paddingLeft: '1rem',
    paddingTop: '1.8rem',
  },
}))

const WithDescription = ({
  children,
  description,
}: {
  children: React.Node,
  description: string,
}) => (
  <ButtonAndText>
    {children}
    <ButtonDescription>{description}</ButtonDescription>
  </ButtonAndText>
)

const RequestSupport = ({ handleClick }: ButtonComponentPropsType) => {
  const { t: translation } = useTranslation()
  return (
    <WithDescription
      description={translation(
        'this_care_option_requires_your_clinicians_support',
      )}
    >
      <Button
        variant="primary"
        onClick={handleClick}
        ariaLabel="Request this"
        dataTestId="request-this"
      >
        {translation('request_this')}
      </Button>
    </WithDescription>
  )
}

const CancelSupportRequest = ({ handleClick }: ButtonComponentPropsType) => {
  const { t: translation } = useTranslation()
  return (
    <WithDescription
      description={translation(
        'this_care_option_requires_your_clinicians_support',
      )}
    >
      <Button
        variant="primary"
        onClick={handleClick}
        ariaLabel="Cancel support request"
        dataTestId="cancel-support-request"
      >
        <span>{translation('requested')}</span>
        <Icon as={Close} />
      </Button>
    </WithDescription>
  )
}

const buttonComponents = {
  start_using_resource: IndividualGetStarted,
  stop_using_resource: IndividualInProgress,
  start_supporting: ClinicianStartSupporting,
  stop_supporting: ClinicianSupporting,
  request_support: RequestSupport,
  cancel_support_request: CancelSupportRequest,
}

const EventButton = ({
  eventName,
  isDisabled,
  trackableId,
  ...props
}: PropsType &
  EventButtonPropsType & { trackableId: string }): React.Node | null => {
  const Component = buttonComponents[(eventName: string)]
  if (Component)
    return (
      <Component
        isDisabled={isDisabled}
        handleClick={(event) => {
          const button = event.target
          button.disabled = true
          submitEvent(props, eventName, trackableId)
        }}
      />
    )
  return null
}

const UserResourceStatusUpdateBase = (props: PropsType) => {
  const {
    user_resource: { allowed_events },
  } = props
  const { match } = useRouter()
  const { trackable_id } = match.params

  return compact(
    allowed_events.map((thisEvent) => {
      const disabled = thisEvent.disabled || false

      return (
        <EventButton
          {...(props: any)}
          eventName={thisEvent.event_name}
          key={thisEvent.event_name}
          isDisabled={disabled}
          trackableId={trackable_id}
        />
      )
    }),
  )
}

const withRelay = (component) => createFragmentContainer(component, query)

const withAuth = (component) =>
  WithAuthWrapper(component, {
    // To stop access when individual is read only
    requiredPolicies: ['CAN_UPDATE_USER_RESOURCE_WRITES'],
  })

export const UserResourceStatusUpdateLoader = flowRight([withRelay, withAuth])(
  UserResourceStatusUpdateBase,
)
