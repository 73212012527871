// @flow

import React, { useState } from 'react'
import { useFela } from 'react-fela'
import { useTranslation } from 'react-i18next'
import { createFragmentContainer } from 'react-relay'
import { startCase } from 'lodash'

import { AccordionItem } from 'react-ui/components/Accordion'
import Markdown from 'react-ui/components/Markdown'

import { scoreCardsPageAboutTrackable } from '../../queries/ScoreCardsPageAbout'

import { accordionItemStyle, markdownStyle } from './ScoreCardsPageAbout.style'

import type { ScoreCardsPageAbout_trackable } from '../../queries/__generated__/ScoreCardsPageAbout_trackable.graphql'

type PropsType = {
  trackable: ScoreCardsPageAbout_trackable,
}

const ScoreCardsPageAboutComponent = (props: PropsType) => {
  const { trackable } = props
  const [isOpen, setOpen] = useState(false)
  const onToggleOpen = () => {
    setOpen(!isOpen)
  }
  const { css } = useFela()
  const { label, description } = trackable

  const { t: translation } = useTranslation()

  if (!label || !description) return null

  return (
    <AccordionItem
      withArrow
      extend={({ theme }) => accordionItemStyle(theme, isOpen)}
      isOpen={isOpen}
      onToggleOpen={onToggleOpen}
      heading={`${translation('more_information_about')} ${startCase(label)}`}
    >
      <Markdown source={description} className={css(markdownStyle)} />
    </AccordionItem>
  )
}

export const ScoreCardsPageAbout = createFragmentContainer(
  ScoreCardsPageAboutComponent,
  scoreCardsPageAboutTrackable,
)
