// @flow

import React, { type Element, type Node } from 'react'
import { useTranslation } from 'react-i18next'
import { graphql } from 'react-relay'

import {
  HeroTableWithPercent,
  HeroTableWithTime,
} from 'react-ui/components/HeroTable'
import ReportCard from 'react-ui/components/ReportCard'

import ReportsView from './ReportsView'
import ReportWithSettings from './ReportWithSettings'
import SafetyInitialResultReport from './SafetyInitialResultReport'

type PropsType = {
  print?: boolean,
  title: Node,
  variables: Object,
}

const query = graphql`
  query SafetyReportQuery(
    $from: DateTime!
    $to: DateTime!
    $tenants: [ID!]!
    $clinicians: [ID!]!
    $report_view: ReportView
  ) {
    viewer {
      reports {
        safety_escalation(
          from: $from
          to: $to
          tenants: $tenants
          clinicians: $clinicians
          report_view: $report_view
        ) {
          traces {
            name
            type
            x
            y
          }
        }
        safety_escalation_at_service_entry(
          from: $from
          to: $to
          tenants: $tenants
          clinicians: $clinicians
          report_view: $report_view
        ) {
          traces {
            name
            type
            x
            y
          }
        }
        safety_escalation_to_action(
          from: $from
          to: $to
          tenants: $tenants
          clinicians: $clinicians
          report_view: $report_view
        ) {
          traces {
            name
            type
            x
            y
          }
        }
      }
    }
  }
`

const SafetyReport = ({ print, title, variables }: PropsType) => {
  // Localization
  const { t: translation } = useTranslation()

  return (
    <ReportsView print={print} title={title}>
      <SafetyInitialResultReport variables={variables} />
      <ReportWithSettings query={query} variables={variables}>
        {(data): Element<ReportCard> => {
          const {
            safety_escalation,
            safety_escalation_at_service_entry,
            safety_escalation_to_action,
          } = data.viewer.reports
          return (
            <ReportCard
              title={translation(
                'notifications_for_suicide_thoughts_and_behaviour',
              )}
            >
              <div className="SafetyEscalationAtServiceEntryChart">
                <ReportCard.SubHeading>
                  {translation(
                    'number_of_service_notifications_upon_first_questionnaire_completion',
                  )}
                </ReportCard.SubHeading>
                <HeroTableWithPercent
                  traces={safety_escalation_at_service_entry.traces}
                />
              </div>

              <ReportCard.Divider />

              <div className="SafetyEscalationChart">
                <ReportCard.SubHeading>
                  {translation(
                    'number_of_service_notifications_over_the_time_period_filtered',
                  )}
                </ReportCard.SubHeading>
                <HeroTableWithPercent traces={safety_escalation.traces} />
              </div>

              <ReportCard.Divider />

              <div className="SafetyEscalationToActionChart">
                <ReportCard.SubHeading>
                  {translation(
                    'average_time_for_a_clinician_to_respond_to_a_notification',
                  )}
                </ReportCard.SubHeading>
                <HeroTableWithTime
                  traces={safety_escalation_to_action.traces}
                />
              </div>
            </ReportCard>
          )
        }}
      </ReportWithSettings>
    </ReportsView>
  )
}

export default SafetyReport
