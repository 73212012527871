/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type AvatarListLoader_data$ref = any;
type AvatarLoader_user$ref = any;
type ClinicianNoteActivity_user$ref = any;
type EditEmrModal_individual$ref = any;
type IndividualCliniciansList_individual$ref = any;
type UserActivityCardLoader_user$ref = any;
export type ClinicianIndividualAssignmentStatusEnum = "Explicit" | "Implicit" | "None" | "%future added value";
export type EmrStatusEnumType = "mandatory" | "none" | "optional" | "%future added value";
export type ImportantFormulaKinds = "effectiveness" | "individual" | "safety" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type IndividualsTable_user$ref: FragmentReference;
declare export opaque type IndividualsTable_user$fragmentType: IndividualsTable_user$ref;
export type IndividualsTable_user = {|
  +id: string,
  +individuals_count: number,
  +staff_individuals_page_actions: {|
    +scores: $ReadOnlyArray<string>,
    +standard: $ReadOnlyArray<string>,
  |},
  +tenant?: ?{|
    +id: string,
    +important_formulas: $ReadOnlyArray<{|
      +id: string,
      +kind: ImportantFormulaKinds,
      +label: string,
      +sequence: number,
      +formula_id: string,
      +formula: {|
        +id: string,
        +label: string,
      |},
      +tenant: {|
        +id: string,
        +label: string,
      |},
    |}>,
  |},
  +individuals: {|
    +edges: ?$ReadOnlyArray<?{|
      +questionnaireDueAt: ?any,
      +questionnaireLastAnsweredAt: ?any,
      +clinicianPreviouslyProvidedFeedback: boolean,
      +clinicianCanProvideFeedback: boolean,
      +assignmentStatus: ClinicianIndividualAssignmentStatusEnum,
      +node: ?{|
        +do_with_clinician: {|
          +requested: boolean,
          +question_sets: $ReadOnlyArray<{|
            +id: string,
            +label: string,
          |}>,
        |},
        +duty_of_care_clinicians?: $ReadOnlyArray<{|
          +id: string,
          +user: {|
            +id: string,
            +name: string,
            +$fragmentRefs: AvatarLoader_user$ref,
          |},
        |}>,
        +escalated?: boolean,
        +id?: string,
        +important_formula_results?: $ReadOnlyArray<{|
          +id: string,
          +time_since_updated: ?string,
          +last_updated_at: ?string,
          +formula: {|
            +id: string,
            +label: string,
          |},
          +formula_range: ?{|
            +label: string,
            +value: ?number,
          |},
          +trending: ?{|
            +label: string
          |},
        |}>,
        +initial_questionnaire_status?: string,
        +clinicians?: $ReadOnlyArray<{|
          +$fragmentRefs: AvatarListLoader_data$ref
        |}>,
        +role_status?: string,
        +removed_at?: ?any,
        +tenant?: ?{|
          +stratified_scoring: boolean,
          +summary_questionnaire: ?{|
            +answerable: ?boolean,
            +id: string,
          |},
          +emr_status: EmrStatusEnumType,
          +emr_integration: ?{|
            +emr_provider: {|
              +user_id_field_name: string
            |}
          |},
        |},
        +user?: {|
          +id: string,
          +email: string,
          +name: string,
          +legal_name: ?string,
          +invitation_delta: ?string,
          +preferred_name: ?string,
          +last_seen_at: ?any,
          +invitation_created_at: ?any,
          +invitation_expired: boolean,
          +invitation_requested_at: ?any,
          +invitation_accepted: boolean,
          +invitation_accepted_at: ?any,
          +personal_details: {|
            +date_of_birth: ?any
          |},
          +stratified_scoring_result: ?{|
            +status: string,
            +cluster_probabilities: $ReadOnlyArray<{|
              +label: string,
              +probability: number,
            |}>,
          |},
          +$fragmentRefs: UserActivityCardLoader_user$ref & ClinicianNoteActivity_user$ref,
        |},
        +canBecomeActive?: boolean,
        +individual_detail?: ?{|
          +id: string,
          +emr_user_id: ?string,
          +discharged_at: ?any,
        |},
        +$fragmentRefs: EditEmrModal_individual$ref & IndividualCliniciansList_individual$ref,
      |},
    |}>
  |},
  +$refType: IndividualsTable_user$ref,
|};
export type IndividualsTable_user$data = IndividualsTable_user;
export type IndividualsTable_user$key = {
  +$data?: IndividualsTable_user$data,
  +$fragmentRefs: IndividualsTable_user$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = [
  "individuals"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "assignedToMe",
        "variableName": "assigned"
      },
      {
        "kind": "Variable",
        "name": "filterEscalation",
        "variableName": "filterEscalation"
      },
      {
        "kind": "Variable",
        "name": "helpRequested",
        "variableName": "helpRequested"
      },
      {
        "kind": "Variable",
        "name": "prioritiseEscalations",
        "variableName": "prioritise"
      },
      {
        "kind": "Variable",
        "name": "roleStatus",
        "variableName": "status"
      },
      {
        "kind": "Variable",
        "name": "search",
        "variableName": "search"
      },
      {
        "kind": "Variable",
        "name": "sort",
        "variableName": "sort"
      }
    ],
    "kind": "ObjectValue",
    "name": "filters"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": "duty_of_care_clinicians",
  "args": null,
  "concreteType": "ClinicianRole",
  "kind": "LinkedField",
  "name": "clinicians",
  "plural": true,
  "selections": [
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        (v3/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "AvatarLoader_user"
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v6 = [
  (v1/*: any*/),
  (v5/*: any*/)
],
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "DoWithClinician",
  "kind": "LinkedField",
  "name": "do_with_clinician",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "QuestionSet",
      "kind": "LinkedField",
      "name": "question_sets",
      "plural": true,
      "selections": (v6/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "escalated",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "Formula",
  "kind": "LinkedField",
  "name": "formula",
  "plural": false,
  "selections": (v6/*: any*/),
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "initial_questionnaire_status",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "ClinicianRole",
  "kind": "LinkedField",
  "name": "clinicians",
  "plural": true,
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AvatarListLoader_data"
    }
  ],
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "role_status",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "removed_at",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "Tenant",
  "kind": "LinkedField",
  "name": "tenant",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "stratified_scoring",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Questionnaire",
      "kind": "LinkedField",
      "name": "summary_questionnaire",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "answerable",
          "storageKey": null
        },
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "emr_status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EmrIntegrationType",
      "kind": "LinkedField",
      "name": "emr_integration",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "EmrProvider",
          "kind": "LinkedField",
          "name": "emr_provider",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "user_id_field_name",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "legal_name",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "invitation_delta",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "preferred_name",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "last_seen_at",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "invitation_created_at",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "invitation_expired",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "invitation_requested_at",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "invitation_accepted",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "invitation_accepted_at",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "concreteType": "IndividualPersonalDetail",
  "kind": "LinkedField",
  "name": "personal_details",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "date_of_birth",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "concreteType": "StratifiedScoringResult",
  "kind": "LinkedField",
  "name": "stratified_scoring_result",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ClusterProbability",
      "kind": "LinkedField",
      "name": "cluster_probabilities",
      "plural": true,
      "selections": [
        (v5/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "probability",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v27 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "UserActivityCardLoader_user"
},
v28 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "canBecomeActive",
  "storageKey": null
},
v29 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "EditEmrModal_individual"
},
v30 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "IndividualCliniciansList_individual"
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "assigned"
    },
    {
      "kind": "RootArgument",
      "name": "count"
    },
    {
      "kind": "RootArgument",
      "name": "cursor"
    },
    {
      "kind": "RootArgument",
      "name": "filterEscalation"
    },
    {
      "kind": "RootArgument",
      "name": "helpRequested"
    },
    {
      "kind": "RootArgument",
      "name": "prioritise"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "scoresTable"
    },
    {
      "kind": "RootArgument",
      "name": "search"
    },
    {
      "kind": "RootArgument",
      "name": "sort"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "standardTable"
    },
    {
      "kind": "RootArgument",
      "name": "status"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "count",
          "cursor": "cursor"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./IndividualsTableRefetchQuery.graphql'),
      "identifierField": "id"
    }
  },
  "name": "IndividualsTable_user",
  "selections": [
    (v1/*: any*/),
    {
      "alias": null,
      "args": (v2/*: any*/),
      "kind": "ScalarField",
      "name": "individuals_count",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "IndividualsTableAction",
      "kind": "LinkedField",
      "name": "staff_individuals_page_actions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "scores",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "standard",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "individuals",
      "args": (v2/*: any*/),
      "concreteType": "ClinicianIndividualConnection",
      "kind": "LinkedField",
      "name": "__Individuals_currentUser_individuals_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ClinicianIndividualEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "questionnaireDueAt",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "questionnaireLastAnsweredAt",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "clinicianPreviouslyProvidedFeedback",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "clinicianCanProvideFeedback",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "assignmentStatus",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "IndividualRole",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "DoWithClinician",
                  "kind": "LinkedField",
                  "name": "do_with_clinician",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "requested",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                },
                {
                  "condition": "scoresTable",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    (v4/*: any*/),
                    (v7/*: any*/),
                    (v8/*: any*/),
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "FormulaResult",
                      "kind": "LinkedField",
                      "name": "important_formula_results",
                      "plural": true,
                      "selections": [
                        (v1/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "time_since_updated",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "last_updated_at",
                          "storageKey": null
                        },
                        (v9/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "FormulaRange",
                          "kind": "LinkedField",
                          "name": "formula_range",
                          "plural": false,
                          "selections": [
                            (v5/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "value",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "TrendType",
                          "kind": "LinkedField",
                          "name": "trending",
                          "plural": false,
                          "selections": [
                            (v5/*: any*/)
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    (v10/*: any*/),
                    (v11/*: any*/),
                    (v12/*: any*/),
                    (v13/*: any*/),
                    (v14/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "User",
                      "kind": "LinkedField",
                      "name": "user",
                      "plural": false,
                      "selections": [
                        (v1/*: any*/),
                        (v15/*: any*/),
                        (v3/*: any*/),
                        (v16/*: any*/),
                        (v17/*: any*/),
                        (v18/*: any*/),
                        (v19/*: any*/),
                        (v20/*: any*/),
                        (v21/*: any*/),
                        (v22/*: any*/),
                        (v23/*: any*/),
                        (v24/*: any*/),
                        (v25/*: any*/),
                        (v26/*: any*/),
                        (v27/*: any*/),
                        {
                          "args": null,
                          "kind": "FragmentSpread",
                          "name": "ClinicianNoteActivity_user"
                        }
                      ],
                      "storageKey": null
                    },
                    (v28/*: any*/),
                    (v29/*: any*/),
                    (v30/*: any*/)
                  ]
                },
                {
                  "condition": "standardTable",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    (v7/*: any*/),
                    (v4/*: any*/),
                    (v8/*: any*/),
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "IndividualDetail",
                      "kind": "LinkedField",
                      "name": "individual_detail",
                      "plural": false,
                      "selections": [
                        (v1/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "emr_user_id",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "discharged_at",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    (v10/*: any*/),
                    (v11/*: any*/),
                    (v12/*: any*/),
                    (v13/*: any*/),
                    (v14/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "User",
                      "kind": "LinkedField",
                      "name": "user",
                      "plural": false,
                      "selections": [
                        (v1/*: any*/),
                        (v15/*: any*/),
                        (v3/*: any*/),
                        (v16/*: any*/),
                        (v17/*: any*/),
                        (v18/*: any*/),
                        (v19/*: any*/),
                        (v20/*: any*/),
                        (v21/*: any*/),
                        (v22/*: any*/),
                        (v23/*: any*/),
                        (v24/*: any*/),
                        (v25/*: any*/),
                        (v26/*: any*/),
                        (v27/*: any*/)
                      ],
                      "storageKey": null
                    },
                    (v28/*: any*/),
                    (v29/*: any*/),
                    (v30/*: any*/)
                  ]
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "condition": "scoresTable",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Tenant",
          "kind": "LinkedField",
          "name": "tenant",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": [
                {
                  "kind": "Literal",
                  "name": "kind",
                  "value": "individual"
                }
              ],
              "concreteType": "ImportantFormula",
              "kind": "LinkedField",
              "name": "important_formulas",
              "plural": true,
              "selections": [
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "kind",
                  "storageKey": null
                },
                (v5/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "sequence",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "formula_id",
                  "storageKey": null
                },
                (v9/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Tenant",
                  "kind": "LinkedField",
                  "name": "tenant",
                  "plural": false,
                  "selections": (v6/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": "important_formulas(kind:\"individual\")"
            }
          ],
          "storageKey": null
        }
      ]
    }
  ],
  "type": "User",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f3b1c100f8186903294af958f3ce4d2f';

module.exports = node;
