// @flow

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { createFragmentContainer } from 'react-relay'
import { startCase } from 'lodash/fp'

import MultiStepProgressBar from 'components/MultiStepProgressBar'
import { OnboardingHeader } from 'components/OnboardingHeader'
import { ResponsiveLayout } from 'react-ui/components/Layout'
import { useOnboardingStepContext } from 'react-ui/contexts/OnboardingStepContext'
import { useQuestionnaireSession } from 'platform_web/features/Questionnaire'

import UserQuestionnaireInfoWrapper from './components/UserQuestionnaireInfoWrapper'
import { query } from './queries/UserQuestionnaire'

import type { UserQuestionnaire_role } from './queries/__generated__/UserQuestionnaire_role.graphql'
import type { UserQuestionnaire_user_questionnaire } from './queries/__generated__/UserQuestionnaire_user_questionnaire.graphql'

type PropsType = {
  role: UserQuestionnaire_role,
  user_questionnaire: UserQuestionnaire_user_questionnaire,
}

export const UserQuestionnaireBase = (props: PropsType) => {
  const {
    user_questionnaire: {
      user: { id: answereeUserId },
      ensure_questionnaire: { id: questionnaireId },
      label,
      description,
    },
    role: { role_type: answererRoleType },
  } = props
  const [isLoading, setLoading] = useState(false)
  const { currentOnboardingSteps } = useOnboardingStepContext()

  const { start } = useQuestionnaireSession({
    answereeUserId,
    questionnaireId,
    answererRoleType,
  })

  const handleClick = () => {
    setLoading(true)
    start()
  }

  const { t: translation } = useTranslation()

  const customHeader = (
    <OnboardingHeader
      title={startCase(label)}
      infoText={description}
      button={{
        label: translation('start'),
        onClick: handleClick,
        disabled: isLoading,
      }}
    />
  )

  return (
    <>
      <MultiStepProgressBar steps={currentOnboardingSteps} />

      <ResponsiveLayout customHeader={customHeader} hasEmptyHeader>
        <UserQuestionnaireInfoWrapper />
      </ResponsiveLayout>
    </>
  )
}

export const UserQuestionnaireLoader = createFragmentContainer(
  UserQuestionnaireBase,
  query,
)
