// @flow

import React, { useState } from 'react'
import { useFela } from 'react-fela'
import { useTranslation } from 'react-i18next'
import { createFragmentContainer } from 'react-relay'
import { format } from 'date-fns/esm/fp'

import { AnswerTableLoader } from 'components/AnswerTable'
import { AccordionItem } from 'react-ui/components/Accordion'
import { shortDate } from 'services/dateTime'
import createComponentId from 'shared/services/id'

import { query } from '../../queries/ScoreCardsPreviousAnswerSet'

import {
  accordionItemStyle,
  headerStyle,
} from './ScoreCardsPreviousAnswerSet.style'

import type { ScoreCardsPreviousAnswerSet_answer_set } from '../../queries/__generated__/ScoreCardsPreviousAnswerSet_answer_set.graphql'
import type { ScoreCardsPreviousAnswerSet_user_trackable } from '../../queries/__generated__/ScoreCardsPreviousAnswerSet_user_trackable.graphql'

export type PropsType = {
  answer_set: ScoreCardsPreviousAnswerSet_answer_set,
  broadcastPointData: Function,
  componentId?: string,
  label: string,
  openedFromGraph: boolean,
  result: any,
  selected_answer?: string,
  user_trackable: ScoreCardsPreviousAnswerSet_user_trackable,
}

const defaultId = createComponentId(__filename)

const headingData = (params) => {
  const { css, answer_set, label, resultLabel, translation } = params
  const rawSubmitDate = answer_set.submitted_at ? answer_set.submitted_at : ''
  const submittedAtDate = answer_set.submitted_at
    ? format(shortDate)(new Date(rawSubmitDate))
    : translation('not_submitted')

  return (
    <div className={css(headerStyle)} data-testid="QuestionAnswerRow">
      <div>{label}</div>
      <div data-testid="Rating">{resultLabel}</div>

      <div data-testid="AnsweredOn">{submittedAtDate}</div>

      <div data-testid="Status">
        {answer_set.skipped ? translation('skipped') : translation('completed')}
      </div>

      <div data-testid="AnsweredBy">{answer_set.answered_by?.user?.name}</div>
    </div>
  )
}

const getResultLabel = (result, formula_ranges) => {
  if (!formula_ranges) {
    return ''
  }

  const formulaRange = formula_ranges.find((range) => range.value === result)
  if (!formulaRange) {
    return ''
  }

  return formulaRange.label
}

const ScoreCardsPreviousAnswerSetComponent = (props: PropsType) => {
  const {
    broadcastPointData,
    openedFromGraph,
    result,
    componentId = defaultId,
    label,
    answer_set,
    answer_set: { answers },
    user_trackable: {
      trackable: {
        formula: { formula_ranges },
      },
    },
  } = props
  const { css } = useFela()
  const [isOpen, setOpen] = useState(openedFromGraph)
  const onToggleOpen = () => {
    if (openedFromGraph) {
      broadcastPointData(null)
    }
    setOpen(!isOpen)
  }
  const resultLabel = getResultLabel(result?.value, formula_ranges)
  const divKey = result ? `${result?.date}-${openedFromGraph.toString()}` : ''

  const { t: translation } = useTranslation()

  return (
    <div className={componentId} key={divKey}>
      <AccordionItem
        withArrow
        extend={accordionItemStyle}
        isOpen={isOpen}
        onToggleOpen={onToggleOpen}
        heading={headingData({
          css,
          answer_set,
          label,
          resultLabel,
          translation,
        })}
      >
        <AnswerTableLoader answers={answers} />
      </AccordionItem>
    </div>
  )
}

export const ScoreCardsPreviousAnswerSet = createFragmentContainer(
  ScoreCardsPreviousAnswerSetComponent,
  query,
)
