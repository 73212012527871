// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import Link from 'found/Link'

import { Container } from 'react-ui/components/Grid'
import Heading from 'react-ui/components/Heading'
import Section from 'react-ui/components/Section'
import { privacyPolicyUrl } from 'services/commonExternalUrls'
import { Layout as l } from 'shared/ui/Styles'

const Page = () => {
  const { t: translation } = useTranslation()

  return (
    <Section color="white">
      <Container>
        <Heading level={2}>{translation('research_data_sharing')}</Heading>

        <p>{translation('last_modified_june_2022')}</p>

        <div className={l.bulkTextBlock}>
          <p>
            {translation(
              'thank_you_for_sharing_your_deidentified_data_with_innowell',
            )}
          </p>

          <p>
            {translation(
              'your_personal_information_is_deidentified_and_no_one_can_link_your_name_email_address_or_date_of_birth_to_your_health_information',
            )}
          </p>

          <p>
            {translation(
              'your_personal_information_will_be_always_stored_in_the_innowell_platform_and_only_your_deidentified_data_will_be_used_for_research_purposes_the_deidentified_data_will_be_extracted_used_for_statistical_analysis_and_to_improve_the_overall_effectiveness_of_the_innowell_platform',
            )}
          </p>

          <p>
            {translation(
              'contributing_your_deidentified_data_is_completely_voluntary_and_you_can_change_your_mind_at_any_time_furthermore_your_decision_whether_or_not_to_have_your_deidentified_data_used_for_research_purposes_will_not_affect_your_standard_clinical_care_or_your_current_or_future_relationship_with_your_service_provider_or_health_professional_or_innowell',
            )}
          </p>

          <p>
            {translation(
              'for_more_information_about_how_innowell_uses_data_please_see',
            )}
            <br />
            <Link to={privacyPolicyUrl()}>
              https://www.innowell.org/privacy-policy/
            </Link>
          </p>
        </div>
      </Container>
    </Section>
  )
}
export default Page
