// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import { type GraphQLTaggedNode } from 'relay-runtime'

import type { UseMultiSelectReturnTypes } from 'platform_web/pages/Staff/hooks/useMultiSelect'
import { type QueryVariablesType } from 'platform_web/pages/Staff/Individuals/hooks/useIndividualTable'
import {
  Box,
  Button,
  FlexContainer,
  FlexItem,
  IconNew,
  Modal,
  ScrollableBox,
  Text,
} from 'care-ui'
import { AssessmentIcon } from 'care-ui/atoms/icons-new'

import useIndividualActionsModal from '../../hooks/useIndividualActionsModal'

type ResendInvitationsModalPropsType = {
  closeModal: () => void,
  isOpen: boolean,
  multiSelectPayload: UseMultiSelectReturnTypes,
  mutationQuery: GraphQLTaggedNode,
  queryVariables: QueryVariablesType,
}

const ResendInvitationsModal = (props: ResendInvitationsModalPropsType) => {
  const {
    closeModal,
    mutationQuery,
    isOpen,
    multiSelectPayload,
    queryVariables,
  } = props

  const useIndividualActionsModalPayload = useIndividualActionsModal({
    closeModal,
    queryVariables,
    mutationQuery,
    multiSelectPayload,
  })
  const {
    allSelectedMinusDeselectedCount,
    selectedRoleIdsCount,
    handleDone,
    handleSummaryCloseModal,
    onConfirmation,
    isInFlight,
    summaryState,
    failedList,
  } = useIndividualActionsModalPayload

  const { t: translation } = useTranslation()

  const count = selectedRoleIdsCount || allSelectedMinusDeselectedCount

  const active_individuals_in_another_tenant =
    failedList?.rolesReinvite?.active_individuals_in_another_tenant

  const invitationFailCount = active_individuals_in_another_tenant?.length || 0
  const invitationSentCount = count - invitationFailCount

  const invitationText = translation('invitation', {
    count,
  })

  const invitationToText = translation('invitationTo', {
    count,
  })

  const individualText = translation('individual', {
    count,
  })
  const failedInvitationText = translation('invitation', {
    count: invitationFailCount,
  })

  return (
    <Modal
      width="400px"
      isOpen={isOpen}
      closeModal={summaryState ? handleSummaryCloseModal : closeModal}
      heading={`${
        summaryState
          ? translation('invitations_sent')
          : `${translation('resend')} ${invitationText}`
      }`}
      dataTestId="Resend Modal"
    >
      {!summaryState && (
        <>
          <FlexContainer>
            <Text>
              {translation('you_are_about_to_resend')} {invitationToText}&nbsp;
              {count}&nbsp;{individualText}.
            </Text>
            <Box paddingX="sm">
              <Text>{translation('are_you_sure_you_want_to_continue')}</Text>
            </Box>
          </FlexContainer>
          <FlexContainer paddingX="sm" justifyContent="space-between">
            <Button onClick={closeModal} size="md" type="button" variant="text">
              {translation('no')}
            </Button>
            <Button
              onClick={() => onConfirmation(true)}
              size="md"
              type="button"
              variant="primary"
            >
              {translation('yes_resend')}&nbsp;
              {invitationText}
            </Button>
          </FlexContainer>
        </>
      )}
      {summaryState && !isInFlight && (
        <>
          <FlexContainer
            justifyContent="space-between"
            alignItems="center"
            wrap="nowrap"
            gap="xs"
          >
            <FlexItem flex={1}>
              <IconNew as={AssessmentIcon} size="xl" color="success" />
            </FlexItem>
            <Text>
              {translation(
                'invitations_sent_please_allow_around_5_minutes_for_reinvites_to_reach_individuals',
              )}
            </Text>
          </FlexContainer>
          <Box
            borderRadius="sm"
            border="lighter"
            paddingY="xxs"
            marginY="xs"
            backgroundColor="accentLighter"
          >
            <Text margin="xs">
              {invitationSentCount}&nbsp;
              {invitationText} {translation('sent')}
            </Text>
            <Text margin="xs">
              {invitationFailCount} {failedInvitationText}&nbsp;
              {translation('failed')}
            </Text>
          </Box>
          {invitationFailCount > 0 && (
            <>
              <Text margin="xxxs">
                {translation(
                  'following_invitations_may_have_failed_because_they_are_currently_active_in_another_tenant',
                )}
              </Text>
              <ScrollableBox maxHeight="225px" backgroundColor="subtle">
                <Box paddingY="xxs" paddingX="xxs">
                  {active_individuals_in_another_tenant?.map((individual) => (
                    <Text margin="xxs" key={individual}>
                      {individual}
                    </Text>
                  ))}
                </Box>
              </ScrollableBox>
            </>
          )}

          <FlexContainer marginTop="xs" justifyContent="center">
            <Button
              onClick={handleDone}
              size="md"
              type="button"
              variant="primary"
            >
              {translation('done')}
            </Button>
          </FlexContainer>
        </>
      )}
    </Modal>
  )
}

export default ResendInvitationsModal
