// @flow

import React, { Suspense, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  type PreloadedQuery,
  usePreloadedQuery,
  useQueryLoader,
} from 'react-relay'
import { get } from 'lodash'

import { Graph, GraphContainer } from 'care-ui'
import { navy } from 'care-ui/atoms/colors/colorTokens'

import useGraphPagination from '../../hooks/useGraphPagination'
import { type OverviewQueryVariableType } from '../../hooks/useOverviewInsights'
import { query as individualOnboardingQuery } from '../../queries/IndividualOnboardingInsightsQuery'

import { type IndividualOnboardingInsightsQuery } from '../../queries/__generated__/IndividualOnboardingInsightsQuery.graphql'

type ComponentProps = {
  loadIndividualOnboardingQuery: (variables: OverviewQueryVariableType) => void,
  overviewQueryVariables: OverviewQueryVariableType,
  queryReference: PreloadedQuery<IndividualOnboardingInsightsQuery>,
}

const Component = (props: ComponentProps) => {
  const {
    overviewQueryVariables,
    queryReference,
    loadIndividualOnboardingQuery,
  } = props

  // Use the preloaded querye
  const queryResult = usePreloadedQuery(
    individualOnboardingQuery,
    queryReference,
  )

  const seriesCategories = get(
    queryResult,
    'viewer.insights.individual_onboardings.series_categories',
  )

  const series = get(
    queryResult,
    'viewer.insights.individual_onboardings.series',
  )

  // Pagination
  const { onPaginate, isRightPaginationDisabled } = useGraphPagination({
    overviewQueryVariables,
    loadQuery: loadIndividualOnboardingQuery,
  })

  return (
    <Graph
      colors={[navy[500], navy[400], navy[300]]}
      graphType="column"
      series={series}
      seriesCategories={seriesCategories}
      onPaginateLeft={() => onPaginate('left')}
      onPaginateRight={() => onPaginate('right')}
      isRightPaginationDisabled={isRightPaginationDisabled}
    />
  )
}

type IndividualOnboardingInsightsGraphProps = {
  overviewQueryVariables: OverviewQueryVariableType,
}

// Query Loader
const IndividualOnboardingInsightsGraph = (
  props: IndividualOnboardingInsightsGraphProps,
) => {
  const { overviewQueryVariables } = props

  const [
    individualOnboardingQueryReference,
    loadIndividualOnboardingQuery,
  ] = useQueryLoader(individualOnboardingQuery)

  // Load query when query variables change
  useEffect(() => {
    loadIndividualOnboardingQuery(overviewQueryVariables)
  }, [overviewQueryVariables])

  // transalations
  const { t: translation } = useTranslation()

  return (
    <GraphContainer
      heading={translation('individual_onboarding')}
      graphInfoText={translation(
        'this_graph_visualises_the_number_of_individuals_who_have_successfully_completed_the_onboarding_process_over_the_specified_period',
      )}
    >
      <Suspense>
        {individualOnboardingQueryReference != null && (
          <Component
            overviewQueryVariables={overviewQueryVariables}
            queryReference={individualOnboardingQueryReference}
            loadIndividualOnboardingQuery={loadIndividualOnboardingQuery}
          />
        )}
      </Suspense>
    </GraphContainer>
  )
}

export default IndividualOnboardingInsightsGraph
