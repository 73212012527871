// @flow

import React from 'react'
import type { TFunction } from 'react-i18next'
import { useRelayEnvironment } from 'react-relay'
import { upperCase } from 'lodash'
import { find, flow, get, getOr, sortBy } from 'lodash/fp'

import { ModelSelect2AdvancedLoader as ModelSelect } from 'components/ModelSelect2'
import { Input, Radio } from 'react-ui/components/Form'
import Spacer from 'react-ui/components/Spacer'
import { FormError } from 'shared/ui/Forms'
import { useFeatureToggle } from 'platform_web/hooks/useFeatureToggle'
import TextNew from 'care-ui/molecules/TextNew/TextNew'

import { clinicianQuery } from './query/UserInviteFormTenantCliniciansQuery'

import { type FormikProps } from 'services/flow'

// find the emr information of tenant currently selected for the new role
const getEmrIntegration = (id, tenants) =>
  get('emr_integration')(find({ id })(tenants))

const getAllowAdminClinicalAssignments = (id, tenants) =>
  flow(find({ id }), getOr(false, 'allowAdminClinicalAssignments'))(tenants)

type FieldsForIndividualsProps = {
  formProps: FormikProps,
  inputProps: (arg: string) => {},
  translation: TFunction,
}

const FieldsForIndividuals = (props: FieldsForIndividualsProps) => {
  const { inputProps, formProps, translation } = props
  const {
    errors,
    hideMobile,
    setValues,
    tenants = [],
    touched,
    values,
  } = formProps

  const environment = useRelayEnvironment()
  const { SMS_INVITE } = useFeatureToggle()

  const emrIntegration = getEmrIntegration(values.tenantId, tenants)

  const allowAdminClinicalAssignments = getAllowAdminClinicalAssignments(
    values.tenantId,
    tenants,
  )

  return (
    <>
      {emrIntegration && (
        <>
          <Spacer units={0.5} />
          <label htmlFor="emrUserId">
            <strong>{emrIntegration.emr_provider.user_id_field_name}</strong>
            <Input type="text" {...(inputProps('emrUserId'): any)} />
          </label>
          <FormError errors={errors} touched={touched} attr="emrUserId" />
        </>
      )}
      {!hideMobile && (
        <>
          <Spacer units={0.5} />
          <label htmlFor="phone_number">
            <strong>{translation('mobile_phone')}</strong>
            <Input type="text" {...(inputProps('phone_number'): any)} />
          </label>
          <FormError errors={errors} touched={touched} attr="phone_number" />

          <TextNew as="p" color="caption" typography="bodySm">
            <small>
              {translation(
                'individuals_will_receive_an_sms_from_innowell_reminding_them_to_complete_their_onboarding_individuals_can_update_or_remove_their_mobile_number_on_their_profile_page_via_the_profile_settings_menu_at_any_time',
              )}
            </small>
          </TextNew>
        </>
      )}

      {allowAdminClinicalAssignments && (
        <>
          <Spacer units={0.5} />
          <label htmlFor="clinicianAssignmentId">
            <strong>{translation('assign_clinician')}</strong>
          </label>
          <br />
          <ModelSelect
            {...(inputProps('clinicianAssignmentId'): any)}
            id="clinicianAssignmentId"
            environment={environment}
            query={clinicianQuery}
            field="tenant.assignable_clinicians.nodes"
            kind="tenantClinicians"
            name="clinicianAssignmentId"
            nullLabel={translation('assign_no_one')}
            valueName="name"
            variables={{ id: values.tenantId }}
            pipeline={sortBy('name')}
          />
          <FormError
            errors={errors}
            touched={touched}
            attr="clinicianAssignmentId"
          />
        </>
      )}
      <Spacer units={0.5} />

      {SMS_INVITE && (
        <>
          <label htmlFor="inviteType">
            <strong>{translation('invitation_type')}</strong>
            <Spacer units={0.5} />
            <Radio
              {...(inputProps('inviteType'): any)}
              id="inviteType_SMS"
              checked={values.inviteType === 'SMS'}
              label="SMS"
              name="inviteType"
              onChange={() => {
                setValues({ ...values, inviteType: 'SMS' })
              }}
            />
            <Spacer units={0.5} />
            <Radio
              {...(inputProps('inviteType'): any)}
              id="inviteType_EMAIL"
              checked={values.inviteType === 'EMAIL'}
              label={upperCase(translation('email'))}
              name="inviteType"
              onChange={() => {
                setValues({ values, inviteType: 'EMAIL' })
              }}
            />
          </label>
          <FormError errors={errors} touched={touched} attr="inviteType" />
        </>
      )}
    </>
  )
}

export default FieldsForIndividuals
