// @flow

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { createFragmentContainer, graphql } from 'react-relay'

import Modal from 'react-ui/components/Modal'
import { Button } from 'care-ui'

import IndividualTasksItem from './IndividualTasksItem'

import type { StaffDownloadDataTask_individual } from './__generated__/StaffDownloadDataTask_individual.graphql'

type PropsType = {
  individual: StaffDownloadDataTask_individual,
}
const StaffDownloadDataTaskComponent = ({ individual }: PropsType) => {
  const { t: translation } = useTranslation()
  const { user } = individual
  const [emrModalOpen, setEmrModalOpen] = useState(false)

  const handleClick = () => {
    const newWindow = window.open(
      `/individual/emr?user_id=${user.id}`,
      '_blank',
      'noopener,noreferrer',
    )
    if (newWindow) newWindow.opener = null
  }

  return (
    <>
      <IndividualTasksItem
        header={translation('health_history_pdf')}
        onClick={() => setEmrModalOpen(true)}
      >
        {translation('download_a_pdf_of_their_health_history')}
      </IndividualTasksItem>
      <Modal
        componentId="EmrDownloadModal"
        contentLabel={translation('results_download_security_warning')}
        isOpen={emrModalOpen}
        onRequestClose={() => setEmrModalOpen(false)}
        showCross
        showClose={false}
        shrinkwrap
      >
        <p>
          {translation(
            'please_ensure_you_take_appropriate_steps_to_protect_the_privacy_of_this_document_in_accordance_with_the_clinical_governance_requirements',
          )}
        </p>
        <Button
          dataTestId="emrDownloadButton"
          onClick={handleClick}
          variant="primary"
        >
          {translation('continue')}
        </Button>
      </Modal>
    </>
  )
}

export default createFragmentContainer(StaffDownloadDataTaskComponent, {
  individual: graphql`
    fragment StaffDownloadDataTask_individual on IndividualRole {
      user {
        id
      }
    }
  `,
})
