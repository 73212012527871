// @flow

import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { graphql } from 'react-relay'
import numeral from 'numeral'

import HeroNumber from 'react-ui/components/HeroNumber'
import HeroTable from 'react-ui/components/HeroTable'
import ReportCard from 'react-ui/components/ReportCard'
import SegmentationTable from 'react-ui/components/SegmentationTable'

import ReportsView from './ReportsView'
import ReportWithSettings from './ReportWithSettings'

type PropsType = {
  print?: boolean,
  title: React.Node,
  variables: Object,
}

const query = graphql`
  query WorkforceReportQuery(
    $from: DateTime!
    $to: DateTime!
    $tenants: [ID!]!
    $clinicians: [ID!]!
    $report_view: ReportView
  ) {
    viewer {
      reports {
        workforce_clinician_system_use(
          from: $from
          to: $to
          tenants: $tenants
          clinicians: $clinicians
          report_view: $report_view
        ) {
          traces {
            name
            type
            x
            y
          }
        }
        workforce_staffing_discipline(
          from: $from
          to: $to
          tenants: $tenants
          clinicians: $clinicians
          report_view: $report_view
        ) {
          traces {
            name
            type
            x
            y
          }
        }
      }
    }
  }
`

const WorkforceReport = ({ print, title, variables }: PropsType) => {
  // Localization
  const { t: translation } = useTranslation()

  return (
    <ReportsView print={print} title={title}>
      <ReportWithSettings query={query} variables={variables}>
        {(data) => {
          const {
            workforce_clinician_system_use,
            workforce_staffing_discipline,
          } = data.viewer.reports

          return (
            <React.Fragment>
              <ReportCard title={translation('clinician_breakdown')}>
                <div className="WorkforceStaffingDisciplineChart">
                  <SegmentationTable
                    traces={workforce_staffing_discipline.traces}
                    columnConfig={{
                      label: {
                        format: ({ name }: { name: string }) => name,
                        width: '270px',
                      },
                      value0: {
                        format: (value) => numeral(value).format('0,0'),
                        width: '135px',
                      },
                      value1: {
                        format: (value) => (
                          <HeroNumber format="0,0.0" value={value} />
                        ),
                        width: '135px',
                      },
                    }}
                  />
                </div>
              </ReportCard>

              <div className="WorkforceClinicianSystemUseChart">
                <ReportCard
                  title={translation('clinicians_use_of_the_platform')}
                >
                  <ReportCard.SubHeading>
                    {translation('dashboard_views')}
                  </ReportCard.SubHeading>
                  <HeroTable
                    columnFormat={{ '0': '0,0.00' }}
                    traces={workforce_clinician_system_use.traces}
                  />
                </ReportCard>
              </div>
            </React.Fragment>
          )
        }}
      </ReportWithSettings>
    </ReportsView>
  )
}

export default WorkforceReport
