// @flow

import React from 'react'

import { TextCell } from 'react-ui/components/DataGrid'
import {
  sortByBoolean,
  sortByDate,
  sortByNumber,
} from 'shared/services/sorters'

import UserTrackable from './components/UserTrackable'

type FormulaRange = {
  label: string,
  value: number,
}

type Formula = {
  escalated: boolean,
  formula_label?: string,
  formula_range: ?FormulaRange,
  last_updated_at: string,
  time_since_updated: any,
  trending: {
    label: string,
  },
}

type FormulaSortDetails = {
  escalated: boolean,
  last_updated_at: Date,
  value: number,
}

const staff = true

function formulaSortDetails(formula: Formula): FormulaSortDetails {
  const defaultDetails = {
    value: -1,
    escalated: false,
    last_updated_at: new Date(0),
  }
  if (!formula) return defaultDetails

  const { formula_range, escalated, last_updated_at: date } = formula

  const last_updated_at = new Date(date)
  if (!formula_range) return { ...defaultDetails, escalated, last_updated_at }

  const { value } = formula_range
  return { value, escalated, last_updated_at }
}

export default {
  format: ({
    formula_range,
    trending,
    time_since_updated,
    formula_label,
  }: Formula) => (
    <TextCell>
      {formula_range && (
        <UserTrackable
          staff={staff}
          formulaRange={formula_range}
          trending={trending}
          timeSinceUpdated={time_since_updated}
          formula_label={formula_label}
        />
      )}
    </TextCell>
  ),
  sort: (formulaA: Formula, formulaB: Formula) => {
    const a = formulaSortDetails(formulaA)
    const b = formulaSortDetails(formulaB)

    const sortEscalated = sortByBoolean(a.escalated, b.escalated)
    if (sortEscalated !== 0) return sortEscalated

    const sortValue = sortByNumber(a.value, b.value)
    if (sortValue !== 0) return sortValue

    return sortByDate(a.last_updated_at, b.last_updated_at)
  },
  width: '160px',
}
