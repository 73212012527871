// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'

import IndividualTasksItem from './IndividualTasksItem'

export default () => {
  const { t: translation } = useTranslation()
  const handleClick = () => {
    const newWindow = window.open(
      '/individual/emr',
      '_blank',
      'noopener,noreferrer',
    )
    if (newWindow) newWindow.opener = null
  }

  return (
    <IndividualTasksItem
      header={translation('download_health_history_pdf')}
      onClick={handleClick}
    >
      {translation('its_your_history_and_you_can_take_it_with_you')}
    </IndividualTasksItem>
  )
}
