// @flow

import React from 'react'
import { connect } from 'react-fela'
import { useTranslation } from 'react-i18next'
import { useRouter } from 'found'

import Logo from 'react-ui/assets/images/innowell-logo.svg'
import HelpNowButton from 'platform_web/features/Header/HelpNowButton/HelpNowButton'
import { Button } from 'care-ui'

import { type FelaStylesType } from 'react-ui/typing'

type PropsType = {
  backButton?: boolean,
  hideHelp?: boolean,
  hideLogo?: boolean,
  styles: FelaStylesType,
  white?: boolean,
}
const styleRules = ({ background, theme }) => ({
  header: {
    className: 'public__Header',
    background: background || theme.palette.themelet.white.backgroundColor,
    zIndex: '1',
    position: 'fixed',
    top: 0,
    right: 0,
    left: 0,
    height: '5rem',
  },
  headerContainer: {
    className: 'public__Header_Container',
    display: 'flex',
    position: 'relative',
    maxWidth: '1200px',
    margin: 'auto',
    padding: '0 16px',
    [theme.breakpoints.queries.smOnly]: {
      margin: '0 10px',
    },
  },
  callToAction: {
    marginLeft: 'auto',
    marginTop: '1.5555556rem',
    lineHeight: '3.1111111rem',
  },
  back: {
    flexGrow: 1,
    alignItems: 'flex-start',
    marginTop: '1.5555556rem',
  },
  logo: {
    className: 'PageHeader__logo',
    height: '30px',
  },

  brand: {
    flexGrow: '1',
    alignItems: 'center',
    display: 'flex',
  },
})

const logo = (styles) => (
  <div className={styles.brand}>
    <Logo className={styles.logo} />
  </div>
)

const back = (router, styles, translation) => (
  <div className={styles.back}>
    <Button onClick={() => router && router.go(-1)} ghost shape="pill" invert>
      {translation('back')}
    </Button>
  </div>
)

const helpButton = (styles) => (
  <div className={styles.callToAction}>
    <HelpNowButton />
  </div>
)

const PublicHeader = ({
  hideHelp,
  hideLogo,
  backButton,
  styles,
}: PropsType) => {
  const { router } = useRouter()
  const { t: translation } = useTranslation()

  return (
    <header className={styles.header}>
      <div className={styles.headerContainer}>
        {!hideLogo && logo(styles)}
        {backButton && back(router, styles, translation)}
        {!hideHelp && helpButton(styles)}
      </div>
    </header>
  )
}

export default connect(styleRules)(PublicHeader)
