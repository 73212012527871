// @flow

import React, { Suspense, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  type PreloadedQuery,
  usePreloadedQuery,
  useQueryLoader,
} from 'react-relay'
import { get } from 'lodash'

import { Graph, GraphContainer } from 'care-ui'
import { navy } from 'care-ui/atoms/colors/colorTokens'

import useGraphPagination from '../../hooks/useGraphPagination'
import { type OverviewQueryVariableType } from '../../hooks/useOverviewInsights'
import { query as summaryQuestionnairesQuery } from '../../queries/SummaryQuestionnairesInsightsQuery'

import { type SummaryQuestionnairesInsightsQuery } from '../../queries/__generated__/SummaryQuestionnairesInsightsQuery.graphql'

type ComponentProps = {
  loadSummaryQuestionnairesQuery: (
    overviewQueryVariables: OverviewQueryVariableType,
  ) => void,
  overviewQueryVariables: OverviewQueryVariableType,
  queryReference: PreloadedQuery<SummaryQuestionnairesInsightsQuery>,
}

const Component = (props: ComponentProps) => {
  const {
    overviewQueryVariables,
    queryReference,
    loadSummaryQuestionnairesQuery,
  } = props
  const queryResult = usePreloadedQuery(
    summaryQuestionnairesQuery,
    queryReference,
  )

  const seriesCategories = get(
    queryResult,
    'viewer.insights.summary_questionnaires.series_categories',
  )
  const series = get(
    queryResult,
    'viewer.insights.summary_questionnaires.series',
  )

  // Pagination
  const { onPaginate, isRightPaginationDisabled } = useGraphPagination({
    overviewQueryVariables,
    loadQuery: loadSummaryQuestionnairesQuery,
  })

  return (
    <Graph
      colors={[navy[400], navy[500]]}
      graphType="line"
      series={series}
      seriesCategories={seriesCategories}
      onPaginateLeft={() => onPaginate('left')}
      onPaginateRight={() => onPaginate('right')}
      isRightPaginationDisabled={isRightPaginationDisabled}
    />
  )
}

type SummaryQuestionnairesInsightsGraphProps = {
  overviewQueryVariables: OverviewQueryVariableType,
}

// Query Loader
const SummaryQuestionnairesInsightsGraph = (
  props: SummaryQuestionnairesInsightsGraphProps,
) => {
  const { overviewQueryVariables } = props

  const [
    summaryQuestionnairesQueryReference,
    loadSummaryQuestionnairesQuery,
  ] = useQueryLoader(summaryQuestionnairesQuery)

  // Load query when query variables change
  useEffect(() => {
    loadSummaryQuestionnairesQuery(overviewQueryVariables)
  }, [overviewQueryVariables])

  // transalations
  const { t: translation } = useTranslation()

  return (
    <GraphContainer
      heading={translation('summary_questionnaire')}
      graphInfoText={translation(
        'this_graph_visualises_the_number_of_summary_questionnaires_that_have_been_completed_over_the_specified_period_the_top_line_will_show_the_total_number_of_questionnaires_completed_and_the_bottom_line_will_show_the_total_number_of_unique_individuals_that_completed_those_questionnaires',
      )}
    >
      <Suspense>
        {summaryQuestionnairesQueryReference != null && (
          <Component
            overviewQueryVariables={overviewQueryVariables}
            queryReference={summaryQuestionnairesQueryReference}
            loadSummaryQuestionnairesQuery={loadSummaryQuestionnairesQuery}
          />
        )}
      </Suspense>
    </GraphContainer>
  )
}

export default SummaryQuestionnairesInsightsGraph
