// @flow

import { useTranslation } from 'react-i18next'
import { eachQuarterOfInterval, endOfQuarter, format } from 'date-fns'

type Quarter = {
  label: string,
  quarterEndDate: string,
  quarterStartDate: string,
}

export type QuarterGroup = {
  label: string,
  quarters: Array<Quarter>,
  value: string,
}

export type Lifetime = {
  label: string,
  quarterEndDate: string,
  quarterStartDate: string,
  secondaryLabel: string,
  value: string,
}

type ResultType = {
  lifetime: Lifetime,
  quarterGroup: Array<QuarterGroup>,
}

const useQuarterGenerator = (
  startDate: string,
  reverse: boolean = false,
): ResultType => {
  const today = new Date()
  const { t: translation } = useTranslation()
  // Map all quarters
  const allQuarters: Array<Quarter> = eachQuarterOfInterval({
    start: new Date(startDate),
    end: today,
  }).map((quarterStartDate) => {
    const formattedStartDate = format(quarterStartDate, 'd MMM yy')
    const formattedEndDate = format(endOfQuarter(quarterStartDate), 'd MMM yy')
    const quarterEndDate = endOfQuarter(quarterStartDate)

    return {
      label: `${formattedStartDate} - ${formattedEndDate}`,
      quarterStartDate: quarterStartDate.toISOString(),
      quarterEndDate: quarterEndDate.toISOString(),
    }
  })

  // Filter quarters to exclude incomplete ones in the current year
  const filteredQuarters = allQuarters.filter((quarter) => {
    const currentYear = format(today, 'yyyy')
    const quarterYear = format(new Date(quarter.quarterStartDate), 'yyyy')
    return (
      quarterYear !== currentYear || new Date(quarter.quarterEndDate) <= today
    )
  })

  // Group quarters by year
  let quarterGroup: Array<QuarterGroup> = filteredQuarters.reduce(
    (acc, quarter) => {
      const year = format(new Date(quarter.quarterStartDate), 'yyyy')
      const existingYear = acc.find((item) => item.label === year)

      if (existingYear) {
        existingYear.quarters.push(quarter)
      } else {
        acc.push({
          label: year,
          value: year,
          quarters: [quarter],
        })
      }

      return acc
    },
    [],
  )

  //  Reverse if needed
  if (reverse) {
    quarterGroup = quarterGroup.reverse()
  }

  // Create the lifetime object
  const lifetime: Lifetime = {
    label: translation('lifetime'),
    secondaryLabel: translation(
      'displays_results_from_the_beginning_of_your_innowell_journey_until_the_latest_completed_calendar_quarter',
    ),
    value: 'Lifetime',
    quarterStartDate: new Date(startDate).toISOString(),
    quarterEndDate:
      filteredQuarters[filteredQuarters.length - 1]?.quarterEndDate,
  }

  return { quarterGroup, lifetime }
}

export default useQuarterGenerator
