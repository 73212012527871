// @flow

import React, { useEffect, useState } from 'react'
import type { TFunction } from 'react-i18next'
import { useTranslation } from 'react-i18next'
import {
  createFragmentContainer,
  graphql,
  useRelayEnvironment,
} from 'react-relay'
import { useRouter } from 'found'

import MultiStepProgressBar from 'components/MultiStepProgressBar'
import { IndividualQuestionnaireCompleteRenderer } from 'components/QuestionnaireComplete'
import { useOnboardingStepContext } from 'react-ui/contexts/OnboardingStepContext'
import { commit as commitUserQuestionnaireSubmit } from 'mutations/UserQuestionnaireSubmit'
import { useCurrentUserRoles } from 'platform_web/hooks/useCurrentUserRoles'

import { Clinician } from './QuestionnaireComplete/Clinician'
import { Individual } from './QuestionnaireComplete/Individual'
import { QuestionnaireCompleteRenderer } from './QuestionnaireCompleteRenderer'

import { type QuestionnaireComplete_user } from './__generated__/QuestionnaireComplete_user.graphql'
import { type QuestionnaireComplete_userQuestionnaire } from './__generated__/QuestionnaireComplete_userQuestionnaire.graphql'
import { type SupportingSupporteeSelectionPage_supportees } from 'pages/Supporting/queries/__generated__/SupportingSupporteeSelectionPage_supportees.graphql'

type PropsType = {|
  environment: Object,
  roleType: ?string,
  router: { push: Function },
  setSubmitting: (boolean) => void,
  submitting: boolean,
  supportees?: SupportingSupporteeSelectionPage_supportees,
  translation: TFunction,
  user: QuestionnaireComplete_user,
  userQuestionnaire: QuestionnaireComplete_userQuestionnaire,
|}

type BaseType = {|
  roleType: ?string,
  supportees?: SupportingSupporteeSelectionPage_supportees,
  user: QuestionnaireComplete_user,
  userQuestionnaire: QuestionnaireComplete_userQuestionnaire,
|}

export type BuilderPropsType = {
  ...PropsType,
  onSubmit: Function,
  translation: TFunction,
}

export function submitBuilder(props: PropsType) {
  return function onSubmit({ onCompleted }: { onCompleted?: Function }) {
    return function submitHandler() {
      props.setSubmitting(true)
      commitUserQuestionnaireSubmit({
        environment: props.environment,
        variables: {
          input: {
            questionnaireId: props.userQuestionnaire.ensure_questionnaire.id,
            answereeId: props.userQuestionnaire.user.id,
          },
        },
        onCompleted: () => {
          if (onCompleted) onCompleted()
        },
        onError: () => {
          props.setSubmitting(false)
        },
      })
    }
  }
}

export function infoBuilder(props: PropsType) {
  switch (props.roleType) {
    case 'INDIVIDUAL': {
      return Individual({ onSubmit: submitBuilder(props), ...props })
    }
    case 'CLINICIAN': {
      return Clinician({ onSubmit: submitBuilder(props), ...props })
    }
    default: {
      return {
        title: props.translation(
          'sorry_your_role_is_not_supported_for_questionnaires',
        ),
      }
    }
  }
}

export function QuestionnaireCompleteBase(props: BaseType) {
  const [submitting, setSubmitting] = useState(false)
  const { currentOnboardingSteps } = useOnboardingStepContext()
  const { router } = useRouter()
  const environment = useRelayEnvironment()
  const { hasClinicianRole } = useCurrentUserRoles()
  const { t: translation } = useTranslation()
  const { roleType: roleTypeFromProps, user } = props
  let roleType = roleTypeFromProps

  if (roleTypeFromProps !== 'INDIVIDUAL') {
    roleType = hasClinicianRole ? 'CLINICIAN' : roleTypeFromProps
  }

  const { linked_individual, id } = user
  const { initial_questionnaire_status } = linked_individual || {}
  const hasCompletedInitialQuestionnaire =
    initial_questionnaire_status === 'Completed'

  const allProps = { ...props, submitting, setSubmitting, router, environment }

  useEffect(() => {
    if (roleType === 'SUPPORT_PERSON') {
      router.replace({
        name: 'supportPersonQuestionnaireSubmission',
        params: {
          id,
        },
      })
    }
  }, [roleType, router, id])

  if (roleType === 'INDIVIDUAL') {
    return (
      <>
        {!hasCompletedInitialQuestionnaire && (
          <MultiStepProgressBar steps={currentOnboardingSteps} />
        )}
        <IndividualQuestionnaireCompleteRenderer
          info={infoBuilder({ ...allProps, translation })}
        />
      </>
    )
  }

  return (
    <QuestionnaireCompleteRenderer
      info={infoBuilder({ ...allProps, roleType, translation })}
    />
  )
}

graphql`
  fragment QuestionnaireComplete_user_questionnaires_question_sets on UserQuestionnairesQuestionSet
    @relay(mask: false) {
    latest_submitted_answer_set {
      skip_reason_value
    }
    question_set {
      label
    }
    questionnaires_question_set {
      id
    }
  }
`

const QuestionnaireComplete = createFragmentContainer(
  QuestionnaireCompleteBase,
  {
    userQuestionnaire: graphql`
      fragment QuestionnaireComplete_userQuestionnaire on UserQuestionnaire {
        completeness {
          percent
        }
        completedQuestionSets: user_questionnaires_question_sets(
          scope: completed
        ) {
          ...QuestionnaireComplete_user_questionnaires_question_sets
            @relay(mask: false)
        }
        skippedQuestionSets: user_questionnaires_question_sets(scope: skipped) {
          ...QuestionnaireComplete_user_questionnaires_question_sets
            @relay(mask: false)
        }
        user {
          id
        }
        questionnaire {
          id
        }
        ensure_questionnaire {
          id
        }
      }
    `,
    user: graphql`
      fragment QuestionnaireComplete_user on User {
        id
        name
        legal_name
        preferred_name
        eligible_for_dashboard_setup
        contract
        linked_individual {
          initial_questionnaire_status
        }
      }
    `,
    supportees: graphql`
      fragment QuestionnaireComplete_supportees on IndividualRole
        @relay(plural: true) {
        id
        tenant {
          id
          name
          support_person_help
        }
        user {
          id
          email
          name
        }
        supporting_user_questionnaire {
          id
          questionnaire {
            id
          }
          ensure_questionnaire {
            id
          }
          user {
            id
          }
        }
      }
    `,
  },
)

export default QuestionnaireComplete
