// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'

import { FlexContainer } from 'care-ui'
import TextNew from 'care-ui/molecules/TextNew/TextNew'

const RecoveryTrajectoriesEmptyState = () => {
  const { t: translation } = useTranslation()

  return (
    <FlexContainer
      justifyContent="center"
      height="200px"
      alignItems="center"
      direction="column"
      marginTop="md"
    >
      <TextNew as="p" typography="bodyMdBold">
        {translation('no_data_available_for_this_period')}
      </TextNew>

      <TextNew as="div" typography="bodyMdBold">
        {translation(
          'recovery_trajectory_can_only_be_calculated_with_at_least_two_results_no_domains_with_a_latest_result_found_within_this_period',
        )}
      </TextNew>
    </FlexContainer>
  )
}

export default RecoveryTrajectoriesEmptyState
