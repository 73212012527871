// @flow

import React from 'react'
import { useFela } from 'react-fela'
import { useTranslation } from 'react-i18next'
import { useFragment } from 'react-relay'
import { useRouter } from 'found'
import { uniqueId } from 'lodash'

import { Button, Heading, Text } from 'care-ui'

import HealthCard from '../../HealthCard'

import {
  cardContainerStyle,
  containerStyle,
  headerStyle,
  subHeaderStyle,
  subtitleStyle,
} from './HealthPriorityList.style'
import { healthCardListFragment } from './query/HealthPriorityList'

import type { HealthPriorityList_user_trackables } from './query/__generated__/HealthPriorityList_user_trackables.graphql'

type PropsType = {
  +user_trackables: HealthPriorityList_user_trackables,
  viewOnly?: boolean,
}

const HealthPriorityList = (props: PropsType) => {
  const { user_trackables: fragmentRef, viewOnly } = props

  const { t: translation } = useTranslation()

  const user_trackables = useFragment(healthCardListFragment, fragmentRef)
  const empty = user_trackables?.length === 0

  const { css } = useFela({ empty })
  const { router } = useRouter()

  const handleClick = () => {
    router.push('dashboard_preferences')
  }

  const healthCards = user_trackables?.map((user_trackable) => {
    return (
      <HealthCard
        key={uniqueId('health-card-')}
        user_trackable={user_trackable}
      />
    )
  })

  const withTrackables = (
    <>
      <div className={css(headerStyle)}>
        <div className={css(subHeaderStyle)}>
          <Heading level={2}>{translation('health_priorities')}</Heading>

          {!viewOnly && (
            <Button
              variant="text"
              onClick={handleClick}
              ariaLabel={translation('update_health_priorities')}
              dataTestId="update-health-priorities"
            >
              {translation('update_health_priorities')}
            </Button>
          )}
        </div>

        <Text className={css(subtitleStyle)}>
          {translation(
            'these_are_the_focus_areas_to_improve_your_overall_wellbeing',
          )}
        </Text>
      </div>

      <div className={css(cardContainerStyle)}>{healthCards}</div>
    </>
  )

  const withoutTrackables = (
    <>
      <Text className={css(subtitleStyle)}>
        {translation('you_currently_have_no_health_priorities_selected')}
      </Text>

      {!viewOnly && (
        <Button
          size="sm"
          shape="rounded"
          variant="primary"
          bordered={false}
          onClick={handleClick}
        >
          {translation('select_health_priorities')}
        </Button>
      )}
    </>
  )

  return (
    <div className={css(containerStyle)}>
      {empty ? withoutTrackables : withTrackables}
    </div>
  )
}

export default HealthPriorityList
