// @flow

import React, { type Node } from 'react'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'
import { map } from 'lodash/fp'

import { Layout as l } from 'shared/ui/Styles'

import s from './Table.scss'

type PropsType = {
  children: (model: Object, idx: number) => Node,
  className?: string,
  color?: 'primary' | 'secondary',
  data: ?$ReadOnlyArray<?Object>,
  renderHeaders: (?$ReadOnlyArray<?Object>) => ?Node,
}

const Table = ({
  children,
  className,
  color = 'primary',
  data,
  renderHeaders,
}: PropsType) => {
  const renderedBody = map.convert({ cap: false })(children)(data)
  const { t: translation } = useTranslation()

  const noResultsBody = (
    <tfoot className={s.tfoot}>
      <tr>
        <td colSpan="100%">{translation('no_results_found')}.</td>
      </tr>
    </tfoot>
  )

  return (
    <div className={cx(l.container, s.tableScroll)}>
      <table
        className={cx(
          s.table,
          {
            [s[color]]: color,
          },
          className,
        )}
      >
        <thead className={s.thead}>{renderHeaders(data)}</thead>
        <tbody className={s.tbody}>{renderedBody}</tbody>
        {renderedBody.length === 0 && noResultsBody}
      </table>
    </div>
  )
}
export default Table
