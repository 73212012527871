// @flow

import type { TFunction } from 'react-i18next'
import * as yup from 'yup'

import { ago, longDateFormat } from 'services/dateTime'
import type { QuestionType } from 'shared/utils/SimpleQuestionnaireTypes'

import { stateAddressesRegex } from './address'

const getAllValidationSchemas = (label, answerValidationRegex, translation) => {
  const requiredMsg = label
    ? `${label} ${translation('is_a_required_field')}`
    : null

  const postcodeValidationRegex = new RegExp(answerValidationRegex || '')

  return {
    // common validations
    thisStringIsRequired: yup.string().trim().required(requiredMsg),

    thisStringIsEnsured: yup.string().trim().ensure(),

    thisArrayIsRequired: yup.array().min(1).ensure().compact(),

    thisArrayIsEnsured: yup.array().ensure().compact(),

    // field validations
    date_of_birth: yup
      .date()
      .label('Date of birth')
      .required(requiredMsg)
      .min(
        ago(150, 'year'),
        ({ min }) =>
          `${translation('date_of_birth_must_be_after')} ${longDateFormat(
            min,
          )}`,
      )
      .max(
        new Date(),
        ({ max }) =>
          `${translation('date_of_birth_must_be_before')} ${longDateFormat(
            max,
          )}`,
      ),

    suburb: yup
      .string()
      .trim()
      .required(requiredMsg)
      .matches(
        new RegExp(`^\\D{1,}, (${stateAddressesRegex()})$`),
        translation('location_must_follow_the_pattern_eg_sydney_nsw'),
      ),

    // NOTE: canada postcode
    postcode: yup
      .string()
      .trim()
      .required(requiredMsg)
      .matches(
        postcodeValidationRegex,
        translation('postcode_must_follow_the_pattern_eg_a1b_2c3'),
      ),

    no_of_children: yup
      .number()
      .typeError(translation('value_must_be_a_number'))
      .required(requiredMsg)
      .min(1, translation('number_must_be_greater_than_0')),

    years_practicing_discipline: yup
      .number()
      .integer(translation('only_enter_whole_number_of_years'))
      .required(translation('please_enter_a_valid_value'))
      .min(0, translation('number_must_be_positive'))
      .max(100, translation('number_must_be_less_than_100')),

    years_in_current_role: yup
      .number()
      .integer(translation('only_enter_whole_number_of_years'))
      .required(translation('please_enter_a_valid_value'))
      .min(0, translation('number_must_be_positive'))
      .max(100, translation('number_must_be_less_than_100')),
  }
}

export const validationSchemaForQuestion = (
  {
    id,
    name,
    validationName,
    required,
    label,
    dependOn,
    answerValidationRegex,
  }: QuestionType,
  isMultiple: boolean,
  translation: TFunction,
) => {
  const validationSchema = ((): yup => {
    const allValidationSchemas = getAllValidationSchemas(
      label || name,
      answerValidationRegex,
      translation,
    )
    const forField = allValidationSchemas[id || name]
    if (forField) return forField

    if (validationName === 'conditional' && dependOn) {
      const { thisStringIsEnsured, thisStringIsRequired } = allValidationSchemas
      const fieldName = Object.keys(dependOn)[0]
      const fieldValue = Object.values(dependOn)[0]

      return yup.string().when(fieldName, {
        is: (value) => value && value === fieldValue,
        then: thisStringIsRequired,
        otherwise: thisStringIsEnsured,
      })
    }

    if (isMultiple) {
      const { thisArrayIsEnsured, thisArrayIsRequired } = allValidationSchemas
      if (!required) return thisArrayIsEnsured
      return thisArrayIsRequired
    }
    const { thisStringIsEnsured, thisStringIsRequired } = allValidationSchemas
    if (!required) return thisStringIsEnsured
    return thisStringIsRequired
  })()
  return validationSchema
}
