// @flow

import React, { type Node } from 'react'
import { useTranslation } from 'react-i18next'
import { createFragmentContainer } from 'react-relay'

import { StatefullTabs } from 'react-ui/components/Tabs'
import Can from 'react-ui/utils/Can'

import GraphWithFilters from '../GraphWithFilters'

import { query } from './queries/UserTrackableChartControl'

import { type UserTrackableChartControls_formulaRanges } from './queries/__generated__/UserTrackableChartControls_formulaRanges.graphql'
import { type UserTrackableChartControls_user } from './queries/__generated__/UserTrackableChartControls_user.graphql'

type commonProps = {
  broadcastPointData?: () => any,
  children?: Node,
  formulaRanges: ?UserTrackableChartControls_formulaRanges,
}

type PropsType = commonProps & {
  user: UserTrackableChartControls_user,
}

export function buildGraphOptions(props: commonProps) {
  return (tabId?: string) => {
    return (
      <GraphWithFilters
        broadcastPointData={props.broadcastPointData}
        displayActivity={tabId === '#activity'}
        displaySleep={tabId === '#sleep'}
        {...(props: any)}
      />
    )
  }
}

export const UserTrackableChartControlsBase = ({
  broadcastPointData,
  children,
  formulaRanges,
  user: {
    fitbit: { connected: hasFitbit },
  },
}: PropsType) => {
  const { t: translation } = useTranslation()

  const dataTabs = [
    {
      label: translation('my_result'),
      id: '#mood',
    },
    {
      label: translation('activity_result'),
      id: '#activity',
    },
    {
      label: translation('sleep_result'),
      id: '#sleep',
    },
  ]

  const DEFAULT_TAB = '#mood'

  const initialTab = DEFAULT_TAB

  const renderChart = buildGraphOptions({
    broadcastPointData,
    formulaRanges,
    children,
  })

  return (
    <Can>
      {({ CAN_USE_FITBIT }) =>
        CAN_USE_FITBIT && hasFitbit ? (
          <StatefullTabs
            possibleTabs={dataTabs}
            initialTabId={initialTab}
            withAnchorTabs
          >
            {({ tabs, currentTabId }) => (
              <>
                {tabs}
                {renderChart(currentTabId)}
              </>
            )}
          </StatefullTabs>
        ) : (
          <>
            <div />
            {renderChart()}
          </>
        )
      }
    </Can>
  )
}

const withRelay = (component) => createFragmentContainer(component, query)

export const UserTrackableChartControls = withRelay(
  UserTrackableChartControlsBase,
)
