// @flow

import React, { Fragment } from 'react'
import type { TFunction } from 'react-i18next'

import Link from 'react-ui/components/Link'

const TechnologyQuestions = (translation: TFunction) => [
  {
    key: 'techIssues',
    question: (
      <Fragment>{translation('what_do_you_do_with_technical_issues')}</Fragment>
    ),
    answer: (
      <Fragment>
        {translation(
          'always_save_and_submit_your_data_as_you_progress_through_innowell',
        )}
        <br />
        <br />
        {translation(
          'if_you_are_experiencing_technical_issues_please_reach_out_to_your_health_provider_i_e_the_organisation_who_invited_you_to_innowell',
        )}
        <br />
        <br />
        {translation('you_can_find')}{' '}
        <Link to="connected_organisations">
          {translation('your_clinicians_and_healthcare_providers')}{' '}
        </Link>
        {translation('information_under_the_profile_settings_menu')}
        <br />
        <br />
        {translation('do_you_need_help_now_there_are')}{' '}
        <Link to="help">{translation('services_who_can_help')}</Link>
      </Fragment>
    ),
  },
  {
    key: 'invitationNotReveived',
    question: (
      <Fragment>
        {' '}
        {translation('why_is_an_invitation_email_not_received')}{' '}
      </Fragment>
    ),
    answer: (
      <Fragment>
        {translation('when_an_email_invitation_is_not_received')}
        <ul>
          <li>
            {translation(
              'check_and_confirm_the_email_invite_was_sent_to_the_correct_email_address',
            )}
          </li>
          <li>
            {translation(
              'check_and_confirm_the_date_and_time_the_email_was_sent_it_is_possible_that_the_email_went_to_junk_spam_trash_folders_in_a_person_s_email_system',
            )}
          </li>
          <li>
            {translation(
              'resend_an_email_if_the_email_invite_was_sent_more_than_seven_7_days_ago_it_will_have_expired_and_a_new_email_invite_needs_to_be_resent',
            )}
          </li>
        </ul>
      </Fragment>
    ),
  },
]

export default TechnologyQuestions
