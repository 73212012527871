// @flow

import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { createFragmentContainer, graphql } from 'react-relay'
import { subDays } from 'date-fns'
import { flow, invoke } from 'lodash/fp'
import { commitLocalUpdate } from 'relay-runtime'

import {
  AccessibilityEquityReport,
  ContinuityCoordinationReport,
  EffectivenessOutcomesReport,
  EfficiencyExpenditureCostReport,
  SafetyReport,
  SatisfactionAcceptabilityReport,
  WorkforceReport,
} from './reports'
import { ReportSettingsTenantFetchLoader } from './ReportSettingsTenantFetch'
import ServiceMetricsPageWrapper from './ServiceMetricsPageWrapper'

import type { ServiceMetricsHomePage_viewer } from './__generated__/ServiceMetricsHomePage_viewer.graphql'

type PropsType = {
  match: Object,
  relay: Object,
  viewer: ServiceMetricsHomePage_viewer,
}

const startDate = flow(
  (date: Date): Date => subDays(date, 3),
  invoke('toISOString'),
)

const endDate = invoke('toISOString')

const ServiceMetricsHomePage = ({ match, relay, viewer }: PropsType) => {
  const {
    reports: { available_tenants },
    currentUser,
  } = viewer

  const reportView = match.params.report_view

  useEffect(() => {
    const { environment } = relay

    commitLocalUpdate(environment, (store) => {
      const reportSettings =
        store.get('reportSettings') ||
        store.create('reportSettings', 'ReportSettings')
      reportSettings.setValue(startDate(new Date()), 'from')
      reportSettings.setValue(endDate(new Date()), 'to')
      reportSettings.setValue(
        available_tenants.map(({ id }) => id),
        'tenants',
      )
      reportSettings.setValue([], 'clinicians')
      reportSettings.setValue('last_three_days', 'selectedDateType')

      const viewerLinkedRecord = store.getRoot().getLinkedRecord('viewer')
      const reportsLinkedRecord =
        viewerLinkedRecord && viewerLinkedRecord.getLinkedRecord('reports')

      if (reportsLinkedRecord)
        reportsLinkedRecord.setLinkedRecord(reportSettings, 'settings')
    })

    if (environment.options) {
      environment.retain({
        dataID: 'reportSettings',
        node: { selections: [] },
        variables: {},
      })
    }
  }, [])

  // Localization
  const { t: translation } = useTranslation()

  if (!viewer.reports.settings) {
    return `${translation('working')}...`
  }

  const settings = { ...viewer.reports.settings, report_view: reportView }

  let userRoles = []

  if (
    currentUser &&
    currentUser.tenant &&
    currentUser.tenant.multi_roles_enabled &&
    currentUser.roles &&
    currentUser.roles.nodes
  ) {
    userRoles = currentUser.roles.nodes
      .filter(
        (v) => v && currentUser.tenant && v.tenant_id === currentUser.tenant.id,
      )
      .map((v) => v && v.role_type)
      .filter((v, i, a) => a.indexOf(v) === i)
  }

  return (
    <ServiceMetricsPageWrapper
      userRoles={userRoles}
      reportView={reportView}
      actions={
        <ReportSettingsTenantFetchLoader
          settings={settings}
          reportView={reportView}
          tenants={available_tenants}
        />
      }
    >
      <SafetyReport
        title={translation('safety')}
        variables={settings}
        navHash="#safety"
      />

      <AccessibilityEquityReport
        dimensions={viewer.reports.accessibility_breakdown_dimensions}
        title={translation('accessibility_and_equity')}
        variables={settings}
        navHash="#accessibility_equity"
      />

      <SatisfactionAcceptabilityReport
        dimensions={viewer.reports.satisfaction_individual_sign_in_dimensions}
        title={translation('satisfaction_and_acceptability')}
        variables={settings}
        navHash="#satisfaction_acceptability"
      />

      <WorkforceReport
        title={translation('workforce')}
        variables={settings}
        navHash="#workforce"
      />

      <EfficiencyExpenditureCostReport
        title={translation('efficiency_expenditure_and_cost')}
        variables={settings}
        navHash="#efficiency_expenditure_cost"
      />

      <EffectivenessOutcomesReport
        title={translation('effectiveness_and_outcomes')}
        variables={settings}
        navHash="#effectiveness_outcomes"
      />

      <ContinuityCoordinationReport
        title={translation('continuity_and_coordination')}
        variables={settings}
        navHash="#continuity_coordination"
      />
    </ServiceMetricsPageWrapper>
  )
}

export const ServiceMetricsHomePageQuery = graphql`
  query ServiceMetricsHomePageQuery {
    viewer {
      ...ServiceMetricsHomePage_viewer
    }
  }
`

export const ServiceMetricsHomePageLoader = createFragmentContainer(
  ServiceMetricsHomePage,
  {
    viewer: graphql`
      fragment ServiceMetricsHomePage_viewer on Viewer {
        reports {
          id
          available_tenants {
            id
            name
          }
          satisfaction_individual_sign_in_dimensions {
            key
            value
          }
          accessibility_breakdown_dimensions {
            key
            value
          }
          settings {
            ...ReportSettingsTenantFetch_settings
            from
            to
            tenants
            clinicians
            selectedDateType
          }
        }
        currentUser {
          tenant {
            id
            multi_roles_enabled
          }
          roles {
            totalCount
            nodes {
              ... on RoleInterface {
                tenant_id
                id
                role_type
              }
            }
          }
        }
      }
    `,
  },
)
