// @flow

import type { TFunction } from 'react-i18next'

const LGBTQIParagraphs = (translation: TFunction) => [
  {
    id: '1',
    text: translation(
      'innowell_acknowledges_the_lesbian_gay_bisexual_trans_queer_and_intersex_communities_who_have_worked_to_improve_and_develop_the_health_and_wellbeing_of_their_peers_children_families_friends_and_country_we_celebrate_and_embrace_the_diversity_of_people_and_community_and_honour_the_work_that_has_been_done_by_those_before_us_and_those_who_continue_to_positively_shape_the_future',
    ),
  },
]

export default LGBTQIParagraphs
