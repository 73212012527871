// @flow

import React, { useEffect, useState } from 'react'
import { useFela } from 'react-fela'
import { useTranslation } from 'react-i18next'

import { NotificationCarousel } from 'components/SystemNotificationPopUp/NotificationCarousel'
import Modal from 'react-ui/components/Modal/Modal'
import * as colors from 'react-ui/themes/synergy-base-theme/colors'
import { Button } from 'care-ui'

import { type PopUpNotificationShowPreview_notification_preview } from './__generated__/PopUpNotificationShowPreview_notification_preview.graphql'

type PropsType = {
  notification_preview: PopUpNotificationShowPreview_notification_preview,
}

const modelStyles = () => ({
  Modal: {
    borderRadius: 0,
  },
})

const modelContentStyles = {
  padding: 0,
}

const containerRule = {
  backgroundColor: colors.greyscale['45'],
}

const footerRule = {
  paddingBottom: '40px',
  textAlign: 'center',
}

const previewButton = {
  padding: '40px 0',
  textAlign: 'center',
}

export const PopUpNotificationPreview = ({
  notification_preview,
}: PropsType) => {
  const { t: translation } = useTranslation()
  const [isOpen, setOpen] = useState(
    Object.keys(notification_preview).length > 0,
  )
  const { css } = useFela()
  const onClose = () => setOpen(!isOpen)

  useEffect(() => {
    setOpen(Object.keys(notification_preview).length > 0)
  }, [notification_preview])

  return (
    <React.Fragment>
      <div className={css(previewButton)}>
        <Button onClick={onClose} variant="primary">
          {translation('preview_notification')}
        </Button>
      </div>
      <Modal
        isOpen={isOpen}
        contentStyle={modelContentStyles}
        onRequestClose={onClose}
        showClose={false}
        showCross={false}
        shrinkwrap
        extend={modelStyles}
      >
        <div className={css(containerRule)}>
          <NotificationCarousel notifications={[notification_preview]} />
          <div className={css(footerRule)}>
            <Button onClick={onClose} variant="primary">
              {translation('lets_go')}
            </Button>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  )
}
