// @flow

import React, { Fragment } from 'react'
import type { TFunction } from 'react-i18next'

const DataSecurityQuestions = (translation: TFunction) => [
  {
    key: 'isYourDataSecure',
    question: <Fragment> {translation('is_your_data_secure')} </Fragment>,
    answer: (
      <Fragment>
        {translation(
          'all_personal_and_health_information_data_is_stored_on_computer_systems_located_in_australia_the_data_is_secure_highly_restricted_and_protected_by_the_highest_standards_in_organisational_physical_and_logical_security_measures_data_communications_between_innowell_and_health_services_are_protected_by_strong_encryption',
        )}
      </Fragment>
    ),
  },
  {
    key: 'whatisDataSharing',
    question: <Fragment> {translation('what_is_data_sharing')} </Fragment>,
    answer: (
      <Fragment>
        {translation(
          'data_sharing_is_an_agreement_to_share_health_data_for_research_purposes_all_data_is_non_identified_health_data_stored_in_a_secure_password_protected_research_data_store_managed_by_the_university_of_sydney',
        )}
      </Fragment>
    ),
  },
  {
    key: 'doYouHaveToShareData',
    question: (
      <Fragment> {translation('do_you_have_to_share_your_data')} </Fragment>
    ),
    answer: (
      <Fragment>
        {translation(
          'individuals_can_use_innowell_and_choose_not_to_share_their_data_it_is_a_personal_choice_to_learn_more_please_refer_to_the_innowell_menu_on_your_welcome_back_page_and_go_to_profile_settings_your_data',
        )}
      </Fragment>
    ),
  },
  {
    key: 'stopSharingData',
    question: (
      <Fragment> {translation('can_you_stop_sharing_your_data')} </Fragment>
    ),
    answer: (
      <Fragment>
        {translation(
          'you_can_stop_sharing_your_data_for_research_purposes_at_anytime',
        )}
        <br />
        <br />
        {translation(
          'to_learn_more_or_make_any_required_changes_please_refer_to_the_innowell_menu_on_your_welcome_back_page_and_go_to_profile_settings_your_data_and_follow_the_prompts',
        )}
      </Fragment>
    ),
  },
  {
    key: 'discharged',
    question: (
      <Fragment>
        {translation(
          'what_happens_when_you_are_discharged_or_change_your_health_provider',
        )}
      </Fragment>
    ),
    answer: (
      <Fragment>
        {translation(
          'when_you_are_no_longer_working_with_a_health_professional_or_a_health_service_your_innowell_account_may_be_switched_to_read_only_in_read_only_mode_you_can_continue_to_access_care_options_and_review_your_previous_data_you_will_not_be_able_to_enter_new_information_or_update_your_health_cards',
        )}
      </Fragment>
    ),
  },
]

export default DataSecurityQuestions
