// @flow

import type { TFunction } from 'react-i18next'
import {
  compact as compactArray,
  difference,
  every,
  find,
  isNil,
  isString,
  map,
  reduce,
} from 'lodash/fp'
import * as yup from 'yup'

import { validationSchemaForQuestion } from 'shared/services/validations/ValidationSchemas'
import * as AllFormikFields from 'shared/ui/Formiks/AllFormikFields'
import { ensureArray } from 'shared/utils/ArrayUtils'

import { compact as compactObject } from './ObjectUtils'
import type {
  AnswerSetType,
  FormikQuestionType,
  OrdersType,
  QuestionsType,
} from './SimpleQuestionnaireTypes'

export const convertDependOnToShowIf = (
  dependOn: ?AnswerSetType,
): ((values: AnswerSetType) => boolean) => {
  const alwaysTrue = () => true
  if (!dependOn) return alwaysTrue

  const allRequiredValuesAreSelected = (values) => (key) =>
    difference(ensureArray(dependOn[key]), ensureArray(values[key])).length ===
    0

  return (values) => {
    return Object.keys(dependOn).every(allRequiredValuesAreSelected(values))
  }
}

export const convertQuestions = (
  questions: QuestionsType,
  translation: TFunction,
): Array<FormikQuestionType> =>
  map((question) => {
    const { fieldType, dependOn, value, ...rest } = question
    const isMultiple = fieldType === 'multiselect'
    const dependingQuestionsAreRequired = every(
      (questionKey) => find({ name: questionKey })(questions)?.required,
    )(Object.keys(dependOn || {}))
    return {
      ...rest,
      id: rest.name,
      dependOn,
      showIf: convertDependOnToShowIf(dependOn),
      canSkip: dependOn ? !dependingQuestionsAreRequired : !rest.required,
      InputComponent:
        (fieldType && AllFormikFields[fieldType]) || AllFormikFields.text,
      validationSchema: validationSchemaForQuestion(
        question,
        isMultiple,
        translation,
      ),
      value: value || (isMultiple ? [] : value),
    }
  })(questions)

export const collectPropFrom = (
  questions:
    | Array<{ [propName: string]: any }>
    | { [questionKey: string]: { [propName: string]: any } },
  propName?: ((question: any) => any) | string,
  toCompact?: boolean,
): { [questionKey: string]: any } => {
  const result = reduce((hash, question) => {
    const { name } = question
    let value = typeof propName === 'function' && propName(question)
    if (isNil(propName)) value = question
    if (isString(propName)) value = question[propName]
    return {
      ...hash,
      [name]: value,
    }
  }, {})(questions)
  return toCompact ? compactObject(result) : result
}

export const getQuestionSetBy = (
  fieldName: string,
  questions: QuestionsType,
  orders: OrdersType,
): QuestionsType => {
  const normalizedOrders = orders.map(ensureArray)
  const orderSet = normalizedOrders.find(
    (orderArray) => orderArray[0] === fieldName,
  )
  return compactArray(
    (orderSet || []).map((questionName) =>
      questions.find((question) => question.name === questionName),
    ),
  )
}

export const getQuestionValidatorProp = ({
  dependOn,
  validationSchema,
}: {
  dependOn: ?AnswerSetType,
  validationSchema: ?any,
}) => {
  if (dependOn) {
    const dependOnName = Object.keys(dependOn).toString()
    const conditionalSchemaOptions = {
      is: dependOn[dependOnName],
      then: (schema) => validationSchema || schema.required(),
      otherwise: (schema) => schema.nullable(),
    }
    const baseSchema = () => {
      switch (validationSchema?.type) {
        case 'number':
          return yup.number().when(dependOnName, conditionalSchemaOptions)
        case 'string':
          return yup.string().when(dependOnName, conditionalSchemaOptions)
        default:
          return yup.mixed().when(dependOnName, conditionalSchemaOptions)
      }
    }

    return baseSchema()
  }
  return validationSchema
}
