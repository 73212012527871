// @flow

// Converts a string to snake_case for use in localization (CARE system).
// This key format is used to retrieve translations for backend constants.

export const buildLocalizationKey = (string: string) => {
  if (!string) return null

  return string
    .toLowerCase() // Convert to lowercase
    .trim() // Remove leading and trailing spaces
    .replace(/[^a-z0-9_\s]/g, '') // Remove non-alphanumeric characters except spaces
    .replace(/\s+/g, '_') // Replace spaces with underscores
}
