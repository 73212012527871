// @flow

import React from 'react'
import { useFela } from 'react-fela'
import { useTranslation } from 'react-i18next'

import AlertIcon from 'react-ui/assets/icons/alert-circle.svg'
import Card from 'react-ui/components/Card'
import { H2 } from 'react-ui/components/Heading'
import Icon from 'react-ui/components/Icon'
import { useCurrentUser } from 'react-ui/contexts/UserContext'
import { Button } from 'care-ui'

import {
  cardHeaderStyle,
  cardStyle,
  messageStyle,
  pageStyle,
} from './UnauthorizedPage.style'

const UnauthorizedPage = () => {
  const { css } = useFela()
  const { homepage_path } = useCurrentUser()

  const { t: translation } = useTranslation()

  return (
    <div className={css(pageStyle)}>
      <Card extend={cardStyle}>
        <Icon as={AlertIcon} scale={4} />

        <H2 extend={cardHeaderStyle}>{translation('unauthorised_access')}</H2>

        <p className={css(messageStyle)}>
          {translation(
            'you_are_not_authorised_to_access_this_page_for_access_please_contact_your_supervisor_or_systems_admin',
          )}
        </p>

        <Button variant="primary" to={homepage_path}>
          {translation('back_to_home')}
        </Button>
      </Card>
    </div>
  )
}

export default UnauthorizedPage
