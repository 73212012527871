// @flow

import React from 'react'
import { connect } from 'react-fela'
import type { TFunction } from 'react-i18next'
import { useTranslation } from 'react-i18next'
import { createFragmentContainer, useRelayEnvironment } from 'react-relay'
import { Form as FormikForm, Formik } from 'formik'
import useRouter from 'found/useRouter'
import { isEmpty } from 'lodash/fp'
import * as yup from 'yup'

import { commit as commitIndividualPersonalDetailUpdate } from 'mutations/IndividualPersonalDetailUpdate'
import { normalizeIndividualPersonalDetailValues } from 'shared/services/normalization'
import {
  collectPropFrom,
  convertQuestions,
  getQuestionSetBy,
  getQuestionValidatorProp,
} from 'shared/utils/QuestionnaireUtils'
import type {
  FormikQuestionType,
  QuestionnaireType,
} from 'shared/utils/SimpleQuestionnaireTypes'
import { Button, Heading } from 'care-ui'

import { query } from '../../queries/UserSettingsPersonalDetailsEditPage'

import { styleRules } from './UserSettingsPersonalDetailsEditPage.style'

import type { FelaRulesType, FelaStylesType } from 'react-ui/typing'
import type { UserSettingsPersonalDetailsEditPage_profileQuestionnaire } from '../../queries/__generated__/UserSettingsPersonalDetailsEditPage_profileQuestionnaire.graphql'

type PropsType = {
  profileQuestionnaire: UserSettingsPersonalDetailsEditPage_profileQuestionnaire,
  rules: FelaRulesType,
  styles: FelaStylesType,
}

const prepareQuestionSetFrom = (
  questionnaire: QuestionnaireType,
  field: string,
  translation: TFunction,
): Array<FormikQuestionType> => {
  if (!questionnaire) return []

  const { questions, orders } = questionnaire
  const questionSet = getQuestionSetBy(field, questions, orders)
  return convertQuestions(questionSet, translation)
}

const handleSubmit = (environment, profile_type, router) => (values) =>
  commitIndividualPersonalDetailUpdate({
    environment,
    variables: {
      input: {
        individual_personal_detail: normalizeIndividualPersonalDetailValues(
          values,
        ),
        profile_type,
      },
    },
    onCompleted: () => router.replace('settings_personal_details'),
  })

const UserSettingsPersonalDetailsEditPage = (props: PropsType) => {
  const { rules, styles, profileQuestionnaire } = props

  const {
    match: {
      params: { field },
    },
    router,
  } = useRouter()
  const environment = useRelayEnvironment()
  const { t: translation } = useTranslation()
  const questionSet = prepareQuestionSetFrom(
    profileQuestionnaire,
    field,
    translation,
  )
  const initialValues = {
    ...collectPropFrom(questionSet, 'value'),
    // NOTE: this is to ensure that we have values for those input controls that have `other` option
    other: collectPropFrom(
      questionSet,
      ({ otherEnabled, other }) => otherEnabled && (other || ''),
      true,
    ),
  }

  if (
    initialValues.primary_language_initial &&
    initialValues.primary_language === 'english'
  )
    delete initialValues.primary_language

  const validationSchema = yup
    .object()
    .shape({ ...collectPropFrom(questionSet, getQuestionValidatorProp) })

  const { label, profile_type } = questionSet[0]

  return (
    <div className={styles.container}>
      <Button
        variant="secondary"
        componentId="back_button"
        to="settings_personal_details"
        ariaLabel={translation('back')}
        dataTestId="back"
      >
        {translation('back')}
      </Button>

      <Heading level={3} margin="xs">
        {label}
      </Heading>

      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit(environment, profile_type, router)}
        validationSchema={validationSchema}
      >
        {({ values, isSubmitting, errors }) => (
          <FormikForm>
            {questionSet.map(
              ({ InputComponent, id, showIf, options, title, otherEnabled }) =>
                showIf(values) && (
                  <InputComponent
                    id={id}
                    key={id}
                    options={options}
                    rules={rules}
                    title={title !== label && title}
                    data={{ otherEnabled }}
                  />
                ),
            )}

            <Button
              componentId="submit_button"
              disabled={!isEmpty(errors) || isSubmitting}
              type="submit"
              variant="primary"
              ariaLabel={translation('update')}
              dataTestId="submit"
            >
              {translation('update')}
            </Button>
          </FormikForm>
        )}
      </Formik>
    </div>
  )
}

export const UserSettingsPersonalDetailsEditPageLoader = createFragmentContainer(
  connect(styleRules)(UserSettingsPersonalDetailsEditPage),
  query,
)
