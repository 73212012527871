// @flow

import React, { type Node, useState } from 'react'
import { useFela } from 'react-fela'
import { useTranslation } from 'react-i18next'
import { createFragmentContainer, graphql } from 'react-relay'

import { AccordionItem } from 'react-ui/components/Accordion'

import { StratifiedScoringResultAnswerTableLoader } from './StratifiedScoringResultAnswerTable'

import type { StratifiedScoringResultAnswers_answers } from './__generated__/StratifiedScoringResultAnswers_answers.graphql'

type PropsType = {
  answers: ?StratifiedScoringResultAnswers_answers,
  children?: Node,
  isChild?: boolean,
  label: string,
  rating: ?string,
}

const accordionItemStyle = ({ theme, isChild }) => ({
  AccordionItem: {
    marginBottom: '0',
    backgroundColor: 'white',
    borderBottom: `1px solid ${theme.palette.dividerColor}`,
    '& table': {
      overflow: 'hidden',
      marginTop: '0',
      marginBottom: '0',
      '& th': {
        '&:last-child': {
          paddingLeft: '0',
          width: '27.6rem',
          maxWidth: '100%',
        },
      },
      '& tbody': {
        backgroundColor: 'unset',
        '& tr': {
          minHeight: '2rem',
          '&:last-child': {
            '& td': {
              borderBottom: 'none',
            },
          },
        },
        '& td': {
          borderBottom: `1px solid ${theme.palette.dividerColor}`,
          padding: '0.5rem 0',
          '&:first-child': {
            paddingLeft: '0',
            borderLeft: '2.8rem solid transparent',
            paddingRight: '1rem',
            height: '3.3rem',
          },
          '&:nth-child(2)': {
            paddingRight: '1rem',
          },
          '&:last-child': {
            paddingRight: '0',
            borderRight: '2.8rem solid transparent',
          },
        },
      },
    },
  },
  header: {
    backgroundColor: 'white',
    flexDirection: 'row-reverse',
    height: '3.7rem',
    lineHeight: '2rem',
    paddingLeft: isChild ? '1.7rem' : '0.3rem',
  },
  headerContainer: {
    backgroundColor: 'white',
  },
  heading: {
    fontSize: '1rem',
  },
  icon: {
    marginLeft: '0.3rem',
    margin: '0.7rem 0.9rem 0 0.3rem',
    width: '0.5rem',
    height: '0.5rem',
  },
  children: {
    backgroundColor: theme.palette.lightBackground,
    paddingTop: '0',
    paddingBottom: '0',
    paddingRight: '0',
    paddingLeft: '0',
  },
})

const headerStyle = ({ isChild }) => {
  const firstChildWidth = isChild ? '33rem' : '34.5rem'
  return {
    fontWeight: 'normal',
    display: 'flex',
    '& div': {
      ':first-child': {
        width: firstChildWidth,
      },
      ':nth-child(2)': {
        width: '31.5rem',
      },
      ':nth-child(3)': {
        width: '12rem',
      },
    },
  }
}

const StratifiedScoringResultAnswers = ({
  label,
  rating,
  answers,
  children,
  isChild,
}: PropsType) => {
  // Localization
  const { t: translation } = useTranslation()
  const { css } = useFela()
  const [isOpen, setOpen] = useState(false)
  const onToggleOpen = () => {
    setOpen(!isOpen)
  }

  const headingData = () => {
    return (
      <div className={css(headerStyle({ isChild }))}>
        <div>{label}</div>
        <div>{translation('completed')}</div>
        <div>{rating}</div>
      </div>
    )
  }

  const renderAnswers = () => {
    if (children) {
      return children
    }

    return answers?.length ? (
      <StratifiedScoringResultAnswerTableLoader answers={answers} />
    ) : (
      translation('no_results_found')
    )
  }

  return (
    <AccordionItem
      withArrow
      extend={({ theme }) => accordionItemStyle({ theme, isChild })}
      isOpen={isOpen}
      onToggleOpen={onToggleOpen}
      heading={headingData()}
    >
      {renderAnswers()}
    </AccordionItem>
  )
}

export const StratifiedScoringResultAnswersLoader = createFragmentContainer(
  StratifiedScoringResultAnswers,
  {
    answers: graphql`
      fragment StratifiedScoringResultAnswers_answers on AnswerInterface
        @relay(plural: true) {
        ...StratifiedScoringResultAnswerTable_answers
      }
    `,
  },
)
