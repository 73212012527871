// @flow

import React, { Fragment } from 'react'
import type { TFunction } from 'react-i18next'
import { lowerCase } from 'lodash'

import Link from 'react-ui/components/Link'

const InnowellFeaturesQuestions = (translation: TFunction) => [
  {
    key: 'whatisInitialQuestionnaire',
    question: (
      <Fragment> {translation('what_is_the_initial_questionnaire')}</Fragment>
    ),
    answer: (
      <Fragment>
        {translation(
          'the_initial_questionnaire_is_a_comprehensive_health_assessment_completed_as_part_of_an_intake_onboarding_process_each_set_of_questions_is_a_specific_collection_of_validated_psychometric_tools_that_are_used_to_provide_real_time_standardised_data_that_then_sits_on_a_person_s_personal_dashboard_this_initial_data_set_enables_individuals_and_health_professionals_to_make_decisions_about_needs_and_priorities_as_they_work_together_in_ongoing_care',
        )}
      </Fragment>
    ),
  },
  {
    key: 'needToCompleteQuestionnaire',
    question: (
      <Fragment>
        {translation(
          'does_the_initial_questionnaire_need_to_be_completed_all_at_once',
        )}
      </Fragment>
    ),
    answer: (
      <Fragment>
        {translation(
          'each_set_of_questions_can_be_skipped_with_the_option_to_complete_them_later_with_a_health_professional_or_a_support_person_a_person_s_answers_are_sorted_into_health_cards_that_can_be_viewed_from_their_dashboard_any_question_set_that_is_skipped_with_the_option_to_do_later_with_a_clinician_raises_an_orange_flag_visible_in_the_service_s_client_list',
        )}
      </Fragment>
    ),
  },
  {
    key: 'whatisDashboard',
    question: <Fragment> {translation('what_is_the_dashboard')} </Fragment>,
    answer: (
      <Fragment>
        {translation(
          'the_dashboard_holds_a_person_s_health_and_wellbeing_story_it_includes_their_profile_health_history_and_health_card_data_it_can_be_updated_anytime_to_reflect_how_a_person_is_currently_feeling_and_functioning_helping_to_track_needs_coordinate_care_and_identify_health_and_wellbeing_goals',
        )}
      </Fragment>
    ),
  },
  {
    key: 'howDashboardInformCare',
    question: (
      <Fragment> {translation('how_does_the_dashboard_inform_care')} </Fragment>
    ),
    answer: (
      <Fragment>
        {translation(
          'the_health_service_will_engage_with_a_person_s_dashboard_as_part_of_a_holistic_approach_to_care_the_combination_of_psychometric_data_and_health_information_informs_and_guides_ongoing_care_and_treatment_options_providing_opportunities_to_assess_and_monitor_a_person_s_progress',
        )}
      </Fragment>
    ),
  },
  {
    key: 'whatisSummaryDashboard',
    question: (
      <Fragment> {translation('what_is_the_summary_dashboard')} </Fragment>
    ),
    answer: (
      <Fragment>
        {translation(
          'the_summary_dashboard_consists_of_2_5_specific_gauges_reflecting_a_health_domain_all_gauges_are_updated_together_to_provide_an_overall_impression_of_a_person_s_health_and_wellbeing',
        )}
      </Fragment>
    ),
  },
  {
    key: 'whoContibutesToSmmaryDashboard',
    question: (
      <Fragment>
        {translation('who_can_contribute_to_a_person_s_summary_dashboard')}{' '}
      </Fragment>
    ),
    answer: (
      <Fragment>
        {translation(
          'a_clinician_can_contribute_feedback_to_the_summary_dashboard_when_a_support_person_is_invited_they_can_also_contribute_they_answer_a_series_of_questions_linked_to_the_individual_s_summary_dashboard_adding_to_the_overall_data_related_to_a_person_s_health_and_wellbeing',
        )}
      </Fragment>
    ),
  },
  {
    key: 'whoisSupportPerson',
    question: <Fragment> {translation('who_is_a_support_person')} </Fragment>,
    answer: (
      <Fragment>
        {translation(
          'a_support_person_is_a_person_nominated_by_an_individual_to_give_additional_feedback_on_their_everyday_functioning_and_wellbeing_a_support_person_will_be_able_to_answer_a_series_of_questions_on_areas_of_health_linked_to_the_summary_dashboard_they_can_be_invited_and_removed_at_anytime_by_the_individual_as_needed',
        )}
        <br />
        {translation(
          'they_cannot_see_the_full_dashboard_unless_an_individual_chooses_to_show_it_to_them_from_their_own_device',
        )}
      </Fragment>
    ),
  },
  {
    key: 'whatAreHealthCards',
    question: <Fragment> {translation('what_are_health_cards')} </Fragment>,
    answer: (
      <Fragment>
        {translation(
          'the_health_cards_reflect_a_range_of_health_areas_that_make_up_a_multidimensional_view_of_a_person_s_health_and_wellbeing_when_you_complete_the_initial_questionnaire_the_response_data_is_sorted_into_the_relevant_card_on_the_back_of_a_health_card_it_will_show_details_of_the_health_area_that_include',
        )}
        <ul>
          <li>{translation('information')}</li>
          <li>{lowerCase(translation('results'))}</li>
          <li>{translation('factsheets')}</li>
          <li>{lowerCase(translation('care_options'))} </li>
          <li>{translation('previous_responses_psychometric_data_sets')}</li>
        </ul>
      </Fragment>
    ),
  },
  {
    key: 'healthCardsColours',
    question: (
      <Fragment>{translation('why_are_the_health_cards_coloured')} </Fragment>
    ),
    answer: (
      <Fragment>
        {translation(
          'each_health_card_is_displayed_as_red_amber_yellow_or_green_providing_you_with_real_time_feedback_drawing_from_the_individual_s_responses_the_colour_of_the_health_card_tells_you_whether_this_area_of_health_is',
        )}
        <ul>
          <li>{translation('red_a_high_concern')}</li>
          <li>{translation('amber_a_medium_concern')}</li>
          <li>{translation('yellow_a_mild_concern')}</li>
          <li>{translation('green_a_low_concern_or_no_concern')}</li>
        </ul>
      </Fragment>
    ),
  },
  {
    key: 'whoDecidesHealthCardsRange',
    question: (
      <Fragment>
        {' '}
        {translation('who_decides_on_the_range_of_health_cards')}{' '}
      </Fragment>
    ),
    answer: (
      <Fragment>
        {translation(
          'the_range_of_health_cards_are_decided_by_each_health_service_if_you_think_other_cards_would_enable_you_to_tell_your_story_better_please_provide_this_feedback_to_innowell_at',
        )}
        <Link to="mailto: support@innowell.org"> support@innowell.org </Link>
      </Fragment>
    ),
  },
  {
    key: 'howToAcceptMultipleRoles',
    question: (
      <Fragment>
        {' '}
        {translation('how_to_access_multiple_roles_staff_and_individual')}{' '}
      </Fragment>
    ),
    answer: (
      <Fragment>
        {translation(
          'innowell_limits_users_from_having_an_indivdual_role_and_a_staff_role_using_the_same_email_address_however_there_is_an_option_to_gain_access_to_these_roles_using_a_in_your_email_address_that_helps_create_another_email_address_to_avoid_the_conflict_for_example_if_your_email_is_firstname_lastname_gmail_com_then_by_adding_the_such_as_firstname_lastname_text_gmail_com_will_allow_the_user_to_gain_access_to_the_other_roles',
        )}
        I
      </Fragment>
    ),
  },
  {
    key: 'whatIsTrajectoryGraph',
    question: (
      <Fragment>{translation('what_is_the_trajectory_graph')}</Fragment>
    ),
    answer: (
      <Fragment>
        {translation(
          'this_predictive_tool_uses_a_person_s_demographic_and_clinical_information_to_learn_about_their_specific_pattern_of_outcomes_over_time_the_tool_helps_clinicians_identify_what_trajectory_someone_is_on_based_on_current_results_which_can_be_used_to_guide_decision_making_about_their_care_and_how_frequently_they_may_need_to_be_monitored_the_more_uncertain_an_individual_appears_i_e_a_wider_predicted_path_the_more_frequent_they_should_be_measured',
        )}
      </Fragment>
    ),
  },
]

export default InnowellFeaturesQuestions
