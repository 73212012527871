// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import { type FormikProps } from 'formik'
import * as yup from 'yup'

import { createForm } from 'react-ui/components/Form'

type FormProps = {
  code: string,
}

type SubmitHandlerType = (FormProps, FormikProps) => void

type PropsType = {
  onSubmit: SubmitHandlerType,
}

const MultiFactorAuthenticationForm = (props: PropsType) => {
  const { t: translation } = useTranslation()

  const fieldDefinitions = [
    {
      block: true,
      id: 'code',
      fieldType: 'password',
      validationSchema: yup
        .string()
        .required(translation('please_enter_verification_code')),
      placeholder: translation('enter_verification_code'),
    },
  ]

  const Form = createForm(fieldDefinitions)

  return (
    <Form {...(props: any)}>
      {({ errorMessage, renderedFields, submitButton }) => (
        <>
          {renderedFields}

          {errorMessage}

          {React.cloneElement(submitButton, {
            children: translation('next'),
          })}
        </>
      )}
    </Form>
  )
}

export default MultiFactorAuthenticationForm
