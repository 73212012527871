// @flow

import React from 'react'
import { useFela } from 'react-fela'
import { useTranslation } from 'react-i18next'
import useRouter from 'found/useRouter'

import Error from 'react-ui/assets/icons/error-bang.svg'
import Heading from 'react-ui/components/Heading'
import { Welcome2Col } from 'react-ui/components/Layout'
import Link from 'react-ui/components/Link'
import { requestReinvitation } from 'shared/services/api/registration'
import { Button } from 'care-ui'

const errorContainerStyle = ({ theme }) => ({
  display: 'flex',
  color: theme.palette.component.danger.base,
})

const errorIconStyle = ({ theme }) => ({
  fill: theme.palette.component.danger.base,
  width: '1rem',
  marginRight: '0.5rem',
})

const RequestInvitation = () => {
  const { css } = useFela()
  const {
    match: { location },
    router: { replace },
  } = useRouter()

  const { invitation_token: invitationToken } = location.query

  const onClick = async () => {
    const response = await requestReinvitation(invitationToken)
    if (response.status === 204) replace('/')
  }

  const { t: translation } = useTranslation()

  const errorMessage = () => (
    <div className={css(errorContainerStyle)}>
      <Error className={css(errorIconStyle)} />
      <span>{translation('this_invite_is_invalid')}</span>
    </div>
  )

  return (
    <Welcome2Col>
      <Heading level={2}>
        {translation('welcome_to_the_innowell_platform')}
      </Heading>
      <div>
        {errorMessage()}

        <p>
          {translation(
            'use_the_button_below_to_request_a_new_invite_from_your_service',
          )}
        </p>

        <Button variant="primary" onClick={onClick}>
          {translation('request_new_invite')}
        </Button>

        <p>
          {translation('already_have_an_account')}{' '}
          <Link to="/">{translation('sign_in')}</Link>
        </p>
      </div>
    </Welcome2Col>
  )
}

export default RequestInvitation
