// @flow

import React, { useState } from 'react'
import { useFela } from 'react-fela'
import { useTranslation } from 'react-i18next'
import { createFragmentContainer } from 'react-relay'

import { Box, FlexContainer, Heading, SimpleSelect, Text } from 'care-ui'

import { scoreCardsCareOptions_user } from '../../queries/ScoreCardsCareOptions'
import { ScoreCardsCareOptionsList } from '../ScoreCardsCareOptionsList'

import {
  careOptionsIntro,
  careOptionsTitle,
} from './ScoreCardsCareOptions.style'

import type { ScoreCardsCareOptions_user_trackable } from '../../queries/__generated__/ScoreCardsCareOptions_user_trackable.graphql'
import type { ScoreCardsCareOptions_viewer } from '../../queries/__generated__/ScoreCardsCareOptions_viewer.graphql'

type PropsType = {
  +user_trackable: ScoreCardsCareOptions_user_trackable,
  +viewer: ScoreCardsCareOptions_viewer,
}

const isClinical = (test: string) => (userResource) =>
  userResource.resource.clinical.toString() === test

const ScoreCardsCareOptionsComponent = (props: PropsType) => {
  const { user_trackable, viewer } = props
  const { suggested_user_resources } = user_trackable

  const { t: translation } = useTranslation()

  const careOptionFilters = [
    { label: translation('all'), value: 'all' },
    { label: translation('self_care'), value: 'false' },
    { label: translation('work_with_clinician'), value: 'true' },
  ]

  const [selectedValue, setSelectedValue] = useState(careOptionFilters[0].value)

  const { css } = useFela()
  const changeCareOptionFilter = (value) => {
    setSelectedValue(value)
  }

  const selectedCareOptions =
    selectedValue === 'all'
      ? suggested_user_resources
      : suggested_user_resources.filter(isClinical(selectedValue))

  return (
    <Box marginY="sm">
      <FlexContainer justifyContent="space-between" alignItems="center">
        <div>
          <Heading level={2} extend={careOptionsTitle}>
            {translation('care_options')}
          </Heading>

          <Text className={css(careOptionsIntro)}>
            {translation(
              'choose_from_the_self_care_options_or_request_an_option_with_a_clinician',
            )}
          </Text>
        </div>

        <Box width="200px">
          <SimpleSelect
            options={careOptionFilters}
            value={selectedValue}
            onChange={changeCareOptionFilter}
            dataTestIdForDropdown="careOptionsDropdown"
            dataTestIdForDropdownOptions="careOptions"
          />
        </Box>
      </FlexContainer>

      <div key={`filtered-care-options-${selectedCareOptions.length}`}>
        <ScoreCardsCareOptionsList
          user_resources={selectedCareOptions}
          viewer={viewer}
        />
      </div>
    </Box>
  )
}

export const ScoreCardsCareOptions = createFragmentContainer(
  ScoreCardsCareOptionsComponent,
  scoreCardsCareOptions_user,
)
