// @flow

import * as React from 'react'
import { connect } from 'react-fela'
import classNames from 'classnames'
import omit from 'lodash/fp/omit'

import { felaProps } from 'shared/services/fela'

import TickIcon from './icons/Tick'
import Input, { type InputProps } from './Input'

import type { FelaPropsType } from 'react-ui/typing'

const styleRules = ({ checked, error, theme, uiStyle = 'primary' }) => ({
  Input: {
    className: 'CheckBox',
  },
  input: {
    className: classNames('Checkbox__input', {
      '--error': !!error,
      '--primary': uiStyle === 'primary',
      '--secondary': uiStyle === 'secondary',
    }).replace(/\s/g, ''),
    left: '-9999vw',
    position: 'absolute',

    ':checked:not(:disabled) + label:before': {
      backgroundColor: error
        ? theme.Input.error.base
        : theme.Input.default.base,
      borderColor: error ? theme.Input.error.accent : theme.Input.focus.accent,
    },

    ':disabled + label:before': {
      backgroundColor: theme.Input.disabled.base,
      borderColor: theme.Input.disabled.accent,
    },

    ':disabled + label svg': {
      fill: 'currentColor',
    },

    ':focus + label:before': (() => {
      const focusColor = error
        ? theme.Input.error.accent
        : theme.Input.focus.accent
      return {
        borderColor: focusColor,
        boxShadow: `0 0 4px 0 ${focusColor}`,
      }
    })(),
  },

  label: {
    className: classNames('Checkbox__label', {
      '--error': !!error,
      '--primary': uiStyle === 'primary',
      '--secondary': uiStyle === 'secondary',
    }).replace(/\s/g, ''),
    color: '#48566A',
    display: 'inline-block',
    paddingLeft: '31px',
    position: 'relative',

    ':hover': {
      cursor: 'pointer',
    },

    ':before': {
      backgroundColor: error ? theme.Input.error.base : theme.Input.label.base,
      borderColor: error ? theme.Input.error.accent : theme.Input.label.accent,
      borderRadius: uiStyle === 'primary' ? '5px' : '50%',
      borderStyle: 'solid',
      borderWidth: '2px',
      boxSizing: 'border-box',
      content: '""',
      height: '21px',
      left: 0,
      lineHeight: '21px',
      position: 'absolute',
      top: '2px',
      transition: 'background-color 200ms',
      width: '21px',
      zIndex: 0,
    },
  },

  activeIcon: {
    className: classNames('Checkbox__activeIcon', {
      '--error': error,
      '--checked': checked,
      '--primary': uiStyle === 'primary',
      '--secondary': uiStyle === 'secondary',
    }).replace(/\s/g, ''),
    fill: error ? theme.Input.error.accent : theme.Input.icon.base,
    height: '9px',
    left: '4px',
    opacity: checked ? 1 : 0,
    pointerEvents: 'none',
    position: 'absolute',
    top: '7px',
    transition: 'all 200ms',
    transform: `scale(${checked ? 1 : 0})`,
    width: '13px',
  },
})

export type CheckBoxProps = {
  activeIcon?: ({ className: string }) => React.Element<*>,
  checked?: boolean,
  id: string,
  label?: React.Node,
} & InputProps

const CheckBox = ({
  activeIcon: ActiveIcon = TickIcon,
  id,
  label,
  styles,
  rules,
  ...props
}: CheckBoxProps & FelaPropsType) => {
  const isChecked = props?.checked || false
  return (
    <React.Fragment>
      <Input
        id={id}
        type="checkbox"
        extend={(...args) => ({
          Input: rules.Input(...args),
          input: rules.input(...args),
        })}
        {...omit(['error', 'prefix', 'suffix', 'uiStyle', ...felaProps], props)}
      >
        <label className={styles.label} htmlFor={id}>
          <ActiveIcon
            data-testid={isChecked ? 'checked' : 'unchecked'}
            className={styles.activeIcon}
          />
          <span
            data-testid={`labelText-${isChecked ? 'checked' : 'unchecked'}`}
            className={styles.labelText}
          >
            {label}
          </span>
        </label>
      </Input>
    </React.Fragment>
  )
}

export default connect(styleRules)(CheckBox)
