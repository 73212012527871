// @flow

import { type Node, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRouter } from 'found'

import { jsonLocalStorage } from 'services/browserStorage'
import { currentUnixTime } from 'services/currentTime'
import { createFlash } from 'platform_web/containers/Flash'

type TimesType = {
  warningAt: number,
  warningHideAt?: number,
}

type WarningFunctionAsChildProps = {
  dismiss: () => void,
}

type PropsType = TimesType & {
  children: (WarningFunctionAsChildProps) => Node,
}

const SessionTimeoutWarningBase = (props: PropsType) => {
  const { children, warningAt, warningHideAt } = props
  const [ignored, setIgnored] = useState(false)
  const [showWarning, setShowWarning] = useState(false)

  const { router } = useRouter() || {}

  const { t: translation } = useTranslation()

  const checkInWarningTime = useCallback(() => {
    const sessionTimeoutAt: number = jsonLocalStorage.getItem(
      'sessionTimeoutAt',
      null,
    )

    if (sessionTimeoutAt) {
      const underWarningStart =
        sessionTimeoutAt - currentUnixTime() <= warningAt
      const aboveWarningEnd =
        typeof warningHideAt === 'number'
          ? sessionTimeoutAt - currentUnixTime() >= warningHideAt
          : true
      setShowWarning(underWarningStart && aboveWarningEnd)
    }

    if (sessionTimeoutAt !== null && sessionTimeoutAt - currentUnixTime() < 0) {
      createFlash({
        message: translation(
          'your_session_has_expired_please_sign_in_again_to_continue',
        ),
        type: 'alert',
        timeout: 0,
      })

      if (router) router.replace('signingOut')
    }
  }, [warningAt, warningHideAt])

  useEffect(() => {
    checkInWarningTime()

    const refresh = setInterval(() => {
      setIgnored(false)
      checkInWarningTime()
    }, 5000)

    return () => clearInterval(refresh)
  }, [checkInWarningTime])

  const handleClick = () => setIgnored(true)

  const shouldRender = showWarning && !ignored

  return shouldRender
    ? children({
        dismiss: handleClick,
      })
    : null
}

export default SessionTimeoutWarningBase
