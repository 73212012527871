// @flow

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { endOfMonth, format, startOfMonth, subMonths } from 'date-fns'
import useRouter from 'found/useRouter'

import { PageHeader } from 'platform_web/components'
import { useCurrentUserRoles } from 'platform_web/hooks/useCurrentUserRoles'
import { Box, Callout, Container, Tabs } from 'care-ui'
import alertInfo from 'care-ui/atoms/icons/alert-info.svg'

import OutcomesHeaderActions from './components/OutcomesHeaderActions'
import InsightsPageHeaderActions from './components/PageHeaderActions'
import Outcomes from './tabs/Outcomes'
import useOutcomeReporting from './tabs/Outcomes/hooks/useOutcomeReporting'
import Overview from './tabs/Overview'
import useOverviewInsights from './tabs/Overview/hooks/useOverviewInsights'

const defaultOverviewQueryVariables = {
  interval: 'monthly',
  start_date: format(startOfMonth(subMonths(new Date(), 5)), 'yyyy-MM-dd'),
  end_date: format(endOfMonth(new Date()), 'yyyy-MM-dd'),
}

const defaultOutcomeReportingQueryVariables = {
  quarter_end_date: format(new Date(), 'yyyy-MM-dd'),
}

const Insights = () => {
  const { t: translation } = useTranslation()
  // For owner role, we need to set default tenant_id filter
  const { currentUser, ownerTenants, tenantCreatedDate } = useCurrentUserRoles()
  const displayOutcomeReporting = window.GLOBALS?.features?.OUTCOME_REPORTING
  const ownerDefaultTenantId = ownerTenants?.[0]?.id
  const tenantId = currentUser?.tenant?.id
  const ownerDefaultTenantCreatedDate = ownerTenants?.[0]?.created_at

  const useInsightsPayload = useOverviewInsights({
    defaultOverviewQueryVariables,
  })

  const useOutcomeReportingPayload = useOutcomeReporting({
    defaultOutcomeReportingQueryVariables,
  })

  const {
    overviewQueryVariables,
    setOverviewQueryVariables,
  } = useInsightsPayload
  const {
    outcomeReportQueryVariables,
    setOutcomeReportQueryVariables,
  } = useOutcomeReportingPayload

  const createdDate = ownerDefaultTenantCreatedDate || tenantCreatedDate
  const { interval = 'monthly' } = overviewQueryVariables

  const { router } = useRouter()
  const displayNewInsights = window.GLOBALS?.features?.NEW_INSIGHTS

  // If `displayOutcomeReporting` is true, default to index 0 (Outcomes tab); otherwise, default to index 1 (Overview tab).
  const [selectedIndex, setSelectedIndex] = useState(+!displayOutcomeReporting)

  useEffect(() => {
    if (!displayNewInsights) {
      router.replace({ name: 'insights' })
    }
  }, [])

  // Preload all the query on insights page load
  // Require current user redux state to be loaded for getting all the ownerTenants for owner roles
  useEffect(() => {
    if (!currentUser.loggedIn) return
    setOverviewQueryVariables({ owner_tenant_id: ownerDefaultTenantId })

    setOutcomeReportQueryVariables({
      tenant_id: ownerDefaultTenantId || tenantId,
      quarter_start_date: createdDate,
    })
  }, [currentUser])

  const calloutText = (
    <span>
      {translation(
        'welcome_to_your_insights_page_we_ll_be_updating_it_regularly_for_you_with_new_features_so_if_there_are_metrics_you_d_like_to_see_or_you_have_questions_let_us_know_at',
      )}{' '}
      &nbsp;
      <a href="mailto:feedback@innowell.org">feedback@innowell.org</a>.
    </span>
  )

  const tabs = [
    {
      label: translation('outcomes'),
      content: (
        <Outcomes outcomeReportQueryVariables={outcomeReportQueryVariables} />
      ),
      action: (
        <OutcomesHeaderActions
          setOverviewQueryVariables={setOverviewQueryVariables}
          overviewQueryVariables={overviewQueryVariables}
          outcomeReportQueryVariables={outcomeReportQueryVariables}
          setOutcomeReportQueryVariables={setOutcomeReportQueryVariables}
        />
      ),
    },
    {
      label: translation('overview'),
      content: <Overview overviewQueryVariables={overviewQueryVariables} />,
      action: (
        <InsightsPageHeaderActions
          interval={interval}
          setOutcomeReportQueryVariables={setOutcomeReportQueryVariables}
          setOverviewQueryVariables={setOverviewQueryVariables}
          overviewQueryVariables={overviewQueryVariables}
        />
      ),
    },
  ]

  const handleTabChange = (index) => {
    setSelectedIndex(index)
  }

  return (
    <>
      <PageHeader
        pageTitle={translation('insights')}
        pageAction={tabs[selectedIndex]?.action}
      />

      <Container>
        <Box marginY="sm">
          <Callout
            calloutText={calloutText}
            variant="warning"
            calloutIcon={alertInfo}
          />
        </Box>
        {displayOutcomeReporting && (
          <Tabs
            tabs={tabs.map((tab) => ({
              label: tab.label,
              content: tab.content,
            }))}
            defaultTab={0}
            onChange={handleTabChange}
          />
        )}
        {tabs[selectedIndex]?.content}
      </Container>
    </>
  )
}

export default Insights
