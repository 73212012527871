// @flow

import React from 'react'
import { useFela } from 'react-fela'
import { useTranslation } from 'react-i18next'
import { createFragmentContainer, useRelayEnvironment } from 'react-relay'
import { useRouter } from 'found'

import Icon from 'react-ui/components/Icon/Icon'
import { useAssessmentNotification } from 'react-ui/contexts/AssessmentNotificationContext'
import { commit as commitAssessmentRequestCreate } from 'mutations/AssessmentRequestAcknowledge'
import { Button } from 'care-ui'
import Graph from 'care-ui/atoms/icons/line-graph.svg'

import { requestAssessmentButtonIndividualQuery } from './query/RequestAssessmentButton'
import {
  buttonWrapperStyle,
  notificationStyle,
} from './RequestedAssessmentButton.style'

import type { RequestAssessmentButton_individual } from './query/__generated__/RequestAssessmentButton_individual.graphql'

type PropsType = {
  individual: ?RequestAssessmentButton_individual,
}

const RequestAssessmentButton = ({ individual }: PropsType) => {
  const { router } = useRouter()
  const environment = useRelayEnvironment()
  const { css } = useFela()
  const { notify, assessmentRequestId } = useAssessmentNotification()
  const { user } = individual || {}

  const REQUEST_ASSESSMENTS = window.GLOBALS.features?.REQUEST_ASSESSMENTS

  const { t: translation } = useTranslation()

  if (!REQUEST_ASSESSMENTS) return null

  const routeName = user ? 'user_assessment_requests' : 'assessment_requests'
  const tooltipText = user
    ? translation('assessments')
    : translation('requested_assessments')

  const handleClick = () => {
    if (notify && !user) {
      commitAssessmentRequestCreate({
        environment,
        variables: {
          input: {
            assessmentRequestId,
          },
        },
      })
    }

    router.push({
      name: routeName,
      params: {
        id: user?.id,
      },
    })
  }

  return (
    <div className={css(buttonWrapperStyle)}>
      <Button
        variant="secondary"
        onClick={handleClick}
        dataTestId="requestAssessmentButton"
        ariaLabel={translation('request_assessment_button')}
        tooltipText={tooltipText}
      >
        {notify && <div className={css(notificationStyle)} />}
        <Icon as={Graph} scale={0.8} />
      </Button>
    </div>
  )
}

export default createFragmentContainer(
  RequestAssessmentButton,
  requestAssessmentButtonIndividualQuery,
)
