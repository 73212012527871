//  @flow

import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { addMilliseconds } from 'date-fns'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import HighchartsMore from 'highcharts/highcharts-more'
import addNoDataModule from 'highcharts/modules/no-data-to-display'

import {
  type SleepType,
  useScoreCardChart,
} from 'react-ui/contexts/ScoreCardChartContext/ScoreCardChartContext'
import { trackFitbitViewSleepGraph } from 'services/analytics/events'
import {
  asUTC,
  MILLIS_PER_DAY,
  MILLIS_PER_HOUR,
  utcStartOfDay,
} from 'services/dateTime'

import {
  type DateTimeAxisType,
  type formulaRangesType,
  Basic,
  chart,
  dateTimeAxis,
  responsiveRules,
  tooltip,
} from './defaultOptions'
import { withinDateRange } from './helpers'

import './tooltip.scss'

HighchartsMore(Highcharts)
addNoDataModule(Highcharts)

type PropsType = {
  dateRange: DateTimeAxisType,
  series: Array<Object>,
  sleep: SleepType,
  sortedFormulas: formulaRangesType,
}

// Custom formatter for tooltip times, as date-fns doesn't let us format UTC values
// Returns 'h:mm a' for the UTC value of a Date
function utcTimeFormat(date: Date) {
  const rawHours = date.getUTCHours()
  let hours = rawHours % 12
  if (hours === 0) hours = 12
  const minutes = String(date.getUTCMinutes()).padStart(2, '0')
  const ap = rawHours >= 12 ? 'pm' : 'am'
  return `${hours}:${minutes} ${ap}`
}

/**
 * Fitbit always returns datetimes without any time zone, as times are always
 * recorded as "local to the owner of the device". Our GraphQL API also returns
 * Fitbit data without time zones for consistency of data.
 *
 * To avoid rendering problems on the graph based on the browser's time zone and
 * daylight saving time, we work with all datetimes as if they're in UTC. This
 * provides a fixed timeline (with no DST) and is useable with native JS Date().
 */
const FormatSleepData = (
  sleep: SleepType,
  accessor: 'asleep' | 'inBed',
  dateRange: DateTimeAxisType,
) => {
  const filtered = withinDateRange({
    series: sleep,
    startDate: dateRange.utcStartDate,
    endDate: dateRange.utcEndDate,
    accessor: ({ date }) => asUTC(date),
  })

  const { t: translation } = useTranslation()

  // Use a single fixed day to calculate the Y axis times for Highcharts
  const FIXED_TIMESTAMP = asUTC(sleep[0]?.date)
  const filteredMapOfPoints: Array<Object> = filtered.map((point) => {
    const { date } = point
    const { startTime, endTime, hours, minutes } = point[accessor]

    // Treat all "local" times as UTC
    const dateTimestamp = asUTC(date)
    const startTimestamp = asUTC(startTime)
    const endTimestamp = asUTC(endTime)

    // Calculate millisecond offsets of start/end times from midnight on the given date
    const dayStart = utcStartOfDay(dateTimestamp)
    const startDiff = startTimestamp - dayStart
    const endDiff = endTimestamp - dayStart

    // Add the millisecond offsets to the fixed reference point to get Y axis values
    const normalisedStartTime = addMilliseconds(FIXED_TIMESTAMP, startDiff)
    const normalisedEndTime = addMilliseconds(FIXED_TIMESTAMP, endDiff)

    return {
      x: dayStart.getTime(),
      low: normalisedStartTime.getTime(),
      high: normalisedEndTime.getTime(),
      tooltipLow: utcTimeFormat(normalisedStartTime),
      tooltipHigh: utcTimeFormat(normalisedEndTime),
      tooltipTime: `${hours}h ${minutes}m`,
    }
  })

  return {
    type: 'columnrange',
    color: '#38B3CF',
    data: filteredMapOfPoints,
    name: translation('in_bed'),
    pointRange: MILLIS_PER_DAY,
    yAxis: 0,
  }
}

export const options = ({ dateRange, sleep }: PropsType) => {
  if (!dateRange) return null

  const seriesSet = []

  if (sleep && sleep.length > 0) {
    seriesSet.push(FormatSleepData(sleep, 'asleep', dateRange))
  }

  if (seriesSet.length < 1) {
    seriesSet.push(FormatSleepData([], 'asleep', dateRange))
  }

  return {
    ...Basic(),
    ...responsiveRules,
    ...tooltip(),

    chart: {
      ...chart,
    },

    title: {
      text: '',
    },

    xAxis: {
      ...dateTimeAxis(dateRange),
    },

    yAxis: {
      gridLineColor: 'transparent',
      type: 'datetime',
      tickInterval: MILLIS_PER_HOUR * 2,
      title: {
        text: 'Time',
      },
      labels: {
        format: '{value:%l %P}', // '4 am'
        // Need to explicitly set `x` to the default due to a bug in Highcharts:
        // https://github.com/highcharts/highcharts/issues/11458
        x: -10,
      },
    },

    tooltip: {
      backgroundColor: '#555',
      headerFormat: `<span style="line-height: 25px; font-size: 14px;"><b>&nbsp;{point.key}</b></span><br /><br />`,
      pointFormatter() {
        return `
        <span style="line-height: 20px; font-size: 14px;">
          Start sleep: ${this.tooltipLow}<br/>
          End sleep: ${this.tooltipHigh}<br/>
          Total time: ${this.tooltipTime}
        </span>
        `
      },
      style: {
        color: '#fff',
      },
      useHTML: false,
      xDateFormat: '%A %b %e',
    },

    plotOptions: {
      series: {
        animation: false,
        pointPlacement: 'on',
      },
    },

    series: seriesSet,
  }
}

const SleepChart = () => {
  const sleepChartProps = useScoreCardChart()
  const generatedOptions = options(sleepChartProps)

  useEffect(() => {
    trackFitbitViewSleepGraph()
  }, [])

  return (
    <div data-testid="SleepChart">
      <HighchartsReact highcharts={Highcharts} options={generatedOptions} />
    </div>
  )
}

export default SleepChart
