// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import { lowerCase } from 'lodash'

import type { UseMultiSelectReturnTypes } from 'platform_web/pages/Staff/hooks/useMultiSelect'
import { type QueryVariablesType } from 'platform_web/pages/Staff/Individuals/hooks/useIndividualTable'
import { Box, Button, FlexContainer, Modal, Text } from 'care-ui'

import useEmployeesActionsModal from '../../hooks/useEmployeesActionsModal'

type ResendEmployeesModalPropsType = {
  actionedRoleType: string,
  closeModal: () => void,
  isOpen: boolean,
  multiSelectPayload: UseMultiSelectReturnTypes,
  mutationQuery: any,
  queryVariables: QueryVariablesType,
}

const ResendEmployeesModal = (props: ResendEmployeesModalPropsType) => {
  const {
    actionedRoleType,
    closeModal,
    mutationQuery,
    isOpen,
    multiSelectPayload,
    queryVariables,
  } = props

  const useEmployeesActionsModalPayload = useEmployeesActionsModal({
    closeModal,
    queryVariables,
    mutationQuery,
    multiSelectPayload,
    actionedRoleType,
  })

  const {
    selectedUsersCount,
    allSelectedMinusDeselectedCount,
    onConfirmation,
  } = useEmployeesActionsModalPayload

  const { t: translation } = useTranslation()

  const formattedText = translation('employee', {
    count: selectedUsersCount || allSelectedMinusDeselectedCount,
  })

  return (
    <Modal
      width="400px"
      isOpen={isOpen}
      closeModal={closeModal}
      heading={translation('resend_invitation')}
      dataTestId="ResendInvitationModal"
    >
      <FlexContainer>
        <Text dataTestId="ModalBodyMainText">
          {translation('you_are_about_to_resend')}&nbsp;
          <strong>
            {lowerCase(actionedRoleType)} {translation('invitations')}
          </strong>
          &nbsp;
          {translation('for')}&nbsp;
          <strong>{`${selectedUsersCount} ${formattedText}`}</strong>.
        </Text>

        <Box paddingX="sm">
          <Text dataTestId="ModalConfirmationText">
            {translation('are_you_sure_you_want_to_continue')}
          </Text>
        </Box>
      </FlexContainer>

      <FlexContainer paddingX="sm" justifyContent="space-between">
        <Button
          dataTestId="CloseModalButton"
          onClick={closeModal}
          size="md"
          type="button"
          variant="text"
        >
          {translation('no')}
        </Button>

        <Button
          onClick={() => onConfirmation()}
          size="md"
          type="button"
          variant="primary"
          dataTestId="ConfirmResendInvitationsButton"
        >
          {translation('yes_resend_invitations')}
        </Button>
      </FlexContainer>
    </Modal>
  )
}

export default ResendEmployeesModal
