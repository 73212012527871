// @flow

import React, { type Node, Children, useState } from 'react'
import { connect } from 'react-fela'
import { useTranslation } from 'react-i18next'

import SimpleHeading from 'react-ui/components/Heading/SimpleHeading'
import { Layout2Col } from 'react-ui/components/Layout'
import Menu, { MenuItemWithBox } from 'react-ui/components/Menu'
import { withoutFelaProps } from 'shared/services/fela'
import { Button, Container, Heading } from 'care-ui'

import ReportsView from './reports/ReportsView'
import ServiceMetricsDefinitions from './ServiceMetricsDefinitions'
import { styleRules } from './ServiceMetricsPageWrapper.style'
import ViewStack from './ViewStack'

import type { FelaPropsType } from 'react-ui/typing'

type ViewMode = 'screen' | 'print'
const viewModes = Object.freeze({
  SCREEN: 'screen',
  PRINT: 'print',
})

type PropTypes = FelaPropsType & {
  actions?: Node,
  as: string,
  asideColSpan?: number,
  children: $ReadOnlyArray<ReportsView>,
  onRequestChangeViewMode: (nextViewMode: ViewMode) => void,
  onRequestIndex: (nextIndex: number) => void,
  reportView: string,
  selectedIndex: number,
  userRoles: $ReadOnlyArray<string>,
  viewMode: ViewMode,
}

const canAccess = (currentRoles, requiredRoles) => {
  return (
    (requiredRoles || []).filter((r) => (currentRoles || []).indexOf(r) !== -1)
      .length > 0
  )
}

const ServiceMetricsPage = ({
  actions,
  userRoles,
  as: Component = 'section',
  asideColSpan = 4,
  children,
  rules,
  styles,
  reportView,
  ...props
}: PropTypes) => {
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [viewMode, setViewMode] = useState('screen')

  const isPrintMode = viewMode === viewModes.PRINT
  const isScreenMode = viewMode === viewModes.SCREEN
  const hasActions = !!actions
  const requiredServiceInsightRoles = (userRoles || []).filter((r) =>
    ['LEAD_CLINICIAN', 'MANAGER'].includes(r),
  )
  const requiredClinicianInsightRoles = (userRoles || []).filter(
    (r) => r === 'CLINICIAN',
  )

  const onRequestIndex = (nextIndex) => {
    setSelectedIndex(nextIndex)
  }

  const onRequestChangeViewMode = (nextViewMode) => {
    setViewMode(nextViewMode)
  }

  // Localization
  const { t: translation } = useTranslation()

  return (
    <Component
      {...withoutFelaProps(props)}
      className={styles.ServiceMetricsPage}
    >
      <div className={styles.Wrapper}>
        <Container>
          <SimpleHeading text={translation('insights')} />
        </Container>
        {requiredServiceInsightRoles.length > 0 &&
          requiredClinicianInsightRoles.length > 0 && (
            <Container className={styles.reportViewContainer}>
              {canAccess(userRoles, ['CLINICIAN']) && (
                <a
                  href="/insights/CLIENT_INSIGHT"
                  className={
                    reportView === 'CLIENT_INSIGHT'
                      ? styles.reportViewActionActive
                      : styles.reportViewAction
                  }
                >
                  {translation('client_insight')}
                </a>
              )}
              {canAccess(userRoles, ['LEAD_CLINICIAN', 'MANAGER']) && (
                <a
                  href="/insights/SERVICE_INSIGHT"
                  className={
                    reportView === 'SERVICE_INSIGHT'
                      ? styles.reportViewActionActive
                      : styles.reportViewAction
                  }
                >
                  {translation('service_insight')}
                </a>
              )}
            </Container>
          )}
      </div>

      <Layout2Col
        extend={(...args) => ({
          article: rules.article(...args),
          articleContent: rules.articleContent(...args),
          aside: rules.aside(...args),
          asideContent: rules.asideContent(...args),
        })}
        asideColSpan={asideColSpan}
        aside={
          <>
            <Menu key="menu">
              {isScreenMode && (
                <div className={styles.ButtonWrapper}>
                  <Button
                    key="print-mode"
                    variant="secondary"
                    onClick={() => onRequestChangeViewMode('print')}
                    dataTestId="view-all-for-printing"
                  >
                    {translation('view_all_for_printing')}
                  </Button>
                </div>
              )}
              {isPrintMode && (
                <div className={styles.ButtonWrapper}>
                  <Button
                    key="screen-mode"
                    variant="secondary"
                    onClick={() => onRequestChangeViewMode('screen')}
                    dataTestId="exit-print-view"
                  >
                    {translation('exit_print_view')}
                  </Button>
                </div>
              )}
              {Children.map(children, (child, index) => (
                <MenuItemWithBox
                  as="a"
                  href={child.props.navHash}
                  active={isScreenMode && selectedIndex === index}
                  label={child.props.title}
                  selected={selectedIndex === index}
                  onClick={() => onRequestIndex(index)}
                />
              ))}
            </Menu>
          </>
        }
      >
        {hasActions && <header className={styles.actions}>{actions}</header>}

        {isScreenMode && (
          <>
            <Heading level={3} margin="sm" className={styles.heading}>
              {children[selectedIndex].props.title}
            </Heading>
            <ServiceMetricsDefinitions />
          </>
        )}

        {isPrintMode && <ServiceMetricsDefinitions />}

        <ViewStack print={isPrintMode} selectedIndex={selectedIndex}>
          {children}
        </ViewStack>
      </Layout2Col>
    </Component>
  )
}

export default connect(styleRules)(ServiceMetricsPage)
