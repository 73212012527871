// @flow

import React, { Fragment } from 'react'
import type { TFunction } from 'react-i18next'

const IndividualQuestions = (translation: TFunction) => [
  {
    key: 'platformBenefits',
    question: (
      <Fragment> {translation('how_does_innowell_benefit_you')} </Fragment>
    ),
    answer: (
      <Fragment>
        {translation(
          'innowell_is_a_personalised_interactive_health_dashboard_that_brings_you_your_health_data_and_your_health_professional_together',
        )}
        <br />
        {translation(
          'innowell_leads_you_through_an_intake_process_with_your_health_service_which_includes_an_initial_health_assessment',
        )}
        <br />
        {translation(
          'this_provides_the_data_for_your_dashboard_giving_you_access_to_results_health_information_and_care_options_through_your_innowell_dashboard_you_can_share_your_information_with_your_health_professional_work_together_to_prioritise_and_tailor_care_around_your_needs_and_achieve_your_wellness_goals',
        )}
      </Fragment>
    ),
  },
  {
    key: 'whoCanUse',
    question: <Fragment>{translation('who_can_use_innowell')}</Fragment>,
    answer: (
      <Fragment>
        {translation(
          'innowell_is_used_by_you_your_health_service_and_your_health_professional_your_health_service_helps_coordinate_care_so_that_you_and_your_health_professional_can_focus_on_your_personal_health_and_wellbeing_your_health_professional_sees_your_dashboard_reviews_your_data_and_monitors_your_progress_this_assures_they_can_tailor_care_and_provide_you_with_the_best_treatment_options',
        )}
      </Fragment>
    ),
  },
  {
    key: 'whatDevices',
    question: <Fragment>{translation('what_devices_can_you_use')}</Fragment>,
    answer: (
      <Fragment>
        {translation(
          'innowell_works_on_any_device_login_to_your_dashboard_from_anywhere_and_put_your_mental_health_front_and_centre',
        )}
      </Fragment>
    ),
  },
  {
    key: 'createAccount',
    question: (
      <Fragment>
        {' '}
        {translation('how_do_you_create_your_innowell_account')}{' '}
      </Fragment>
    ),
    answer: (
      <Fragment>
        {translation(
          'your_health_provider_will_send_an_email_invitation_with_a_link_the_link_will_take_you_to_innowell_where_you_create_your_account_and_set_up_your_dashboard_there_are_three_steps_to_get_started',
        )}
        <ol>
          <li>{translation('set_up_your_user_name_and_password')}</li>
          <li>{translation('read_and_accept_the_terms_of_use')}</li>
          <li>
            {translation(
              'complete_the_onboarding_questions_and_the_initial_questionnaire',
            )}
          </li>
        </ol>
      </Fragment>
    ),
  },
  {
    key: 'initialQuestionnaireWhat',
    question: (
      <Fragment>{translation('what_is_the_initial_questionnaire')}</Fragment>
    ),
    answer: (
      <Fragment>
        {translation(
          'the_initial_questionnaire_is_a_comprehensive_health_assessment_completed_as_part_of_an_intake_process_each_set_of_questions_is_a_specific_collection_of_validated_psychometric_tools_that_are_used_to_provide_real_time_data_that_populates_into_your_personal_dashboard_this_initial_data_set_helps_set_the_stage_for_you_to_make_decisions_about_your_health_needs_and_priorities_and_to_work_together_with_your_clinician_in_ongoing_care',
        )}
      </Fragment>
    ),
  },
  {
    key: 'whyUpdateDashboard',
    question: (
      <Fragment>
        {' '}
        {translation('why_should_you_update_your_dashboard')}{' '}
      </Fragment>
    ),
    answer: (
      <Fragment>
        {translation(
          'as_your_story_and_circumstances_change_so_too_should_your_dashboard_your_summary_dashboard_and_health_card_responses_can_be_regularly_updated_to_reflect_where_you_are_at_today_you_can_view_all_your_previous_responses_in_summary_and_graph_form_and_you_can_monitor_your_progress_over_time_when_you_re_speaking_with_your_health_professional_you_can_discuss_your_updates_review_your_responses_and_make_care_decisions_together',
        )}
      </Fragment>
    ),
  },
  {
    key: 'whoContribuesToDasjboard',
    question: (
      <Fragment>
        {' '}
        {translation('who_can_contribute_to_your_summary_dashboard')}{' '}
      </Fragment>
    ),
    answer: (
      <Fragment>
        {translation(
          'your_health_professional_can_contribute_to_your_summary_dashboard_when_you_invite_a_support_person_they_can_also_contribute_they_answer_a_series_of_questions_linked_to_your_summary_dashboard_adding_to_the_overall_impression_of_your_health_and_wellbeing',
        )}
      </Fragment>
    ),
  },
  {
    key: 'supportPerson',
    question: <Fragment> {translation('who_is_a_support_person')} </Fragment>,
    answer: (
      <Fragment>
        {translation(
          'a_support_person_can_be_a_friend_partner_mentor_or_family_member_they_are_someone_who',
        )}
        <ul>
          <li>{translation('you_trust')}</li>
          <li>
            {translation(
              'can_provide_an_honest_update_on_your_overall_health_and_wellbeing',
            )}
          </li>
          <li>
            {translation('is_able_to_provide_you_with_their_perspective')}
          </li>
        </ul>
        {translation(
          'you_can_invite_a_support_person_and_you_can_remove_a_support_person_from_your_dashboard_at_anytime_a_support_person_can_answer_a_series_of_questions_on_areas_of_health_linked_to_your_summary_dashboard',
        )}
        <br />
        <strong>
          {translation(
            'they_cannot_see_your_dashboard_unless_you_choose_to_show_it_to_them_from_your_own_device',
          )}{' '}
        </strong>
      </Fragment>
    ),
  },
]

export default IndividualQuestions
