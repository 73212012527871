// @flow

import { graphql } from 'react-relay'

export const individualsTablePaginatedQuery = graphql`
  query IndividualsTable_PaginatedQuery(
    $count: Int!
    $cursor: String
    $search: String
    $status: [String!]
    $sort: [RoleSortable!]
    $assigned: Boolean
    $prioritise: Boolean
    $helpRequested: Boolean
    $filterEscalation: Boolean
    $scoresTable: Boolean!
    $standardTable: Boolean!
  ) {
    viewer {
      currentUser {
        ...IndividualsTable_user
          @arguments(scoresTable: $scoresTable, standardTable: $standardTable)
      }
    }
  }
`

export const individualsTableFragments = {
  user: graphql`
    fragment IndividualsTable_user on User
      @refetchable(queryName: "IndividualsTableRefetchQuery")
      @argumentDefinitions(
        scoresTable: { type: "Boolean!" }
        standardTable: { type: "Boolean!" }
      ) {
      id
      individuals_count(
        filters: {
          roleStatus: $status
          search: $search
          sort: $sort
          assignedToMe: $assigned
          prioritiseEscalations: $prioritise
          helpRequested: $helpRequested
          filterEscalation: $filterEscalation
        }
      )
      staff_individuals_page_actions {
        scores
        standard
      }
      tenant @include(if: $scoresTable) {
        id
        important_formulas(kind: individual) {
          id
          kind
          label
          sequence
          formula_id
          formula {
            id
            label
          }
          tenant {
            id
            label
          }
        }
      }
      individuals(
        first: $count
        after: $cursor
        filters: {
          roleStatus: $status
          search: $search
          sort: $sort
          assignedToMe: $assigned
          prioritiseEscalations: $prioritise
          helpRequested: $helpRequested
          filterEscalation: $filterEscalation
        }
      ) @connection(key: "Individuals_currentUser_individuals") {
        edges {
          questionnaireDueAt
          questionnaireLastAnsweredAt
          clinicianPreviouslyProvidedFeedback
          clinicianCanProvideFeedback
          assignmentStatus

          node {
            do_with_clinician {
              requested
            }
            ...IndividualsTable_individual_scores
              @include(if: $scoresTable)
              @relay(mask: false)
            ...IndividualsTable_individual
              @include(if: $standardTable)
              @relay(mask: false)
          }
        }
      }
    }
  `,
}

graphql`
  fragment IndividualsTable_individual_scores on IndividualRole {
    ...EditEmrModal_individual
    duty_of_care_clinicians: clinicians {
      ...AvatarListLoader_data @relay(mask: false)
    }
    do_with_clinician {
      requested
      question_sets {
        id
        label
      }
    }
    escalated
    id
    important_formula_results {
      id
      time_since_updated
      last_updated_at
      formula {
        id
        label
      }
      formula_range {
        label
        value
      }
      trending {
        label
      }
    }
    initial_questionnaire_status
    ...IndividualCliniciansList_individual
    clinicians {
      ...AvatarListLoader_data
    }
    role_status
    removed_at
    tenant {
      stratified_scoring
      summary_questionnaire {
        answerable
        id
      }
      emr_status
      emr_integration {
        emr_provider {
          user_id_field_name
        }
      }
    }
    user {
      ...UserActivityCardLoader_user
      ...ClinicianNoteActivity_user
      id
      email
      name
      legal_name
      invitation_delta
      preferred_name
      last_seen_at
      invitation_created_at
      invitation_expired
      invitation_requested_at
      invitation_accepted
      invitation_accepted_at
      personal_details {
        date_of_birth
      }
      stratified_scoring_result {
        status
        cluster_probabilities {
          label
          probability
        }
      }
    }
    canBecomeActive
  }
`

graphql`
  fragment IndividualsTable_individual on IndividualRole {
    ...EditEmrModal_individual
    do_with_clinician {
      requested
      question_sets {
        id
        label
      }
    }
    duty_of_care_clinicians: clinicians {
      ...AvatarListLoader_data @relay(mask: false)
    }
    escalated
    id
    individual_detail {
      id
      emr_user_id
      discharged_at
    }
    initial_questionnaire_status
    ...IndividualCliniciansList_individual
    clinicians {
      ...AvatarListLoader_data
    }
    role_status
    removed_at
    tenant {
      stratified_scoring
      summary_questionnaire {
        answerable
        id
      }
      emr_status
      emr_integration {
        emr_provider {
          user_id_field_name
        }
      }
    }
    user {
      ...UserActivityCardLoader_user
      id
      email
      name
      legal_name
      invitation_delta
      preferred_name
      last_seen_at
      invitation_created_at
      invitation_expired
      invitation_requested_at
      invitation_accepted
      invitation_accepted_at
      personal_details {
        date_of_birth
      }
      stratified_scoring_result {
        status
        cluster_probabilities {
          label
          probability
        }
      }
    }
    canBecomeActive
  }
`

graphql`
  fragment IndividualsTable_individual_mutation on IndividualRole {
    id
    role_status
  }
`
