// @flow

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { graphql } from 'react-relay'
import { get } from 'lodash/fp'

import { DonutSummary as DonutSummaryChart } from 'react-ui/components/Charts'
import ReportCard from 'react-ui/components/ReportCard'

import ReportWithSettings from '../reports/ReportWithSettings'

type PropsType = {
  +dimensions: $ReadOnlyArray<{
    +key: string,
    +value: string,
  }>,
  +variables: Object,
}

const query = graphql`
  query AccessibilityBreakdownChartQuery(
    $from: DateTime!
    $to: DateTime!
    $tenants: [ID!]!
    $clinicians: [ID!]!
    $dimension: ReportsAccessibilityBreakdownDimensionEnum!
    $report_view: ReportView
  ) {
    viewer {
      reports {
        accessibility_breakdown(
          dimension: $dimension
          from: $from
          to: $to
          tenants: $tenants
          clinicians: $clinicians
          report_view: $report_view
        ) {
          traces {
            name
            type
            x
            y
            total
          }
        }
      }
    }
  }
`

const dimensionLabelFromKey = ({ dimensions, key }) =>
  get(
    ['value'],
    dimensions.find((dimension) => dimension.key === key),
  ) || 'Diversity'

const AccessibilityBreakdownChart = (props: PropsType) => {
  const { dimensions, variables } = props

  const [selectedDimension, setSelectedDimension] = useState(dimensions[0].key)
  const [selectedDimensionLabel, setSelectedDimensionLabel] = useState(
    dimensions[0].value,
  )

  const allVariables = {
    ...variables,
    dimension: selectedDimension,
  }

  const onChangeDimension = (evt) => {
    setSelectedDimension(evt.target.value)
    setSelectedDimensionLabel(
      dimensionLabelFromKey({ dimensions, key: evt.target.value }),
    )
  }

  // Localization
  const { t: translation } = useTranslation()

  return (
    <ReportWithSettings query={query} variables={allVariables}>
      {(data) => {
        const { accessibility_breakdown } = data.viewer.reports
        return (
          <ReportCard
            title={`${selectedDimensionLabel} ${translation('breakdown')}`}
          >
            <DonutSummaryChart
              traces={accessibility_breakdown.traces}
              title={translation('total_individuals')}
            >
              {({ donut, table }) => (
                <>
                  {donut}
                  <br />
                  {/*
                   this rule is disabled as there is a smaller need atm for this and the effort to get it working
                   correctly is estimated high
                   */}
                  {/* eslint-disable-next-line jsx-a11y/no-onchange */}
                  <select
                    onChange={onChangeDimension}
                    value={selectedDimension}
                  >
                    {dimensions.map(({ key, value }) => (
                      <option key={key} value={key}>
                        {value}
                      </option>
                    ))}
                  </select>
                  {table}
                </>
              )}
            </DonutSummaryChart>
          </ReportCard>
        )
      }}
    </ReportWithSettings>
  )
}

export default AccessibilityBreakdownChart
