// @flow

import React, { useEffect, useState } from 'react'
import type { TFunction } from 'react-i18next'
import { useTranslation } from 'react-i18next'
import { startCase, toLower } from 'lodash'
import { type GraphQLTaggedNode } from 'relay-runtime'

import type { UseMultiSelectReturnTypes } from 'platform_web/pages/Staff/hooks/useMultiSelect'
import RemoveRolesMutation from 'platform_web/pages/Staff/mutations/RemoveRolesMutation'
import ResendRolesMutation from 'platform_web/pages/Staff/mutations/ResendRolesMutation'
import { Button, DropDownMenu, DropDownMenuItem, FlexContainer } from 'care-ui'
import { ChevronDownIcon } from 'care-ui/atoms/icons-new'
import useModal from 'care-ui/molecules/hooks/useModal'
import TextNew from 'care-ui/molecules/TextNew/TextNew'

import type { QueryVariablesType } from '../../hooks/useEmployeesTable'
import RemoveEmployeesModal from '../RemoveEmployeesModal'
import ResendEmployeesModal from '../ResendEmployeesModal'

const getActionLabel = (label, roleType, translation) => {
  const formattedAction =
    label === translation('remove')
      ? translation('role')
      : translation('invitation')

  return `${label} ${startCase(toLower(roleType))} ${formattedAction}`
}

type actionButtonGroupsType = {
  translation: TFunction,
}

type ActionButtonType = {
  action: string,
  disabled: boolean,
  label: string,
  modalComponent: any,
  mutationQuery: GraphQLTaggedNode,
}

const buildActionButtonGroups = (
  params: actionButtonGroupsType,
): ActionButtonType[] => {
  const { translation } = params

  return [
    {
      action: 'removeRole',
      label: translation('remove'),
      disabled: false,
      modalComponent: RemoveEmployeesModal,
      mutationQuery: RemoveRolesMutation,
    },
    {
      action: 'resendRoleInvitation',
      label: translation('resend'),
      disabled: false,
      modalComponent: ResendEmployeesModal,
      mutationQuery: ResendRolesMutation,
    },
  ]
}

type MultiSelectActionsProps = {
  actions: Array<string>,
  multiSelectPayload: UseMultiSelectReturnTypes,
  queryVariables: QueryVariablesType,
  selectedUsers: Array<{ roles: Array<any>, userId: string }>,
}

const MultiSelectActions = (props: MultiSelectActionsProps) => {
  const { actions, selectedUsers, multiSelectPayload, queryVariables } = props
  // For Employees Actions, we get all selected users and their roles
  // Step 1: Extract the role_types for each user
  const roleTypesPerUser = selectedUsers?.map((user) =>
    user.roles?.map((role) => role.role_type),
  )

  // Step 2: Find the intersection of all users' role_types
  // But it all-selected, the common role types comes from BE
  const {
    allSelected,
    deselectedRoleIds,
    commonRoleTypes: commonRoleTypesBE,
  } = multiSelectPayload

  const commonRoleTypesFE = roleTypesPerUser
    ?.reduce(
      (acc, roleTypes) => acc.filter((role) => roleTypes.includes(role)),
      roleTypesPerUser[0],
    )
    ?.filter(Boolean)

  const commonRoleTypes =
    allSelected && !deselectedRoleIds.length
      ? commonRoleTypesBE
      : commonRoleTypesFE

  // Translation
  const { t: translation } = useTranslation()

  const { isOpen, closeModal, openModal } = useModal()
  // prettier-ignore
  const [actionType, setActionType]= useState<string>('')
  // prettier-ignore
  const [actionedRoleType, setActionedRoleType] = useState<string>('')

  useEffect(() => {
    if (actionType) {
      openModal()
    }
  }, [actionType])

  const handleButtonClick = (action, roleType) => {
    setActionedRoleType(roleType)
    setActionType(action)
  }

  const handleCloseModal = () => {
    closeModal()
    setActionType('')
  }

  const actionButtonGroups = buildActionButtonGroups({ translation })

  const actionButton = actionButtonGroups.find(
    (button) => button.action === actionType,
  )
  const ModalComponent = actionButton ? actionButton.modalComponent : null

  // Empty State
  if (!commonRoleTypes?.length) {
    return (
      <TextNew as="p" typography="bodyMd">
        {translation(
          'no_common_actions_ensure_selected_rows_have_a_common_role',
        )}
      </TextNew>
    )
  }

  // Dropdown
  if (commonRoleTypes.length > 1) {
    return (
      <>
        {ModalComponent && (
          <ModalComponent
            closeModal={handleCloseModal}
            isOpen={isOpen}
            multiSelectPayload={multiSelectPayload}
            mutationQuery={actionButton?.mutationQuery}
            queryVariables={queryVariables}
            actionedRoleType={actionedRoleType}
          />
        )}

        <FlexContainer paddingX="xxs" gap="xxxs">
          {actions.map((action) => {
            const actionBtn = actionButtonGroups.find(
              (action_button) => action_button.action === action,
            )
            const { label, disabled } = actionBtn || {}

            return (
              <DropDownMenu
                dropDownTrigger={
                  <Button variant="primary" rightIcon={ChevronDownIcon}>
                    {label === translation('remove')
                      ? translation('remove_role')
                      : translation('resend_invitation')}
                  </Button>
                }
              >
                {commonRoleTypes?.map((roleType) => (
                  <DropDownMenuItem
                    key={roleType}
                    onClick={
                      !disabled
                        ? () => handleButtonClick(action, roleType)
                        : undefined
                    }
                  >
                    {getActionLabel(label, roleType, translation)}
                  </DropDownMenuItem>
                ))}
              </DropDownMenu>
            )
          })}
        </FlexContainer>
      </>
    )
  }

  return (
    <>
      {ModalComponent && (
        <ModalComponent
          closeModal={handleCloseModal}
          isOpen={isOpen}
          multiSelectPayload={multiSelectPayload}
          mutationQuery={actionButton?.mutationQuery}
          queryVariables={queryVariables}
          actionedRoleType={actionedRoleType}
        />
      )}

      <FlexContainer paddingX="xxs" gap="xxxs">
        {actionButtonGroups.map(({ action, label, disabled }) => {
          if (!actions.includes(action)) {
            return null
          }

          return (
            <>
              {commonRoleTypes?.map((roleType) => (
                <Button
                  onClick={
                    !disabled
                      ? () => handleButtonClick(action, roleType)
                      : undefined
                  }
                  key={action}
                  variant="primary"
                >
                  {getActionLabel(label, roleType, translation)}
                </Button>
              ))}
            </>
          )
        })}
      </FlexContainer>
    </>
  )
}

export default MultiSelectActions
