// @flow

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Button,
  DropDownMenu,
  DropDownMenuItem,
  FlexContainer,
  RadioButton,
} from 'care-ui'

import type {
  QueryVariablesType,
  UpdateQueryVariablesType,
} from '../../Individuals/hooks/useIndividualTable'

const getFilters = (translation) => [
  {
    value: 'filterEscalation',
    label: translation('stb_notifications'),
  },
  {
    value: 'helpRequested',
    label: translation('help_requested'),
  },
]

type TableFilterControlProps = {
  queryVariables: QueryVariablesType,
  updateQueryVariables: UpdateQueryVariablesType,
}

const TableFilterControl = (props: TableFilterControlProps) => {
  const { updateQueryVariables } = props

  const [closeDropDown, setCloseDropDown] = useState(true)
  const [selectedFilter, setSelectedFilter] = useState()

  const handleSubmit = () => {
    setCloseDropDown(true)

    // Two hardcoded FILTERS, either one of them is set true for filter to work
    // filterEscalation
    // helpRequested
    updateQueryVariables({
      filterEscalation: selectedFilter === 'filterEscalation',
      helpRequested: selectedFilter === 'helpRequested',
    })
  }

  // Localization
  const { t: translation } = useTranslation()

  const FILTERS = getFilters(translation)

  return (
    <DropDownMenu
      closeDropDown={closeDropDown}
      setCloseDropDown={setCloseDropDown}
      dropDownTrigger={
        <Button
          variant="secondary"
          ariaLabel="filter"
          dataTestId="filter"
          onClick={() => setCloseDropDown(!closeDropDown)}
        >
          {translation('filter')}
        </Button>
      }
    >
      <FlexContainer direction="column" gap="sm">
        <div>
          {FILTERS.map((option) => (
            <DropDownMenuItem
              key={option.value}
              onClick={() => setSelectedFilter(option.value)}
            >
              <RadioButton
                inputAttributes={{
                  id: option.value,
                  name: 'filterBy',
                  value: option.value,
                  label: option.label,
                  checked: selectedFilter === option.value,
                }}
                dataTestId="sortOptionLabel"
                ariaLabel={option.value}
              />
            </DropDownMenuItem>
          ))}
        </div>

        <Button
          disabled={!selectedFilter}
          onClick={() => handleSubmit()}
          variant="primary"
          dataTestId="apply"
        >
          {translation('apply')}
        </Button>
      </FlexContainer>
    </DropDownMenu>
  )
}

export default TableFilterControl
