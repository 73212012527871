// @flow

import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { createFragmentContainer } from 'react-relay'

import { HealthHistoryLoader } from 'components/Dashboard/HealthHistory'
import { MyEscalationsTableLoader } from 'components/Escalation/MyEscalationsTable'
import { WithAuthWrapper } from 'components/hoc/WithAuthWrapper'
import { DownloadReportTaskButton } from 'components/SummaryGraph/'
import Accordion, { AccordionItem } from 'react-ui/components/Accordion'
import Section from 'react-ui/components/Section'

import { containerRules } from './HealthHistoryPage.style'
import { healthHistoryPageUser } from './queries/HealthHistoryPage'

import type { HealthHistoryPage_user } from './queries/__generated__/HealthHistoryPage_user.graphql'

type PropsType = {
  user: HealthHistoryPage_user,
}

const HealthHistoryPage = (props: PropsType) => {
  const {
    user,
    user: { escalations },
  } = props

  const { t: translation } = useTranslation()

  return (
    <>
      <DownloadReportTaskButton />

      <Section color="white" extend={containerRules}>
        <HealthHistoryLoader user={user} />

        <Accordion>
          <AccordionItem
            data-component-id="EscalationHistory"
            heading={translation('notifications_history')}
          >
            <MyEscalationsTableLoader escalations={escalations} />
          </AccordionItem>
        </Accordion>
      </Section>
    </>
  )
}

const HealthHistoryWrapped = WithAuthWrapper(HealthHistoryPage, {
  requiredPolicies: ['CAN_VIEW_OWN_HEALTH_HISTORY'],
})

const HealthHistoryPageLoader = createFragmentContainer(
  HealthHistoryWrapped,
  healthHistoryPageUser,
)

export default HealthHistoryPageLoader
