// @flow

import React from 'react'
import type { TFunction } from 'react-i18next'
import { startCase, toLower } from 'lodash/fp'

import { Input } from 'react-ui/components/Form'
import Select from 'react-ui/components/Select'
import Spacer from 'react-ui/components/Spacer'
import { FormError } from 'shared/ui/Forms'
import { buildLocalizationKey } from 'platform_web/utility/buildLocalizationKey'

import TenantOrTenantGroup from './TenantOrTenantGroup'

import { type FormikProps } from 'services/flow'

const roleTypeItems = (invitableRoleTypes, translation) => {
  const mappedRoleTypes = invitableRoleTypes
    .map((roleType: string) => ({
      key: roleType,
      value: startCase(toLower(translation(buildLocalizationKey(roleType)))),
    }))
    .sort((a, b) => a.value.localeCompare(b.value))

  return mappedRoleTypes
}

type FieldsForIndividualsProps = {
  formProps: FormikProps,
  inputProps: (arg: string) => {},
  translation: TFunction,
}

const FieldsForAllUsers = (props: FieldsForIndividualsProps) => {
  const { inputProps, formProps, translation } = props
  const { errors, invitableRoleTypes = [], touched } = formProps

  return (
    <>
      {/* No spacer needed here as it is the top of the form */}
      <label htmlFor="email">
        <strong>{translation('email')}</strong>
        <Input {...(inputProps('email'): any)} type="email" />
      </label>
      <FormError errors={errors} touched={touched} attr="email" />

      {invitableRoleTypes &&
        (invitableRoleTypes.length > 1 ? (
          <>
            <Spacer units={0.5} />
            <label htmlFor="roleType">
              <strong>{translation('role_type')}</strong>
              <Select
                alwaysShowEmpty={false}
                items={roleTypeItems(invitableRoleTypes, translation)}
                keyName="key"
                {...(inputProps('roleType'): any)}
                valueName="value"
              />
            </label>
            <FormError errors={errors} touched={touched} attr="roleType" />
          </>
        ) : (
          <input type="hidden" name="roleType" value={invitableRoleTypes[0]} />
        ))}

      <TenantOrTenantGroup
        inputProps={inputProps}
        formProps={formProps}
        translation={translation}
      />
    </>
  )
}

export default FieldsForAllUsers
