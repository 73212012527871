// @flow

import * as React from 'react'
import { useFela } from 'react-fela'
import { useTranslation } from 'react-i18next'

import SearchIcon from 'react-ui/assets/icons/search.svg'
import Button from 'react-ui/components/Button'
import { Input } from 'react-ui/components/Form'
import Icon from 'react-ui/components/Icon'
import MatchMedia from 'react-ui/components/MatchMedia'
import {
  type DataType,
  type FieldsType,
  filterBySearchTerm,
} from 'platform_web/services/filter'

export type ResultType<T> = {
  data: T,
  query?: string,
}

type SearchBoxType = {
  input?: string,
  onButtonClick: () => {},
  onSearchChange: () => {},
  query?: string,
}

type ExternalPropsType = {
  children: (result: ResultType<Object>) => React.Node,
  data: DataType,
  fields: FieldsType,
  placeholder?: string,
  renderSearchBox?: (result: SearchBoxType) => React.Node,
}

type InternalPropsType = ExternalPropsType & {
  renderSearchBox: any,
}

const searchWrapperStyleRules = () => ({
  display: 'inline-flex',
  marginTop: '30px',
})

const buttonStyleRules = () => ({
  marginLeft: '-10%',
  zIndex: '0',
  background: '#000000 0% 0% no-repeat padding-box !important',
  border: '1px solid #707070 !important',
  borderRadius: '50px',
  height: '38px',
  font: 'normal normal medium 14px/19px',
  fontSize: '14px !important',
  ':hover': {
    backgroundColor: '#1E68C3 !important',
  },
})

const inputStyleRules = () => ({
  borderRadius: '50px',
  zIndex: '-1',
  width: '319px',
  background: '#FFFFFF 0% 0% no-repeat padding-box !important',
  border: '1px solid #707070 !important',
  height: '38px !important',
  minHeight: '38px !important',
  paddingLeft: '14px',
  paddingRight: '10px',
  font: 'normal normal medium 14px/17px',
  fontSize: '14px !important',
  maxWidth: '319px',
  ':focus': {
    outline: 'none !important',
  },
})

const Search = ({
  data: initialData,
  fields,
  renderSearchBox,
  placeholder = 'Search...',
  children,
}: InternalPropsType) => {
  const { t: translation } = useTranslation()
  const [query, setQuery] = React.useState('')
  const [input, setInput] = React.useState('')

  const onButtonClick = (e) => {
    if (e) {
      e.preventDefault()
    }

    setQuery(input)
  }

  const onSearchChange = (e) => {
    if (e) {
      e.preventDefault()
    }

    setInput(e.target.value)
  }

  const data = filterBySearchTerm(initialData, query, { include: fields })
  const { css } = useFela()

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      onButtonClick()
    }
  }

  return (
    <React.Fragment>
      {renderSearchBox ? (
        renderSearchBox({ query, onSearchChange })
      ) : (
        <div className={css(searchWrapperStyleRules)}>
          <Input
            className={css(inputStyleRules)}
            placeholder={placeholder}
            id="searchInput"
            value={input}
            onChange={onSearchChange}
            onKeyDown={handleKeyDown}
          />
          <MatchMedia>
            {({ sm }) => (
              <Button
                type="submit"
                componentId="searchButton"
                className={css(buttonStyleRules)}
                shape="pill"
                onClick={onButtonClick}
              >
                {sm ? (
                  <div>
                    <Icon as={SearchIcon} scale={0.5} /> {translation('search')}
                  </div>
                ) : (
                  <Icon as={SearchIcon} scale={0.5} />
                )}
              </Button>
            )}
          </MatchMedia>
        </div>
      )}
      {children({ data, query })}
    </React.Fragment>
  )
}

export default Search
