// @flow

/*
 * Set of options for Higcharts configuration object
 */
import { useTranslation } from 'react-i18next'
import Highcharts from 'highcharts'
import { sortBy } from 'lodash'

import { MILLIS_PER_HOUR } from 'services/dateTime'

import {
  generateIndex,
  generateInvertedIndex,
  getBiggestKey,
  getSmallestKey,
} from './helpers'

export const chart = {
  animation: false,
  type: 'spline',
  spacingTop: 20,
  ignoreHiddenSeries: false,
}

export type formulaRangesType = $ReadOnlyArray<{
  label: string,
  value: number,
}>

export const yAxisLine = (sortedFormulas: formulaRangesType) => {
  const categories =
    sortedFormulas && sortedFormulas.map((formula) => formula.label)
  const values =
    sortedFormulas && sortedFormulas.map((formula) => formula.value)

  const labelIndex = generateIndex(sortedFormulas)
  const labelInverted = generateInvertedIndex(sortedFormulas)
  const smallestKey = getSmallestKey(labelIndex)
  const biggestKey = getBiggestKey(labelIndex)

  return {
    labels: {
      formatter() {
        return labelInverted[this.value]
      },
      // Need to explicitly set `x` to the default due to a bug in Highcharts:
      // https://github.com/highcharts/highcharts/issues/11458
      x: -15,
      style: {
        fontSize: '14px',
        fontFamily: 'Raleway',
      },
    },
    tickPositioner() {
      return sortBy(values)
    },
    crosshair: true,
    showEmpty: true,
    tickmarkPlacement: 'on',
    title: { enabled: false },
    min: smallestKey,
    max: biggestKey,
    tickAmount: categories.length,
  }
}

export const tooltip = (options: Object) => ({
  tooltip: {
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    borderColor: 'transparent',
    style: {
      color: 'white',
      fontSize: '14px',
    },
    shared: true,
    useHTML: true,
    headerFormat: '<span>{point.key}</span>',
    pointFormat: '<div> {series.name} {point.y}</div>',
    footerFormat: '</div>',
    valueDecimals: 0,
    ...options,
  },
})

export const Basic = () => {
  const { t } = useTranslation()

  return {
    credits: { enabled: false },
    lang: { noData: t('no_results_for_this_time_period') },
  }
}

const textOutline = (color) => {
  const dist = '2px'
  return {
    textShadow: `
    -${dist} -${dist} ${color},
    -${dist}  ${dist} ${color},
     ${dist} -${dist} ${color},
     ${dist}  ${dist} ${color}`,
  }
}

export const chartTitle = (title?: string) => ({
  title: { text: title || '' },
})

export type DateTimeAxisType = {
  localEndDate: Date,
  localStartDate: Date,
  selectedFilter: 'Week' | 'Month' | 'Quarter' | 'Biannual',
  utcEndDate: Date,
  utcStartDate: Date,
}

Highcharts.setOptions({
  lang: {
    thousandsSep: ',',
  },
})

// Add a format option for first letter of the day of the week ('M', 'T', etc.)
Highcharts.dateFormats['0'] = (timestamp) =>
  Highcharts.dateFormat('%a', timestamp)[0]

const xAxisFormats = {
  Week: {
    units: [['day', [1]]],
    dateTimeLabelFormats: {
      day: '%0', // Custom format defined above
    },
  },
  Month: {
    units: [['day', [7]]], // using week will force the tick on a monday. In month view we care more about 7 days of data then a calender week of data.
    dateTimeLabelFormats: {
      week: '%e %b %y',
    },
  },
  Quarter: {
    units: [['month', [1]]],
    dateTimeLabelFormats: {
      month: '%e %b %y',
    },
  },
  Biannual: {
    units: [['month', [1]]],
    dateTimeLabelFormats: {
      month: '%e %b %y',
    },
  },
}

export const dateTimeAxis = (dateRange: DateTimeAxisType) => {
  const xAxisByFilter = xAxisFormats[dateRange.selectedFilter] || {}
  return {
    // Add half a day at the start of the axis to provide enough space for columns to render
    min: dateRange.utcStartDate.getTime() - MILLIS_PER_HOUR * 12,
    max: dateRange.utcEndDate.getTime(),
    type: 'datetime',
    labels: {
      y: 25,
      style: {
        fontSize: '14px',
        fontFamily: 'Raleway',
      },
    },
    ...xAxisByFilter,
  }
}

const responsiveLeftAxisRules = {
  annotations: {
    align: 'left',
    x: -10,
    y: -5,
  },
  title: { text: null },
  style: {
    ...textOutline('white'),
  },
}

const responsiveRightAxisRules = {
  annotations: {
    align: 'right',
    x: -10,
    y: -5,
  },
  title: { text: null },
  style: {
    ...textOutline('white'),
  },
}

export const responsiveRules = {
  responsive: {
    rules: [
      {
        condition: { maxWidth: 500 },
        chartOptions: {
          yAxis: [responsiveLeftAxisRules, responsiveRightAxisRules],
          subtitle: { text: null },
        },
      },
    ],
  },
}

export const stepsOptions = {
  gridLineColor: 'transparent',
  title: {
    text: 'Steps',
    enabled: false,
  },
  annotations: {
    format: '{value:,.0f} steps',
    style: {
      color: '#485465',
    },
    // Need to explicitly set `x` to the default due to a bug in Highcharts:
    // https://github.com/highcharts/highcharts/issues/11458
    x: 5,
  },
  min: 0,
  max: 30000,
}

export const colorSelector = {
  SUPPORT_PERSON: '#0A77D9',
  INDIVIDUAL: '#BF00B6',
  CLINICIAN: '#008479',
}
