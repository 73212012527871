// @flow

import React from 'react'
import { useFela } from 'react-fela'
import { useTranslation } from 'react-i18next'
import { useFragment } from 'react-relay'
import { uniqueId } from 'lodash/fp'

import InfoCircle from 'react-ui/assets/icons/information-circle.svg'
import Team from 'react-ui/assets/icons/team.svg'
import Markdown from 'react-ui/components/Markdown'
import QuestionnaireInfoCard from 'react-ui/components/QuestionnaireInfoCard'

import { assignmentFragment } from '../../queries/SupportingSupporteeInfoWrapper'
import SupportingSupporteeRemoveModal from '../SupportingSupporteeRemoveModal'

import {
  displayInline,
  markdownStyle,
} from './SupportingSupporteeInfoWrapper.style'

import type { SupportingSupporteeInfoWrapper_individual_assignments$key } from '../../queries/__generated__/SupportingSupporteeInfoWrapper_individual_assignments.graphql'

type PropsType = {
  individual_assignments: SupportingSupporteeInfoWrapper_individual_assignments$key,
}

const SupportingSupporteeInfoWrapper = (props: PropsType) => {
  const { individual_assignments: assignmentsFragmentRef } = props

  const { css } = useFela()
  const individualAssignments = useFragment(
    assignmentFragment,
    assignmentsFragmentRef,
  )

  const { t: translation } = useTranslation()

  const supportPersonHelpList = individualAssignments.map(
    ({ individual }, index) => {
      const { legal_name, tenant } = individual
      const { support_person_help } = tenant || {}

      return (
        support_person_help && (
          <span key={uniqueId('support-person-help-list-')}>
            {index !== 0 && <span>, {translation('or')} </span>}
            <Markdown
              source={support_person_help}
              className={css(markdownStyle)}
              data-testid="websiteLinks"
            />
            {individualAssignments.length > 1 && (
              <span>
                {' '}
                ({translation('for')} {legal_name})
              </span>
            )}
          </span>
        )
      )
    },
  )
  const firstContent = [
    translation(
      'this_questionnaire_will_help_provide_an_additional_perspective_on_the_health_and_well_being_of_the_person_you_are_supporting',
    ),
    translation(
      'your_answers_will_only_be_seen_by_the_person_you_are_supporting_and_their_connected_clinicians',
    ),
  ]

  const contactOptions = (
    <>
      {translation(
        'theres_no_right_or_wrong_way_to_go_about_supporting_someone_often_the_first_step_is_having_an_open_conversation_while_this_can_be_difficult_to_do_check_out_the',
      )}
      &nbsp;
      {supportPersonHelpList}
      &nbsp;
      {translation('website_to_help_you_plan_better_for_these_conversations')}
    </>
  )

  const customNote = (
    <SupportingSupporteeRemoveModal
      individualAssignments={individualAssignments}
    />
  )

  return (
    <div className={css(displayInline)}>
      <QuestionnaireInfoCard
        title={translation('important_information')}
        points={firstContent}
        avatar={Team}
      />

      <QuestionnaireInfoCard
        title={translation('what_else_can_i_do_now')}
        points={[contactOptions]}
        avatar={InfoCircle}
        customNote={customNote}
      />
    </div>
  )
}

export default SupportingSupporteeInfoWrapper
