// @flow

import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-fela'
import { useTranslation } from 'react-i18next'
import { getDaysInMonth } from 'date-fns'
import startCase from 'lodash/startCase'

import { FormError, Input } from 'react-ui/components/Form'
import { localToUtc, longDateFormat } from 'services/dateTime'

import type { FelaPropsType } from 'react-ui/typing'

type PropsType = FelaPropsType & {
  id: string,
  maxDate?: Date,
  minDate?: Date,
  onChange: (value?: Date) => void,
  value?: Date,
}

const styleRules = ({
  theme: {
    Font,
    Grid: { gutter },
  },
}) => {
  const webkitOverride = {
    '-webkit-appearance': 'none',
    margin: 0,
  }
  const InputOveride = {
    '::-webkit-outer-spin-button': webkitOverride,
    '::-webkit-inner-spin-button': webkitOverride,
    '-moz-appearance': 'textfield',
  }
  return {
    label: {
      lineHeight: Font.lineHeight,
      paddingBottom: `calc(${gutter} / 2)`,
    },
    smallInputContainer: {
      width: '64px',
    },
    largeInputContainer: {
      width: '88px',
    },
    smallInputField: {
      width: '64px',
      ...InputOveride,
    },
    largeInputField: {
      width: '88px',
      ...InputOveride,
    },
    span: {
      display: 'flex',
      flexDirection: 'column',
      ':not(:last-child)': {
        paddingRight: gutter,
      },
    },
    DateInput: {
      display: 'flex',
      flexDirection: 'row',
    },
    DateInputWrapper: {
      display: 'flex',
      flexDirection: 'column',
    },
    FieldError: {
      paddingTop: gutter,
    },
  }
}

/**
 * @param fullId: the id of the field that contains the field name and the component id ( ie date_of_birth_day )
 * @return {string}: the name of the field ( ie day )
 */

function getBaseId(fullId) {
  return fullId.split('_').pop()
}

/**
 * @param styles: from fela
 * @param rules: from fela
 * @param value: the initial value as a date - optional
 * @param onChange: the onChange handler. The handler should accept a Date object (when input is correct), or undefined
 * (when input is not yet correct) as a param - required
 * @param maxDate: the maximum date that should be input - optional
 * @param minDate: the minimum date that should be input - optional
 * @param id: a unique string to ensure a unique id for the input fields - required
 *
 * NOTE: This component makes several assumptions
 *   - any 2 digit year is from the last 100 years
 *   - any year must be positive
 *   - max and min dates ( when provided ) are included
 *   - onChange will only be called with a valid date, or to clear when not valid
 *   - a valid date is when the inputs are with "normal" ranges ( ie. month 20 is not normal )
 *   - the returned date will be in UTC. There should be no zone attached to this date
 */

function DateInput({
  styles,
  rules,
  value,
  onChange,
  maxDate,
  minDate,
  id,
}: PropsType) {
  /** Setup of values */

  const { t: translation } = useTranslation()
  const [day, setDay] = useState(value ? value.getDate() : '')
  const [month, setMonth] = useState(value ? value.getMonth() + 1 : '')
  const [year, setYear] = useState(value ? value.getFullYear() : '')

  function allValuesSet() {
    return day !== '' && month !== '' && year !== ''
  }

  /** Store when an input is touched */

  const touched = useRef({ day: false, month: false, year: false })

  /** Setup the error storage and handling */

  const [dayError, setDayError] = useState(null)
  const [monthError, setMonthError] = useState(null)
  const [yearError, setYearError] = useState(null)

  const [dateError, setDateError] = useState(null)

  function currentError() {
    // only show one error at a time
    return dateError || dayError || monthError || yearError
  }

  function hasError() {
    return Boolean(currentError())
  }

  /** Limits on inputs */

  // initially set to 31, then it updates when  month and year is known ( leap years!! )
  let maxDays = 31
  if (month !== '' && year !== '' && !monthError && !yearError) {
    maxDays = getDaysInMonth(new Date(year, month - 1))
  } else {
    maxDays = 31
  }

  // this is to provide some basic limits on the year input
  const defaultMaxYear = new Date().getFullYear() + 149
  const defaultMinYear = new Date().getFullYear() - 149
  const maxYear = maxDate ? maxDate.getFullYear() : defaultMaxYear
  const minYear = minDate ? minDate.getFullYear() : defaultMinYear

  /** Setting of values */

  const updaters = {
    day: setDay,
    month: setMonth,
    year: setYear,
  }

  function handleChange({ target }) {
    let newValue = ''
    if (target.value != null && target.value !== '') {
      newValue = Number.parseInt(target.value, 10)
    }

    updaters[getBaseId(target.id)](newValue)
  }

  /** Validation of each input */

  function validate() {
    // these are defined here as the values change
    const values = { day, month, year }
    const errorSetters = {
      day: [dayError, setDayError, 1, maxDays],
      month: [monthError, setMonthError, 1, 12],
      year: [yearError, setYearError, minYear, maxYear],
    }

    Object.entries(errorSetters).forEach(
      // [currentFieldError, setter, lower, upper] is a tuple, but it is incapable with mixed type of forEach
      // $DisableFlow
      ([field, [currentFieldError, setFieldError, lower, upper]]) => {
        const currentValue = values[field]
        let error = null

        if (!touched.current[field]) return // Don't show errors for untouched fields

        const displayField = startCase(field)

        if (currentValue === '') {
          error = `${displayField} ${translation('is_required')}`
        } else {
          if (lower && currentValue < lower) {
            error = `${displayField} ${translation('must_be_above')} ${
              lower - 1
            }`
          }

          if (upper && currentValue > upper) {
            error = `${displayField} ${translation('must_be_below')} ${
              upper + 1
            }`
          }
        }

        if (currentFieldError !== error) setFieldError(error)
      },
    )

    if (allValuesSet()) {
      /** Validate Date is within range (if given) */
      // $DisableFlow year month and day exist if allValuesSet is true
      const dateInput = new Date(year, month - 1, day)
      if (maxDate && dateInput.getTime() > maxDate.getTime()) {
        setDateError(
          `${translation('date_must_be_on_or_before')} ${longDateFormat(
            maxDate,
          )}`,
        )
      } else if (minDate && dateInput.getTime() < minDate.getTime()) {
        setDateError(
          `${translation('date_must_be_on_or_after')} ${longDateFormat(
            minDate,
          )}`,
        )
      } else if (dateError != null) {
        setDateError(null)
      }
    }
  }

  // validation on render only occurs if the input has been marked as touched
  useEffect(validate, [day, month, year, maxDays, validate])

  function handleBlur({ target }) {
    touched.current[getBaseId(target.id)] = true
    validate() // start validation on blur
  }

  function blurYearHandler(event) {
    const currentTwoDigitYear = new Date().getFullYear() % 100
    // ensure that the year is in the YYYY format before validating
    setYear((currentValue) => {
      if (typeof currentValue === 'number') {
        if (currentValue < currentTwoDigitYear) return currentValue + 2000
        if (currentValue < 100) return currentValue + 1900
      }
      return currentValue
    })

    handleBlur(event)
  }

  /** Returning of value via onChange */

  const sentValue = useRef()

  function sendChange() {
    // return undefined if one of the input is changed to empty
    // return a Date object when input is valid

    let valueToSend // default returned value to undefined

    if (allValuesSet() && !hasError()) {
      // $DisableFlow the type of year month and day is checked in allValuesSet
      valueToSend = localToUtc(new Date(year, month - 1, day))
    }

    if (
      (valueToSend && valueToSend.getTime()) !==
      (sentValue.current && sentValue.current.getTime())
    ) {
      sentValue.current = valueToSend
      onChange(valueToSend)
    }
  }

  useEffect(sendChange, [day, month, year, sendChange])

  return (
    <div className={styles.DateInputWrapper}>
      <div className={styles.DateInput}>
        <span className={styles.span}>
          <label className={styles.label} htmlFor={`${id}_day`}>
            {translation('day')}
          </label>
          <Input
            min={1}
            max={maxDays}
            id={`${id}_day`}
            extend={{
              Input: rules.smallInputContainer,
              input: rules.smallInputField,
            }}
            type="number"
            value={day}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </span>
        <span className={styles.span}>
          <label className={styles.label} htmlFor={`${id}_month`}>
            {translation('month')}
          </label>
          <Input
            min={1}
            max={12}
            id={`${id}_month`}
            extend={{
              Input: rules.smallInputContainer,
              input: rules.smallInputField,
            }}
            type="number"
            value={month}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </span>
        <span className={styles.span}>
          <label className={styles.label} htmlFor={`${id}_year`}>
            {translation('year')}
          </label>
          <Input
            min={minYear}
            max={maxYear}
            id={`${id}_year`}
            extend={{
              Input: rules.largeInputContainer,
              input: rules.largeInputField,
            }}
            type="number"
            value={year}
            onChange={handleChange}
            onBlur={blurYearHandler}
          />
        </span>
      </div>
      {hasError() && (
        <FormError
          data-component-id="dateErrorDisplay"
          extend={{ FormError: rules.FieldError }}
        >
          {currentError()}
        </FormError>
      )}
    </div>
  )
}
export type DateInputProps = PropsType
export default connect(styleRules)(DateInput)
