// @flow

import React, { useState } from 'react'
import { useFela } from 'react-fela'
import { useTranslation } from 'react-i18next'
import { createFragmentContainer } from 'react-relay'
import { getOr } from 'lodash/fp'

import { UserTrackableRawScoreLoader } from 'components/UserTrackable/UserTrackableRawScore'
import { healthCardNoRating } from 'services/colours'
import useConditionalFormulaRangeDescription from 'platform_web/hooks/useConditionalFormulaRangeDescription'
import { usePolicies } from 'platform_web/hooks/usePolicies'
import { Box, FlexContainer, Heading } from 'care-ui'

import { currentResultCardFormulaUserTrackable } from '../../queries/CurrentResultCardFormula'
import ScoreCardsNotifications from '../ScoreCardsNotifications'

import {
  descriptionStyle,
  rawScoreStyle,
  updatedStyle,
} from './CurrentResultCardFormula.style'

import type { CurrentResultCardFormula_user_trackable } from '../../queries/__generated__/CurrentResultCardFormula_user_trackable.graphql'

type PropsType = {
  careOptionSupported: boolean,
  user_trackable: CurrentResultCardFormula_user_trackable,
}

const CurrentResultCardFormulaComponent = (props: PropsType) => {
  const {
    careOptionSupported,
    user_trackable,
    user_trackable: { time_since_updated },
  } = props

  const { t: translation } = useTranslation()

  const [completedNotification, setNotification] = useState(false)
  const policies = usePolicies()
  const defaultDescription = translation(
    'to_find_out_your_score_simply_complete_the_questionnaire',
  )

  const color = getOr(healthCardNoRating, 'formula_range.color', user_trackable)
  const description = getOr(
    defaultDescription,
    'formula_range.description',
    user_trackable,
  )
  const { css } = useFela({ color })

  const label = getOr(
    translation('unanswered'),
    'formula_range.label',
    user_trackable,
  )
  const showNotifications =
    user_trackable.escalated &&
    user_trackable.assigned_to_me &&
    policies.CAN_VIEW_CLINICIAN_LINKS

  const conditionalFormulaRangeDescriptionPayload = useConditionalFormulaRangeDescription(
    { label, description },
  )
  const {
    callbacks: { conditionalFormulaRangeDescription },
  } = conditionalFormulaRangeDescriptionPayload

  return (
    <Box
      dataTestId="CurrentResultCardFormula"
      paddingX="xs"
      paddingY="xs"
      borderTop={`8px solid ${color}`}
      borderRadius="md"
      height="100%"
      width="100%"
    >
      <FlexContainer direction="column" gap="xxxs" height="100%" wrap="nowrap">
        <Heading level={3}>
          {translation('current_result')} {label}
        </Heading>

        <div className={css(updatedStyle)}>
          {translation('last_updated')} {time_since_updated}{' '}
          {translation('ago')}
        </div>

        <UserTrackableRawScoreLoader
          extend={rawScoreStyle}
          user_trackable={user_trackable}
        />

        {showNotifications && !completedNotification && (
          <ScoreCardsNotifications
            setNotification={setNotification}
            userTrackable={user_trackable}
            careOptionSupported={careOptionSupported}
          />
        )}

        {(!showNotifications || completedNotification) && (
          <div className={css(descriptionStyle)}>
            {conditionalFormulaRangeDescription()}
          </div>
        )}
      </FlexContainer>
    </Box>
  )
}

export const CurrentResultCardFormula = createFragmentContainer(
  CurrentResultCardFormulaComponent,
  currentResultCardFormulaUserTrackable,
)
