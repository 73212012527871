// @flow

import React from 'react'
import type { TFunction } from 'react-i18next'
import { filter, includes, sortBy } from 'lodash/fp'

import Select from 'react-ui/components/Select'
import Spacer from 'react-ui/components/Spacer'
import { FormError } from 'shared/ui/Forms'

import { type FormikProps } from 'services/flow'

type TenantOrTenantGroupProps = {
  formProps: FormikProps,
  inputProps: (arg: string) => {},
  translation: TFunction,
}

const TenantOrTenantGroup = (props: TenantOrTenantGroupProps) => {
  const { inputProps, formProps, translation } = props
  const {
    errors,
    globalInvitableRoles,
    globalOnlyInvitableRoles,
    invitableMultiTenantRoleTypes: checkTypes = [],
    tenantGroups = [],
    tenants = [],
    touched,
    values,
  } = formProps

  const filteredTenants = filter((tenant) => !tenant.deactivated)(tenants)

  const tenantSelect = filteredTenants.length > 1 && (
    <>
      <Spacer units={0.5} />
      <label htmlFor="tenantId">
        <strong>{translation('service')}</strong>
        <Select
          {...(inputProps('tenantId'): any)}
          alwaysShowEmpty={includes(values.roleType)(globalInvitableRoles)}
          items={sortBy('name', filteredTenants)}
          keyName="id"
          nullLabel="Global"
          valueName="name"
        />
      </label>
      <FormError errors={errors} touched={touched} attr="tenantId" />
    </>
  )

  const tenantGroupSelect = tenantGroups?.length >= 1 && (
    <>
      <Spacer units={0.5} />
      <label htmlFor="tenantGroupId">
        <strong>{translation('service_group')}</strong>
        <Select
          {...(inputProps('tenantGroupId'): any)}
          items={sortBy('name', tenantGroups)}
          keyName="id"
          valueName="label"
        />
      </label>
      <FormError errors={errors} touched={touched} attr="tenantGroupId" />
    </>
  )

  if (includes(values.roleType)(globalOnlyInvitableRoles)) {
    return null
  }

  return includes(values.roleType)(checkTypes)
    ? tenantGroupSelect
    : tenantSelect
}

export default TenantOrTenantGroup
