// @flow

import React from 'react'
import { connect } from 'react-fela'
import { useTranslation } from 'react-i18next'

import { Button } from 'care-ui'

import type { FelaPropsType } from 'react-ui/typing'

type PropsType = FelaPropsType & {
  componentId: string,
  handleClick: () => void,
}

const styleRules = () => ({
  loadMoreWrapper: {
    position: 'relative',
    marginTop: '-10px',
  },
})

const LoadMore = (props: PropsType) => {
  const { styles, componentId = 'LoadMore', handleClick } = props

  const { t: translation } = useTranslation()

  return (
    <center className={styles.loadMoreWrapper}>
      <Button
        dataTestId={componentId}
        variant="secondary"
        onClick={handleClick}
      >
        {translation('load_more')}
      </Button>
    </center>
  )
}

export default connect(styleRules)(LoadMore)
