// @flow

import * as React from 'react'
import { connect } from 'react-fela'
import { camelCase } from 'lodash'

import { withoutFelaProps } from 'shared/services/fela'

import type { FelaOptionalPropsType, FelaPropsType } from 'react-ui/typing'

const styleRules = ({ theme }) => ({
  UserTrackable: {
    className: 'UserTrackable',
    color: theme.care.palette.text.positive,
    ...theme.care.typography.desktop.bodyLg,
  },
  formulaRange: {
    className: 'UserTrackable__formula-range',
    maxWidth: '100%',
    overflow: 'hidden',
    position: 'relative',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  trending: {
    className: 'UserTrackable__trending',
  },
  formulaRangeLabel: {
    ...theme.care.typography.desktop.bodyLgBold,
    marginBottom: theme.care.spacing.xs,
  },
})

type UserTrackableProps = {
  as?: string,
  formulaRange: {
    label: string,
    value: number,
  },
  formula_label?: string,
  staff?: boolean,
  timeSinceUpdated?: ?string,
  trending: {
    label: string,
  },
}

const UserTrackable = ({
  as: Component = 'div',
  formulaRange,
  trending,
  styles,
  timeSinceUpdated,
  staff,
  formula_label,
  ...props
}: UserTrackableProps & FelaPropsType) => {
  const formulaRangeLabel = staff ? styles.formulaRangeLabel : ''

  return (
    <Component className={styles.UserTrackable} {...withoutFelaProps(props)}>
      <div className={styles.formulaRange}>
        <span
          className={formulaRangeLabel}
          data-testid={`${camelCase(formula_label)}Outcome`}
        >
          {formulaRange.label}
        </span>
        {!!timeSinceUpdated && (
          <span data-testid={`${camelCase(formula_label)}OutcomePeriod`}>
            {' '}
            ({timeSinceUpdated})
          </span>
        )}
      </div>
      {!!trending && (
        <div
          className={styles.trending}
          data-testid={`${camelCase(formula_label)}Trend`}
        >
          {trending.label}
        </div>
      )}
    </Component>
  )
}

const Connected: React.StatelessFunctionalComponent<
  UserTrackableProps & FelaOptionalPropsType,
> = connect(styleRules)(UserTrackable)
export default Connected
