// @flow

import React, {
  type Element,
  Children,
  cloneElement,
  useEffect,
  useState,
} from 'react'
import { connect } from 'react-fela'
import { useTranslation } from 'react-i18next'
import onClickOutside from 'react-onclickoutside'
import { Portal } from 'react-portal'

import { AppVersion } from 'react-ui/components/AppVersion'
import { Column, Container, Row } from 'react-ui/components/Grid'
import Menu, { MenuItem, MenuItemGroup } from 'react-ui/components/Menu'
import Spacer from 'react-ui/components/Spacer'
import Themelet from 'react-ui/components/Themelet'
import { withoutFelaProps } from 'shared/services/fela'
import { Button, Text } from 'care-ui'
import ChevronLeft from 'care-ui/atoms/icons/chevron-left.svg'

import NavigationContext from './NavigationContext'
import NavigationPanel from './NavigationPanel'

import type { FelaPropsType } from 'react-ui/typing'

type PropsType = FelaPropsType & {
  +childPath: $ReadOnlyArray<string>,
  menuIsOpen: boolean,
  onPopChildPath: () => void,
  onPushChildPath: (string) => void,
  onRequestClose: () => void,
  scrollTop?: number,
}

type ChildType = Element<Menu> | Element<MenuItem> | Element<MenuItemGroup>

const styleRules = ({ theme }, renderer) => {
  const fadeIn = renderer.renderKeyframe(() => ({
    '0%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  }))
  const slideIn = renderer.renderKeyframe(() => ({
    '0%': {
      transform: 'translateX(-100%)',
    },
    '100%': {
      transform: 'translateX(0%)',
    },
  }))

  return {
    Navigation: {
      className: 'Navigation',
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      width: '100%',
      zIndex: 99,
    },
    menuItemWrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      marginLeft: `${theme.spacing(1)}`,
      overflow: 'auto',
      height: `${window.innerHeight - 240}px`,
      padding: '1em 0.5em',
      /* Track */
      '::-webkit-scrollbar-track': {
        background: theme.palette.dividerColor,
      },
      /* Handle on hover */
      '::-webkit-scrollbar-thumb:hover': {
        background: theme.palette.layoutDivider,
      },
    },
    container: {
      className: 'Navigation__container',
      width: '100%',
      height: '100%',
      maxWidth: 'unset',
      marginLeft: 0,
      marginRight: 0,
      animationDelay: 0,
      animationName: fadeIn,
      animationDuration: '400ms',
      backgroundColor: 'rgba(0,0,0,0.7)',
    },
    panel: {
      animationName: slideIn,
      animationDuration: '600ms',
      animationDelay: '100ms',
      animationFillMode: 'both',
      className: 'Navigation__panel',
      marginLeft: `-${theme.Grid.gutter}`,
      marginRight: `-${theme.Grid.gutter}`,
      backgroundColor: theme.care.palette.surface.default,
    },

    backButton: {
      className: 'Navigation__backButton',
      background: 'transparent',
      color: '#fff',
      border: 'none',
      cursor: 'pointer',
      display: 'block',
      height: theme.spacing(1.5),
      marginBottom: `-${theme.spacing(0.25)}`,
      marginRight: theme.Grid.gutter,
      marginTop: `-${theme.spacing(0.25)}`,
      padding: 0,
      textAlign: 'center',

      '& > svg': {
        transition: 'transform 400ms ease-in-out',
        transform: 'translateX(0)',
      },
      ':hover > svg': {
        transform: 'translateX(-4px)',
      },
    },

    copyright: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  }
}

const EnhancedNavigationPanel = onClickOutside(NavigationPanel)

const findChild = (needle: ?string, haystack: ChildType) => {
  const match = Children.toArray(haystack.props.children).find((child) => {
    if (child && child.type === MenuItemGroup) {
      const result = findChild(needle, child)
      return result
    }
    return child.props.label === needle
  })

  return match
}

const walkMenu = (
  menu: ChildType,
  childPath: $ReadOnlyArray<string>,
  { onPushChildPath, onRequestClose },
) => {
  const step = childPath[0]
  const childStep = findChild(step, menu)
  const nextMenuPath = childPath.slice(1)

  if (!menu) {
    // Handle null / false nodes from conditional renderering
    return menu
  } else if (childStep) {
    if (childStep && childStep.type === MenuItemGroup) {
      const match = findChild(step, childStep)
      if (match) {
        return walkMenu(match, nextMenuPath, {
          onPushChildPath,
          onRequestClose,
        })
      }
    }
    return walkMenu(childStep, nextMenuPath, {
      onPushChildPath,
      onRequestClose,
    })
  } else if (!childPath.length) {
    return cloneElement(menu, {
      children: Children.map(menu.props.children, (child) => {
        if (!child) {
          return child
        }

        const hasChildren = Children.count(child.props.children) > 0

        if (child && child.type === Menu && hasChildren) {
          return cloneElement(child, {
            onClick: (evt) => {
              evt.preventDefault()
              onPushChildPath(child.props.label)
            },
          })
        } else if (child && child.type === MenuItemGroup && hasChildren) {
          return cloneElement(child, {
            children: Children.map(child.props.children, (menuNode) => {
              if (!menuNode) {
                return menuNode
              }

              if (
                menuNode.type === Menu &&
                Children.count(menuNode.props.children) > 0
              ) {
                return cloneElement(menuNode, {
                  onClick: (evt) => {
                    evt.preventDefault()
                    onPushChildPath(menuNode.props.label)
                  },
                })
              }

              return cloneElement(menuNode, {
                onClick: (...args) => {
                  onRequestClose()
                  if (menuNode.props.onClick) {
                    menuNode.props.onClick(...args)
                  }
                },
              })
            }),
          })
        }
        return cloneElement(child, {
          onClick: (...args) => {
            onRequestClose()
            if (child.props.onClick) {
              child.props.onClick(...args)
            }
          },
        })
      }),
    })
  }

  return cloneElement(menu)
}

const Navigation = ({ onRequestClose, rules, styles, ...props }: PropsType) => {
  const [childPath, setChildPath] = useState([])

  const onPopChildPath = () => {
    window.scrollTo(0, 0)
    const c = childPath.slice(0, -1)
    setChildPath(c)
  }

  const onPushChildPath = (key) => {
    window.scrollTo(0, 0)
    setChildPath(childPath.concat(key))
  }

  const onKeyUp = (evt: KeyboardEvent) => {
    if (evt.code === 'Escape' || evt.keyCode === 27) {
      onRequestClose()
    }
  }

  // Localization
  const { t: translation } = useTranslation()

  useEffect(() => {
    document.addEventListener('keyup', onKeyUp)
    return () => {
      document.removeEventListener('keyup', onKeyUp)
    }
  }, [])

  return (
    <Portal>
      <div className={styles.Navigation} {...withoutFelaProps(props)}>
        <Container extend={rules.container}>
          <Row>
            <Column xs={12} sm={12} md={4} lg={3}>
              <Themelet as="section" extend={rules.panel} variant="primary">
                <NavigationContext.Consumer>
                  {({ menu, userCard }) => (
                    <EnhancedNavigationPanel
                      handleClickOutside={() => onRequestClose()}
                      header={
                        !!childPath.length && (
                          <Button
                            variant="text"
                            size="md"
                            onClick={onPopChildPath}
                            leftIcon={ChevronLeft}
                          >
                            {translation('back')}
                          </Button>
                        )
                      }
                      onRequestClose={onRequestClose}
                      // TODO: inject clientHeight as a prop with a window resize listener
                      // $DisableFlow
                      style={{ minHeight: `${document.body.clientHeight}px` }}
                    >
                      {!!userCard && (
                        <div data-component-id="UserMenuAvatar">
                          {userCard}
                          <Spacer axis="vertical" units={1} />
                        </div>
                      )}
                      {!!menu && (
                        <>
                          <div
                            className={styles.menuItemWrapper}
                            data-component-id="UserMenuItems"
                          >
                            {walkMenu(menu, childPath, {
                              onPushChildPath,
                              onRequestClose,
                            })}
                          </div>
                          <div className={styles.copyright}>
                            <Text size="sm">{`© ${translation(
                              'copyright_2017',
                            )}-${new Date().getFullYear()}`}</Text>
                            <AppVersion />
                          </div>
                        </>
                      )}
                    </EnhancedNavigationPanel>
                  )}
                </NavigationContext.Consumer>
              </Themelet>
            </Column>
          </Row>
        </Container>
      </div>
    </Portal>
  )
}

export default connect(styleRules)(Navigation)
