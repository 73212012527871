// @flow

import React from 'react'
import { connect } from 'react-fela'
import { useTranslation } from 'react-i18next'

import TickCircleIcon from 'react-ui/assets/icons/tick-circle.svg'
import Icon from 'react-ui/components/Icon'

import { type FelaPropsType } from 'react-ui/typing'

type PropsType = FelaPropsType & {
  colorCode: string,
}

const styleRules = ({ theme, colorCode }) => ({
  icon: {
    marginTop: theme.Grid.gutter,
  },
  message: {
    color: colorCode || theme.palette.component.primary.base,
    fontSize: theme.Font.fontSize,
    margin: 'auto',
    textAlign: 'center',
    width: '100%',
  },
})

const ThankYouMessage = ({ styles, rules }: PropsType) => {
  const { t: translation } = useTranslation()
  return (
    <div className={styles.message}>
      <Icon as={TickCircleIcon} scale={2} extend={rules.icon} />
      <br />
      <p>{translation('thank_you_your_input_was_recorded')}</p>
    </div>
  )
}

export default connect(styleRules)(ThankYouMessage)
