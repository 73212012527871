// @flow

import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { createFragmentContainer } from 'react-relay'

import Markdown from 'react-ui/components/Markdown'
import { FlexContainer, FlexItem, Text } from 'care-ui'

import { assessmentRequestsTableBaseAnswers } from './query/AssessmentRequestsAnswerTable'

import type { AssessmentRequestsAnswerTableBase_answers } from './query/__generated__/AssessmentRequestsAnswerTableBase_answers.graphql'

type PropsType = {
  answers: ?AssessmentRequestsAnswerTableBase_answers,
}

const AssessmentRequestsAnswerTableBase = (props: PropsType) => {
  const { answers } = props

  const { t: translation } = useTranslation()

  return (
    <>
      <FlexContainer
        paddingY="lg"
        paddingX="xxs"
        alignItems="center"
        wrap="nowrap"
        gap="xs"
      >
        <FlexItem flex={3}>
          <Text size="lg" bold color="title">
            {translation('question')}
          </Text>
        </FlexItem>

        <FlexItem flex={2}>
          <Text size="lg" bold color="title">
            {translation('answer_one')}
          </Text>
        </FlexItem>
      </FlexContainer>

      {answers?.map((answer, index) => {
        return (
          <FlexContainer
            paddingY="lg"
            justifyContent="center"
            alignItems="start"
            wrap="nowrap"
            gap="xs"
            dataTestId="QuestionAnswerRow"
          >
            <FlexItem flex={3} dataTestId="Question">
              <Text size="lg" color="title">
                <Markdown
                  source={`${index + 1} ${answer.question.label || ''}`}
                />
              </Text>
            </FlexItem>

            <FlexItem flex={2} dataTestId="Answer">
              <Text size="lg" color="title">
                <Markdown source={answer.display_value} />
              </Text>
            </FlexItem>
          </FlexContainer>
        )
      })}
    </>
  )
}

export const AssessmentRequestsAnswerTableLoader = createFragmentContainer(
  AssessmentRequestsAnswerTableBase,
  assessmentRequestsTableBaseAnswers,
)
