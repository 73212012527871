// @flow

import * as React from 'react'
import { connect } from 'react-fela'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import useRouter from 'found/useRouter'
import { flatten, last } from 'lodash'
import get from 'lodash/fp/get'
import omit from 'lodash/fp/omit'

import Heading from 'react-ui/components/Heading'
import Spacer from 'react-ui/components/Spacer'
import { felaProps } from 'shared/services/fela'
import createComponentId from 'shared/services/id'
import { createLogger } from 'shared/services/logging'
import type { UseMultiSelectReturnTypes } from 'platform_web/pages/Staff/hooks/useMultiSelect'
import { Checkbox, FlexContainer } from 'care-ui'

import BasicCellRenderer from './BasicCellRenderer'
import HeaderCellRenderer from './DataGridHeaderCellRenderer'
import DataGridRowFooterRenderer from './DataGridRowFooterRenderer'

import type { FelaPropsType } from 'react-ui/typing'

const log = createLogger(__filename)

const styleRules = ({ theme, maxHeightOffset }) => {
  const row = {
    alignItems: 'stretch',
    boxSizing: 'border-box',
    className: 'DataGrid__row',
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'flex-start',
    position: 'relative',
    width: '100%',
  }

  const header = {
    ...row,
    backgroundColor: theme.DataGrid.header.base,
    borderBottomColor: theme.DataGrid.header.accent,
    borderBottomStyle: 'solid',
    borderBottomWidth: '1px',
    color: theme.DataGrid.header.text,
    flex: '0 0 auto',
    marginBottom: '13px',
    marginLeft: `-${theme.Grid.gutter}`,
    marginRight: `-${theme.Grid.gutter}`,
    width: '100%',
  }

  const basicCell = {
    backgroundColor: theme.DataGrid.cell.base,
    boxSizing: 'border-box',
    className: 'BasicCellRenderer',
    flexGrow: 1,
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.Grid.gutter,
    paddingRight: theme.Grid.gutter,
    paddingTop: theme.spacing(0.5),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }

  return {
    DataGrid: {
      boxSizing: 'border-box',
      className: classNames('DataGrid', {
        [`--maxHeightOffset-${maxHeightOffset}`]: maxHeightOffset,
      }).replace(/\s/g, ''),
      display: 'flex',
      flexFlow: 'column nowrap',
      maxHeight: maxHeightOffset ? `calc(100vh - ${maxHeightOffset})` : '100vh',
      maxWidth: 'inherit',
      overflow: 'auto',
      position: 'relative',
      paddingLeft: theme.Grid.gutter,
      paddingRight: theme.Grid.gutter,
    },
    body: {
      className: 'DataGrid__body',
      display: 'block',
      flex: '1 1 auto',
      marginLeft: `-${theme.Grid.gutter}`,
      marginRight: `-${theme.Grid.gutter}`,
      minWidth: '960px',
      overflow: 'auto',
      // This is technically against the "Fela recommended way" of doing things,
      // but it's WAY more performant when rendering a lot of table cells.
      '& .BasicCellRenderer': {
        ...basicCell,
      },
      '& .TextCell': {
        paddingTop: theme.spacing(0.25),
      },
      [theme.breakpoints.queries.xsOnly]: {
        display: 'table',
      },
    },
    headerHidden: {
      ...header,
      className: 'DataGrid__headerHidden',
      visibility: 'hidden',
      width: '100%',
    },
    headerVisible: {
      ...header,
      className: 'DataGrid__headerVisible',
      borderStyle: 'none',
      position: 'absolute',
      top: 0,
      left: theme.Grid.gutter,
      width: '100%',
      overflowX: 'hidden',
      overflowY: 'hidden',
      minWidth: '960px',
    },
    rowParentBorder: {
      borderBottomColor: theme.DataGrid.cell.accent,
      borderBottomStyle: 'solid',
      borderBottomWidth: '1px',
    },
    row,
  }
}

export type DataGridCellRenderer = (
  data: any,
  key: string,
  props: { width: string },
) => React.Node

export type DataGridHeaderCellRenderer = (
  key: string,
  label: string | React.Node,
  props: { width: string },
) => React.Node

export type DataGridPropTypes = FelaPropsType & {
  cellRenderers: {
    [key: string]: DataGridCellRenderer,
  },
  columnWidths: {
    [key: string]: string,
  },
  columns?: [string],
  componentId?: string,
  data: $ReadOnlyArray<Object>,
  dataAttr?: { name: string, value: string },
  footer?: Function,
  format?: {
    [key: string]: (input: any, row?: any) => any,
  },
  formatNull: {
    [key: string]: boolean,
  },
  headerCellRenderers: {
    [key: string]: DataGridHeaderCellRenderer,
  },
  headers: { [key: string]: string },
  mapData: (rowData: *) => *,
  mapRowProps: (rowData: *, index: number, rowProps: *) => *,
  maxHeightOffset?: string,
  multiSelectPayload: UseMultiSelectReturnTypes,
  noDataText?: string,
  onScroll?: () => void,
  renderCell: DataGridCellRenderer,
  renderHeaderCell: DataGridHeaderCellRenderer,
  renderRow: (
    data: *,
    index: number,
    props: {
      border?: string,
      className?: string,
      componentId?: string,
      key: string,
    },
    children: React.Node,
    footer?: Function,
    multiSelectPayload: UseMultiSelectReturnTypes,
  ) => React.Node,
  scrollLeft: number,
  title?: React.Node,
}

const defaultId = createComponentId(__filename)

const DataGrid = ({
  cellRenderers = {},
  columnWidths = {},
  data = [],
  formatNull = {},
  headerCellRenderers = {},
  headers = {},
  mapData = (rowData) => rowData,
  mapRowProps = (rowData, index, rowProps) => rowProps,
  renderCell = (cellData, key, props) =>
    React.createElement(
      BasicCellRenderer,
      { ...props, key },
      get(key, cellData),
    ),
  renderHeaderCell = (key, label, props) =>
    React.createElement(HeaderCellRenderer, { ...props, key }, label),
  renderRow = (rowData, index, props, children, footer, multiSelectPayload) => {
    const { componentId } = props
    const { id } = rowData

    // rowId is the className that is used for backend tests
    const rowId = componentId && id ? `${componentId}-${id}` : ''

    const newProps = omit(['componentId', 'border'], {
      ...props,
      className: classNames(props.className, rowId),
    })
    return (
      <div key={props.key} className={props.border}>
        <div {...newProps}>{children}</div>
        {footer && (
          <DataGridRowFooterRenderer
            content={footer(data)}
            multiSelectPayload={multiSelectPayload}
          />
        )}
      </div>
    )
  },
  columns: selectedColumns,
  componentId = defaultId,
  dataAttr = {},
  format = {},
  noDataText,
  styles,
  title,
  footer,
  multiSelectPayload,
  ...props
}: DataGridPropTypes) => {
  const { t: translation } = useTranslation()
  const [scrollLeft, setScrollLeft] = React.useState(0)
  const onScroll = (evt) => {
    setScrollLeft(evt.target.scrollLeft)
  }

  const renderHeaderLabel = (key, map) => {
    if (typeof map === 'function') {
      return map(key)
    }
    if (typeof map === 'object' && map[key]) {
      return map[key]
    }
    return key
  }

  const noop = (input) => input

  const columns =
    selectedColumns || (data.length ? Object.keys(mapData(data[0])) : [])
  const defaultCellProps = { width: `${100 / columns.length}%` }

  const {
    isMultiSelect,
    hasSelectedRoleIds,
    selectedRoleIds,
    setMultiSelectState,
  } = multiSelectPayload || {}

  // Hacky Solution to know the current table
  const {
    match: { routes },
  } = useRouter()

  const currentPageRoute = last(routes)?.name
  // For Employee Table, we have user id
  const ids = ['staff_employees'].includes(currentPageRoute)
    ? flatten(data.map((item) => item?.node.roles.map((role) => role.id)))
    : data.map((item) => item?.node?.id || item?.id)

  // For Employee Table and Individual table [request assessment], we have user id
  const userIds = ['staff_employees', 'staff_individuals'].includes(
    currentPageRoute,
  )
    ? data.map((item) => ({
        userId: item.node?.user?.__id || item.node?.user?.id,
        roles: item?.node?.roles?.map((role) => role) || [],
      }))
    : []

  const isPartial =
    hasSelectedRoleIds && selectedRoleIds?.length !== ids?.length
  const isAllSelected =
    hasSelectedRoleIds && selectedRoleIds?.length === ids?.length

  const handleOnChange = () => {
    // Toggle - if allSelected, toggle it to all not selected and vice-versa
    // Selected item is replaced with all ids is select all

    if (isAllSelected) {
      setMultiSelectState({
        selectedRoleIds: [],
        selectedUsers: [],
        deselectedRoleIds: [],
        allSelected: false,
      })
    } else {
      setMultiSelectState({
        selectedRoleIds: ids,
        deselectedRoleIds: [],
        allSelected: true,
        selectedUsers: userIds,
      })
    }
  }

  const headerColumns = columns.map((columnName, index) => {
    const renderHeader = headerCellRenderers[columnName] || renderHeaderCell
    const label = renderHeaderLabel(columnName, headers)

    const updatedLabel =
      index === 0 && isMultiSelect && !!data?.length ? (
        <div style={{ marginLeft: columns.length > 4 ? '-6px' : '-12px' }}>
          <FlexContainer alignItems="center" gap="xxs">
            <Checkbox
              inputAttributes={{
                id: 'multi-select-all',
                name: 'multi-select-all',
                onChange: handleOnChange,
                checked: isAllSelected,
                partial: isPartial,
              }}
            />
            {label}
          </FlexContainer>
        </div>
      ) : (
        label
      )

    return renderHeader(columnName, updatedLabel, {
      'data-component-id': 'table-column-head',
      title: label,
      width: columnWidths[columnName] || defaultCellProps.width,
    })
  })

  const syncScroll = (el) => {
    if (el) {
      // eslint-disable-next-line no-param-reassign
      el.scrollLeft = scrollLeft
    }
  }

  const noData = <div>{noDataText || translation('no_results_found')}</div>

  const rows = data.map((o, i) => {
    const mappedData = mapData(o)
    return renderRow(
      mappedData,
      i,
      mapRowProps(mappedData, i, {
        key: mappedData.id || i,
        componentId,
        className: styles.row,
        border: styles.rowParentBorder,
      }),
      // Render the columns as children
      columns.map((key) => {
        const render = cellRenderers[key] || renderCell
        const formatter = format[key] || noop
        if (!mappedData.hasOwnProperty(key)) {
          log(
            'Data grid has undefined column data for key',
            key,
            'within row data',
            mappedData,
          )
        }
        const rowData = mappedData[key]

        const formattedData = {
          ...mappedData,
          [key]:
            // if data is null or undefined, don't pass it to the formatter
            // so that null checks can be avoided.
            (formatNull[key] ||
              (typeof rowData !== 'undefined' && rowData !== null)) &&
            formatter(rowData, mappedData),
        }

        const dataAttribute = dataAttr[key]
          ? { [dataAttr[key].name]: dataAttr[key].value }
          : {}

        return render(formattedData, key, {
          width: columnWidths[key] || defaultCellProps.width,
          // add data-... attribute to a cell
          ...dataAttribute,
        })
      }),
      footer,
      multiSelectPayload,
    )
  })

  const rowData = data.length > 0 ? rows : noData

  return (
    <>
      {title && (
        <>
          <Heading level={4} data-component-id={`${componentId}_title`}>
            {title}
          </Heading>
          <Spacer units={0.5} />
        </>
      )}
      <div
        {...omit(
          ['maxHeightOffset', 'componentId', 'columnSort', ...felaProps],
          props,
        )}
        className={classNames(styles.DataGrid, componentId)}
        data-component-id={componentId}
      >
        <div
          className={styles.headerVisible}
          onScroll={onScroll}
          ref={syncScroll}
          data-component-id="table-head"
        >
          {headerColumns}
        </div>
        <div className={styles.headerHidden}>{headerColumns}</div>

        <div
          className={styles.body}
          onScroll={onScroll}
          ref={syncScroll}
          data-component-id="table-body"
        >
          {rowData}
        </div>
      </div>
    </>
  )
}

export default connect(styleRules)(DataGrid)
