// @flow

import React, { type Node } from 'react'
import { useFela } from 'react-fela'
import { useTranslation } from 'react-i18next'

import NavLink from 'components/Settings/NavLink'
import WithAuth from 'components/WithAuth'
import { Column, Row } from 'react-ui/components/Grid'
import Section from 'react-ui/components/Section'
import { Container, Heading } from 'care-ui'

import {
  listStyle,
  mainContentStyle,
  mainStyle,
  navStyle,
  rowStyle,
  sectionOuterStyle,
  sectionStyle,
  wrapperStyle,
} from './UserSettingsPage.style'

type PropsType = {
  +children: Node,
}

const UserSettingsPage = (props: PropsType) => {
  const { children } = props

  const { t: translation } = useTranslation()

  const { css } = useFela()

  const sideNav = (
    <div>
      <Heading level={4}>{translation('manage_your_settings')}</Heading>
      <ul className={css(listStyle)}>
        <li>
          <NavLink
            to={{ name: 'settings_personal_details' }}
            locationCheck={['/settings/personal_details', '/settings/user']}
          >
            {translation('profile')}
          </NavLink>
        </li>
      </ul>
      <ul className={css(listStyle)}>
        <li>
          <NavLink
            to="update-password"
            locationCheck={['/settings/update-password']}
          >
            {translation('password')}
          </NavLink>
        </li>
      </ul>
      <WithAuth requiredPolicies={['CAN_VIEW_OWN_HEALTH_HISTORY']}>
        <ul className={css(listStyle)}>
          <li>
            <NavLink
              to="health-history"
              locationCheck={['/settings/health-history']}
            >
              {translation('health_history')}
            </NavLink>
          </li>
        </ul>
      </WithAuth>
      <WithAuth requiredPolicies={['CAN_VIEW_CONNECTED_ORGANIZATIONS_LINK']}>
        <ul className={css(listStyle)}>
          <li>
            <NavLink
              to={{ name: 'connected_organisations' }}
              locationCheck={['/settings/connected_organisations']}
            >
              {translation('clinicians_and_healthcare_providers')}
            </NavLink>
          </li>
        </ul>
      </WithAuth>
      <WithAuth
        requiredPolicies={[
          'CAN_VIEW_INDIVIDUAL_LINKS',
          'CAN_SHOW_RESEARCH_DATA_SHARING_OPTIONS',
        ]}
      >
        <ul className={css(listStyle)}>
          <li>
            <NavLink
              to="research_data_sharing_settings"
              locationCheck={['/settings/research_data_sharing']}
            >
              {translation('research_and_data_sharing')}
            </NavLink>
          </li>
        </ul>
      </WithAuth>
      <WithAuth requiredPolicies={['CAN_READ_LEGAL_INFO_FOR_INDIVIDUALS']}>
        <ul className={css(listStyle)}>
          <li>
            <NavLink
              to="notifications_settings"
              locationCheck={['/settings/notifications']}
            >
              {translation('sms_notifications')}
            </NavLink>
          </li>
        </ul>
      </WithAuth>
      <WithAuth requiredPolicies={['CAN_CREATE_FEEDBACKS']}>
        <ul className={css(listStyle)}>
          <li>
            <NavLink to="contact_us" locationCheck={['/settings/contact-us']}>
              {translation('contact_us')}
            </NavLink>
          </li>
        </ul>
      </WithAuth>
    </div>
  )

  const mainContent = <div className={css(mainContentStyle)}>{children}</div>

  return (
    <Section
      noPadding
      className={css(sectionOuterStyle)}
      extend={{ Section: sectionStyle }}
    >
      <Container extend={wrapperStyle}>
        <Row extend={rowStyle}>
          <Column col={12} md={4} extend={navStyle}>
            {sideNav}
          </Column>
          <Column col={12} md={8} extend={mainStyle}>
            {mainContent}
          </Column>
        </Row>
      </Container>
    </Section>
  )
}

export default UserSettingsPage
