// @flow

import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { createFragmentContainer } from 'react-relay'
import Link from 'found/Link'

import { usePolicies } from 'platform_web/hooks/usePolicies'

import { query } from './query/UserQuestionSetDetailsView'

import type { UserQuestionSetDetailsView_user_question_set } from './query/__generated__/UserQuestionSetDetailsView_user_question_set.graphql'

type PropsType = {
  user_question_set: UserQuestionSetDetailsView_user_question_set,
}

const UserQuestionSetDetailsView = (props: PropsType) => {
  const { t: translation } = useTranslation()
  const {
    user_question_set: { user, question_set },
  } = props
  const policies = usePolicies()
  const showLink = policies.CAN_VIEW_INDIVIDUAL_QUESTION_SET_ANSWERS

  if (!showLink) return null

  return (
    <Link
      to={{
        name: 'user_question_set_details',
        params: {
          id: user.id,
          question_set_id: question_set.id,
        },
      }}
    >
      {translation('view_answers')}
    </Link>
  )
}

export const UserQuestionSetDetailsViewLoader = createFragmentContainer(
  UserQuestionSetDetailsView,
  query,
)
