// @flow

import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { createFragmentContainer } from 'react-relay'

import { ConnectedTenantsListLoader } from 'components/ConnectedTenants/ConnectedTenantsList'
import { IndividualRolesListLoader } from 'components/ConnectedTenants/IndividualRolesList'
import { Container } from 'react-ui/components/Grid'
import Heading from 'react-ui/components/Heading'
import Section from 'react-ui/components/Section'

import { connectedTenantsPageUser } from './queries/ConnectedTenantsPage'

import type { ConnectedTenantsPage_user } from './queries/__generated__/ConnectedTenantsPage_user.graphql'

type PropsType = {
  +user: ConnectedTenantsPage_user,
}

const ConnectedTenantsPage = (props: PropsType) => {
  const { user } = props

  const { roles, individual_tenants } = user

  const { t: translation } = useTranslation()

  return (
    <Fragment>
      <Section color="default">
        <Container>
          <Heading level={2}>
            {translation('connected_organisations_and_access')}
          </Heading>

          <p>
            {translation(
              'on_this_page_you_can_see_the_organisations_and_clinicians_that_you_are_working_with',
            )}
          </p>

          <Heading level={3}>{translation('organisations')}</Heading>

          <p>
            {translation(
              'to_manage_access_to_your_data_please_contact_the_relevant_service',
            )}
          </p>

          <ConnectedTenantsListLoader tenants={individual_tenants} />

          <Heading level={3}>{translation('clinicians')}</Heading>

          {roles && <IndividualRolesListLoader individuals={roles.nodes} />}
        </Container>
      </Section>
    </Fragment>
  )
}

export const ConnectedTenantsPageLoader = createFragmentContainer(
  ConnectedTenantsPage,
  connectedTenantsPageUser,
)
