// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Button, Modal, SuspenseLoader } from 'care-ui'
import useModal from 'care-ui/molecules/hooks/useModal'

import ActivityLogCardContainer from '../ActivityLogCardContainer'

type PropsType = {
  triggerLabel: string,
  userId: string,
}

const ActivityLogModal = (props: PropsType) => {
  const { triggerLabel, userId } = props
  const { isOpen, closeModal, openModal } = useModal()

  const { t: translation } = useTranslation()

  return (
    <>
      <Button
        onClick={openModal}
        dataTestId="activity-log-modal-trigger"
        ariaLabel={triggerLabel}
        variant="link"
      >
        {triggerLabel}
      </Button>

      <Modal
        isOpen={isOpen}
        closeModal={closeModal}
        heading={translation('recent_activity')}
      >
        <Box height="70vh">
          <SuspenseLoader>
            <ActivityLogCardContainer userId={userId} />
          </SuspenseLoader>
        </Box>
      </Modal>
    </>
  )
}

export default ActivityLogModal
