// @flow

import React from 'react'
import { connect } from 'react-fela'
import { useTranslation } from 'react-i18next'

import Loading from 'react-ui/assets/images/loading.svg'
import { H3, H4 } from 'react-ui/components/Heading'

import FancyContainer from './FancyContainer'

import type { FelaPropsType } from 'react-ui/typing'

type PropsType = FelaPropsType & {
  componentId?: string,
  title: string,
}

const styleRules = () => ({
  headingStylesOverride: {
    marginTop: 0,
    marginBottom: 0,
    textAlign: 'center',
  },
  Loading: {
    width: '35px',
    paddingTop: '10px',
  },
})

const FancyHealthCardPending = ({
  componentId,
  rules,
  styles,
  title,
}: PropsType) => {
  const { t: translation } = useTranslation()
  return (
    <FancyContainer hasData={false} componentId={componentId}>
      <H3 extend={rules.headingStylesOverride}>{title}</H3>
      <H4 extend={rules.headingStylesOverride}>{translation('calculating')}</H4>
      <Loading className={styles.Loading} />
    </FancyContainer>
  )
}

export default connect(styleRules)(FancyHealthCardPending)
