// @flow

import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useRouter } from 'found'

import { useRouterReset } from 'services/routeConfig/Router'
import { LoadingSpinner } from 'care-ui'

export default function () {
  const resetRouter = useRouterReset()
  const {
    match: {
      params: { nextLocation },
    },
  } = useRouter()

  useEffect(() => {
    resetRouter()
    window.location.replace(nextLocation)
  }, [nextLocation])

  // Localization
  const { t: translation } = useTranslation()

  return <LoadingSpinner message={translation('signing_in')} />
}
