// @flow

import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { subDays, subYears } from 'date-fns'

const getIntervals = (translation) => [
  { value: '', label: translation('since_inception') },
  { value: 'last_thirty_days', label: translation('last_30_days') },
  { value: 'last_seven_days', label: translation('last_7_days') },
  { value: 'last_three_days', label: translation('last_3_days') },
  { value: 'custom', label: translation('custom_range') },
]

const getFromDate = (interval) => {
  const today = new Date()

  const intervalMap = {
    last_thirty_days: 30,
    last_seven_days: 7,
    last_three_days: 3,
  }

  if (interval in intervalMap) {
    return subDays(today, intervalMap[interval])
  }

  // Default to "Since inception" which is 10 years ago
  return subYears(today, 10)
}

const getToDate = () => {
  return new Date()
}

type DateRange = { from: Date, to: Date }

type UseServiceMetricsDateRangeSelectorProps = DateRange & {
  interval: string,
  setQueryVariables: (interval: string, dateRange: DateRange) => void,
}

const useServiceMetricsDateRangeSelector = (
  props: UseServiceMetricsDateRangeSelectorProps,
) => {
  const { interval, setQueryVariables, from, to } = props

  // prettier-ignore
  const [isCustomInterval, setIsCustomInterval] = useState<boolean>(interval === "custom")
  // prettier-ignore
  const [range, setRange] = useState<DateRange>({ from, to })

  // Dropdown for DateRange Selector Container
  // prettier-ignore
  const [closeDropDown, setCloseDropDown] = useState<boolean>(true)
  const toggleDropDownMenu = () => {
    setCloseDropDown((prev) => !prev)
  }

  // Update Interval for graph query
  // If custom, opens dropdown for DateRange Selector Container
  const changeInterval = (value: string) => {
    if (value === 'custom') {
      setCloseDropDown(false)
      setIsCustomInterval(true)
      return
    }

    setQueryVariables(value, {
      from: getFromDate(value),
      to: getToDate(),
    })
    setIsCustomInterval(false)
  }

  // Update Custom date range when applied button clicked
  const updateCustomDateRange = () => {
    setQueryVariables('custom', {
      from: range.from,
      to: range.to,
    })

    toggleDropDownMenu()
  }

  // Logic To Handle Cancel Button For Custom DateRange Selector
  // 1. If Custom Date Range is not applied, the cancel button should fallback to previous interval
  // or interval of the data displayed
  // 2. If Apply button is clicked, it remains to the custom DateRange Selector
  const handleOnCancel = () => {
    toggleDropDownMenu()

    if (interval === 'custom') return

    setIsCustomInterval(false)
  }

  // Localization
  const { t: translation } = useTranslation()

  return {
    changeInterval,
    closeDropDown,
    handleOnCancel,
    INTERVALS: getIntervals(translation),
    isCustomInterval,
    range,
    setCloseDropDown,
    setIsCustomInterval,
    setRange,
    toggleDropDownMenu,
    updateCustomDateRange,
  }
}

export default useServiceMetricsDateRangeSelector
