// @flow

import React, { useState } from 'react'
import { useFela } from 'react-fela'
import { useTranslation } from 'react-i18next'
import { useRelayEnvironment } from 'react-relay'

import { AccordionItem } from 'react-ui/components/Accordion'
import ToggleButton from 'react-ui/components/Form/ToggleButton'
import { commit } from 'mutations/UserResearchProgramsResponseCreate'
import { createFlash } from 'platform_web/containers/Flash'

import {
  accordionItemStyle,
  offButtonStyle,
} from './UserResearchProgramsResponse.style'

type PropsType = {
  researchProgram: Object,
  userResponse: Object,
}

const completeSubmit = (translation) => {
  createFlash({
    message: translation('thank_you_your_selection_was_recorded'),
    type: 'notice',
    timeout: 0,
  })
}

const offButtonData = (css, title, isChecked, onChange) => (
  <div className={css(offButtonStyle)}>
    <ToggleButton id={title} checked={isChecked} onChange={onChange} />
  </div>
)

const UserResearchProgramsResponse = (props: PropsType) => {
  const {
    researchProgram: { title, description },
    userResponse: { accepted, user_id, tenant_research_program_id },
  } = props

  const { t: translation } = useTranslation()

  const { css } = useFela()
  const environment = useRelayEnvironment()
  const [isOpen, setOpen] = useState(false)
  const [isChecked, setChecked] = useState(accepted)
  const onChange = () => {
    const answers = [
      {
        accepted: !isChecked,
        user_id,
        tenant_research_program_id,
      },
    ]
    commit({
      onCompleted: () => completeSubmit(translation),
      environment,
      variables: {
        input: {
          user_research_programs_response_attributes: answers,
        },
      },
    })
    setChecked(!isChecked)
  }

  return (
    <AccordionItem
      withArrow
      extend={accordionItemStyle}
      heading={title}
      offButton={offButtonData(css, title, isChecked, onChange)}
      isOpen={isOpen}
      onToggleOpen={() => setOpen(!isOpen)}
    >
      <p dangerouslySetInnerHTML={{ __html: description }} />
    </AccordionItem>
  )
}

export default UserResearchProgramsResponse
