// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import Link from 'found/Link'

import Heading from 'react-ui/components/Heading'
import { Box } from 'care-ui'

type PropsType = {
  onTryAgain: () => void,
}

const NewPasswordWaiting = (props: PropsType) => {
  const { onTryAgain } = props

  const { t: translation } = useTranslation()

  return (
    <Box width="600px">
      <Heading
        level={3}
        extend={({ theme }) => ({
          color: theme.palette.component.primary.base,
        })}
      >
        {translation('please_check_your_email')}
      </Heading>

      <p>
        {translation(
          'if_the_email_address_or_region_provided_is_registered_on_our_system_an_email_with_the_instructions_to_reset_the_password_will_be_sent_shortly_this_password_reset_is_only_valid_for_the_next_6_hours',
        )}
      </p>

      <p>
        {translation(
          'if_you_do_not_receive_an_email_in_5_minutes_please_check_your_region_setting_and_email_address',
        )}
      </p>

      <div>
        <Link to="/users/password/new" onClick={onTryAgain}>
          {translation('request_the_link_again')}
        </Link>
      </div>
    </Box>
  )
}

export default NewPasswordWaiting
