// @flow

import React from 'react'
import i18next from 'i18next'

import UserActivityCard from 'react-ui/components/UserActivityCard'
import { type UserActivityCardLoader_user } from 'react-ui/components/UserActivityCard/__generated__/UserActivityCardLoader_user.graphql'
import { createSortByString } from 'shared/services/sorters'

export default {
  format: (user: UserActivityCardLoader_user) => (
    <UserActivityCard user={user} />
  ),
  label: i18next.t('patient_name'),
  sort: createSortByString('user.legal_name'),
  width: '320px',
}
