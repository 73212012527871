// @flow

import React, { type Node } from 'react'
import { useTranslation } from 'react-i18next'
import pluralize from 'pluralize'

import { PopOverWithSearch } from 'react-ui/components/PopOver'

import { LinkDropDown } from '.'

type PropsType = {
  componentId?: string,
  +data: $ReadOnlyArray<Object>,
  getId?: (any) => string,
  onChange: (string[]) => void,
  renderOption: (
    data: *,
    props: {
      checked: boolean,
      key: string,
      onChange: () => void,
    },
  ) => Node,
  searchFields: string[],
  +selectedIds: $ReadOnlyArray<string>,
  singularLabel: string,
}

function getNextSelection(selectedIds, targetId: string): string[] {
  if (selectedIds.includes(targetId)) {
    return selectedIds.filter((id) => id !== targetId)
  }

  return selectedIds.concat(targetId)
}

function defaultGetId(data) {
  return data.id
}

export default ({
  data,
  getId = defaultGetId,
  onChange,
  renderOption,
  selectedIds: unboundSelectedIds,
  singularLabel,
  componentId = 'MultiSelect',
  searchFields,
  ...props
}: PropsType) => {
  // Filter out any selected id's that do not exist in data.
  const selectedIds = unboundSelectedIds.filter((id) =>
    data.some((obj) => getId(obj) === id),
  )

  const selectionCount = selectedIds.length
  const someSelected = selectionCount > 0
  const allSelected = someSelected && selectionCount === data.length

  // Localization
  const { t: translation } = useTranslation()

  const menuLabel = (() => {
    if (allSelected)
      return `${translation('all')} ${pluralize(singularLabel, 2)}`
    if (someSelected)
      return `${selectionCount} ${pluralize(
        singularLabel,
        selectionCount,
      )} ${translation('selected')}`
    return `${translation('filter_by')} ${pluralize(singularLabel, 2)}`
  })()

  return (
    <PopOverWithSearch
      attachment="top right"
      targetAttachment="bottom right"
      extend={() => ({
        container: { width: '450px' },
      })}
      componentId={componentId}
      data={data}
      searchFields={searchFields}
      renderTrigger={({ onToggleOpen }) => (
        <LinkDropDown
          label={menuLabel}
          onClick={onToggleOpen}
          disabled={data.length === 0}
          componentId={`${componentId}Trigger`}
        />
      )}
      {...(props: any)}
    >
      {({ searchResult }) => (
        <form onSubmit={(evt) => evt.preventDefault()}>
          {searchResult.map((obj) => {
            const id = getId(obj)
            const checked = selectedIds.includes(id)
            return renderOption(obj, {
              checked,
              key: id,
              onChange: () => {
                onChange(getNextSelection(selectedIds, id))
              },
            })
          })}
        </form>
      )}
    </PopOverWithSearch>
  )
}
