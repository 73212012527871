// @flow

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { normalizeClinicianValues } from 'shared/services/normalization'
import { type UiStyleProps } from 'shared/ui/Forms/Fields/RadioSet/RadioSet'
import { PublicHeader } from 'shared/ui/Layout/Public'
import type { QuestionnaireType } from 'shared/utils/SimpleQuestionnaireTypes'

import OnboardingFinished from '../OnboardingFinished'
import OnboardingIntro from '../OnboardingIntro'
import OnboardingWizard from '../OnboardingWizard'

type PropsType = UiStyleProps & {
  handleSubmit: (values: Object) => void,
  hasHeader?: boolean,
  pageContent?: string | Node,
  pageTitle?: string | Node,
  questionnaire: QuestionnaireType,
  userName: string,
}

const OnboardingPages = (props: PropsType) => {
  const {
    handleSubmit,
    hasHeader = true,
    pageContent,
    pageTitle,
    questionnaire,
    uiStyle,
    userName,
  } = props

  const [page, setPage] = useState('intro')
  const [values, setValues] = useState({})

  const { t: translation } = useTranslation()

  switch (page) {
    case 'intro':
      return (
        <>
          {hasHeader && <PublicHeader white />}

          <OnboardingIntro
            pageTitle={pageTitle}
            pageContent={pageContent}
            handleWizardStart={() => setPage('wizard')}
          />
        </>
      )
    case 'wizard':
      return (
        <>
          {hasHeader && <PublicHeader white />}

          <OnboardingWizard
            questionnaire={questionnaire}
            handleWizardComplete={(v) => {
              setPage('finished')
              setValues(v)
            }}
            uiStyle={uiStyle}
          />
        </>
      )
    case 'finished':
      return (
        <>
          {hasHeader && <PublicHeader white />}

          <OnboardingFinished
            userName={userName}
            handleQuestionsSubmit={() =>
              handleSubmit(normalizeClinicianValues(values))
            }
          />
        </>
      )
    default:
      return <div>{translation('unknown_page')}</div>
  }
}

export default OnboardingPages
