// @flow

import React, { useCallback, useEffect, useRef, useState } from 'react'
import { connect } from 'react-fela'
import { useTranslation } from 'react-i18next'

import { type SupportPersonTask_individual } from 'components/IndividualTasksCard/__generated__/SupportPersonTask_individual.graphql'
import Modal from 'react-ui/components/Modal'
import { ViewerEnumsContext } from 'containers/ViewerEnums'
import { Button, Heading } from 'care-ui'

import AddSupportPersonForm from '../AddSupportPersonForm'
import ViewSupportPersonInfo from '../ViewSupportPersonInfo'

import { styleRules } from './SupportPersonModal.style'

import { type FelaPropsType } from 'react-ui/typing'
import { type ReadOnlyArrayElement } from 'services/flow'

type supportPersonAssignmentsType = $PropertyType<
  SupportPersonTask_individual,
  'support_person_assignments',
>

export type supportPersonAssignmentType = ReadOnlyArrayElement<supportPersonAssignmentsType>

export type modifiedSupportPersonAssignmentType = supportPersonAssignmentType & {
  justActioned?: boolean,
  justMade?: boolean,
  justRemoved?: boolean,
}

type PropsType = FelaPropsType & {
  hasSupportPerson?: boolean,
  isOpen: boolean,
  onToggleOpen: () => void,
  supportPersonAssignments: supportPersonAssignmentsType,
}

const SupportPersonModal = (props: PropsType) => {
  const {
    isOpen,
    onToggleOpen,
    supportPersonAssignments: initialAssignments,
    hasSupportPerson: hasSupportPersonProp,
    rules,
    styles,
  } = props

  const [inviting, setInviting] = useState(!hasSupportPersonProp)
  const [supportPersonAssignments, setSupportPersonAssignments] = useState(
    initialAssignments,
  )

  const scrollNode = useRef(null)

  useEffect(() => {
    if (!inviting && !hasSupportPersonProp) {
      setInviting(true)
    }

    // Only update state if there are actual changes in the assignments
    if (supportPersonAssignments !== initialAssignments) {
      const oldStateAssignments = supportPersonAssignments
      const newPropAssignments = initialAssignments

      const updatedAssignments = newPropAssignments
        .filter((el) =>
          oldStateAssignments.some(
            (e) =>
              el.id === e.id && el.last_requested_at !== e.last_requested_at,
          ),
        )
        .map((el) => el.id)

      const newAssignments = newPropAssignments.filter(
        (el) => !oldStateAssignments.some((e) => el.id === e.id),
      )
      const deletedAssignmentIds = oldStateAssignments
        .filter((el) => !newPropAssignments.some((e) => el.id === e.id))
        .map((el) => el.id)

      const updatedNewAssignments = newAssignments.map((assignment) => ({
        ...assignment,
        justMade: true,
      }))

      const transformedAssignments = oldStateAssignments.map((el) => {
        if (deletedAssignmentIds.includes(el.id)) {
          return { ...el, justRemoved: true }
        }
        if (updatedAssignments.includes(el.id)) {
          const updated = newPropAssignments.find((e) => e.id === el.id)
          return {
            ...el,
            justActioned: true,
            last_requested_at: updated?.last_requested_at,
          }
        }
        return el
      })

      const concatedAssignments: any = [
        ...transformedAssignments,
        ...updatedNewAssignments,
      ]

      // Only set state if there are actual changes to prevent unnecessary re-renders
      if (
        JSON.stringify(supportPersonAssignments) !==
        JSON.stringify(concatedAssignments)
      ) {
        setSupportPersonAssignments(concatedAssignments)
      }
    }
  }, [
    hasSupportPersonProp,
    initialAssignments,
    supportPersonAssignments,
    inviting,
  ])

  useEffect(() => {
    const { current } = scrollNode
    if (inviting && current) {
      current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [inviting])

  const handleCloseRequest = useCallback(() => {
    onToggleOpen()
    setInviting(!hasSupportPersonProp)
    setSupportPersonAssignments(initialAssignments)
  }, [onToggleOpen, hasSupportPersonProp, initialAssignments])

  const { t: translation } = useTranslation()

  return (
    <Modal
      shrinkwrap
      id="add_support_person_modal"
      showClose={false}
      isOpen={isOpen}
      onRequestClose={handleCloseRequest}
      extend={() => ({ Modal: rules.Modal })}
    >
      <Heading level={2} extend={rules.heading}>
        {translation('your_support_person')}
      </Heading>

      <p className={styles.description}>
        {translation(
          'different_perspectives_can_help_add_more_information_to_your_health_profile_they_will_receive_an_email_invite_to_answer_a_questionnaire_about_your_health_and_well_being',
        )}
      </p>

      <p>
        <strong>
          {translation(
            'they_will_not_be_able_to_view_any_of_your_health_information',
          )}
        </strong>

        <br />

        <strong>
          {translation('if_you_remove_a_support_person_they_wont_be_notified')}
        </strong>
      </p>

      {supportPersonAssignments.map((assignment) => (
        <ViewSupportPersonInfo
          key={assignment.id}
          supportPersonAssignment={assignment}
          extend={{ hr: rules.hr }}
        />
      ))}

      <hr className={styles.hr} />

      <Heading level={3} extend={rules.heading}>
        {`${translation('invite')} ${
          supportPersonAssignments.length > 0
            ? translation('add_another_person')
            : translation('a')
        } ${translation('support_person')}`}
      </Heading>

      <div ref={scrollNode}>
        {inviting ? (
          <ViewerEnumsContext.Consumer>
            {({ relationships }) => (
              <AddSupportPersonForm
                afterSubmit={() => setInviting(false)}
                relationships={relationships}
              />
            )}
          </ViewerEnumsContext.Consumer>
        ) : (
          <Button
            dataTestId="showFormButton"
            onClick={() => setInviting(true)}
            variant="text"
          >
            + {translation('add_another_person')}
          </Button>
        )}
      </div>
    </Modal>
  )
}

export default connect(styleRules)(SupportPersonModal)
