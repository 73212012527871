// @flow

import React, { type Node, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useRouter } from 'found'

import { Layout2Col } from 'react-ui/components/Layout'
import MainContentRegion from 'react-ui/components/MainContentRegion'
import MatchMedia from 'react-ui/components/MatchMedia'
import Page from 'react-ui/components/Page'
import NavigationContext from 'react-ui/components/Page/Navigation/NavigationContext'
import Spacer from 'react-ui/components/Spacer'
import HelpNowButton from 'platform_web/features/Header/HelpNowButton/HelpNowButton'
import { Button } from 'care-ui'
import ChevronLeft from 'care-ui/atoms/icons/chevron-left.svg'

import {
  articleContentStyles,
  asideContentStyles,
  asideStyles,
  mainContentRegionStyles,
} from './LegalPage.style'

import type { FelaPropsType } from 'react-ui/typing'

type PropsType = FelaPropsType & {
  children: Node,
  router: Object,
}

const LegalPage = ({ children }: PropsType) => {
  const { t: translation } = useTranslation()
  const { router } = useRouter()

  const legalPageContents = () => (
    <>
      <Spacer axis="vertical" units={1} />
      <MatchMedia>
        {({ sm, md }) => (
          <Layout2Col
            aside={
              <div>
                <Button
                  variant="secondary"
                  ariaLabel="Back"
                  onClick={() => {
                    router.go(-1)
                  }}
                  dataTestId="back"
                  iconLeft={ChevronLeft}
                >
                  {translation('back')}
                </Button>
              </div>
            }
            asideColSpan={(() => {
              if (md) {
                return 1
              }
              if (sm) {
                return 2
              }
              return 12
            })()}
            extend={() => ({
              articleContent: articleContentStyles,
              aside: asideStyles,
              asideContent: asideContentStyles,
            })}
          >
            <MainContentRegion extend={mainContentRegionStyles}>
              {children}
            </MainContentRegion>
          </Layout2Col>
        )}
      </MatchMedia>
    </>
  )

  const { menu } = useContext(NavigationContext)

  if (menu) {
    return legalPageContents()
  }

  return <Page actions={<HelpNowButton />}>{legalPageContents()}</Page>
}

export default LegalPage
